import EditIcon from "../../../assets/icons/edit.svg";
import React from "react";
import styled from "../../../theme/index";

interface EditButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    className?: string;
}

const Button = styled.button`
    border-radius: 8px;
    background-color: #3B97B1;
    border: 0;
    color: #fff;
    padding: 0 16px;
    margin-right: auto;
    cursor: pointer;
    font-size: 14px;
    margin-top: auto;
    margin-left: auto;
    outline-color: #fff;
    min-width: 100px;
    height: 40px;

    svg {
        margin: 0 5px;
        margin-bottom: 0;
    }
    &:hover {
        background-color: #44aecc;
    }
`;

const EditButton: React.FunctionComponent<EditButtonProps> = ({
    title,
    className,
    ...props
}) => (
    <Button {...props} className={className}>
        <EditIcon className="inline-flex" />
        {title}
    </Button>
);

export default EditButton;
