import React, { memo } from "react";
import { FaExclamation, FaInfo } from "react-icons/fa";
import styled from "styled-components";
import NoIssuesIconE from "../../assets/icons/arrow_checked_issues.svg";
import { NotificationsModule } from "../../redux";
import { theme } from "../../theme";

const Container = styled.div`
    border-radius: 8px;
    min-height: 48px;
    background: ${theme.colors.invertedBackground};
    color: ${theme.getContrastTextColor(theme.colors.invertedBackground)};
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    min-width: 300px;
    overflow: hidden;
    margin-bottom: 42px;

    &:not(:first-child) {
        margin-bottom: 1rem;
    }
`;

interface IconContainerProps {
    uiColor: string;
}

const IconContainer = styled.div<IconContainerProps>`
    width: 30px;
    // background: ${(props) => props.uiColor};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    > svg {
        fill: currentColor;
        font-size: 1.5em;
        margin-right: 5px;
    }
`;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 4px solid #ddf0f7;
    padding-bottom: 3px;
    margin-bottom: 7px;
`;

const ContentContainer = styled.div`
    flex: 1 1 auto;
    // display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    background-color: #f7fbfd;
    color: #377a8d;
    font-size: 20px;
`;

const ContentContainerSub = styled.div`
    color: #969696;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 500;
`;

const TextContainer = styled.div`
    margin-bottom: -7px;
    font-family: Poppins;
`;
/**
 * Component properties
 */
export interface NotificationProps {
    /**
     * Notification id
     */
    id: number;
    /**
     * Notification to render
     */
    message: string;
    /**
     * Notification to render
     */
    subMessage?: string;
    /**
     * Emphasis
     */
    emphasis: NotificationsModule.NotificationType;
    /**
     * Class name
     */
    className?: string;
    /**
     * Notification click callback
     * @param id
     */
    onClick?(id: number): void;
}

export const Notification: React.SFC<NotificationProps> = memo((props) => {
    const { message, emphasis, onClick, id, className, subMessage } = props;
    let icon: JSX.Element = <FaInfo />;
    let color: string = theme.notifications.infoColor;
    switch (emphasis) {
        case "error":
            icon = <FaExclamation />;
            color = theme.notifications.errorColor;
            break;
        case "warning":
            icon = <FaExclamation />;
            color = theme.notifications.warningColor;
            break;
        case "info":
            icon = <NoIssuesIconE />;
            color = theme.notifications.infoColor;
            break;
    }

    const handleClick = (): void => onClick && onClick(id);

    return (
        <Container onClick={handleClick} className={className}>
            <ContentContainer>
                <TopContainer>
                    <IconContainer uiColor={color}>{icon}</IconContainer>
                    <TextContainer>{message}</TextContainer>
                </TopContainer>
                <ContentContainerSub>{subMessage}</ContentContainerSub>
            </ContentContainer>
        </Container>
    );
});
Notification.displayName = "Notification";
