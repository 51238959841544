
import { WorkOrder } from "../interfaces/work-order";

import axiosInstance from "./axios-instance";

const getWorkOrderRecommendations = async (payload: WorkOrder) => {
    try {
        const response = await axiosInstance.post<WorkOrder>(
            "/workOrder/getWorkOrderRecommendations",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error get work order recommendations:", error);
        throw error;
    }
};

export { getWorkOrderRecommendations };