import { GetAllQueryParams, Supplier } from "../interfaces/supplier";

import axiosInstance from "./axios-instance";

const getAll = async (queryParams?: GetAllQueryParams) => {
    try {
        const response = await axiosInstance.get<Supplier[]>("/supplier", {
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching suppliers:", error);
        throw error;
    }
};

export { getAll };
