import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import styled from "../../theme";

const HoverableItemContainer = styled.div`
    width: 100%;
`;

const HoverableItemLabel = styled.span`
    text-align: center;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    position: relative;

    color: ${(props) =>
        props.className && props.className.includes("danger")
            ? "#FF6084"
            : "#222222"};
    & .sub_row {
        font-size: 12px;
    }
    & .searchTerm{
        background: #e4e9f1;
    }
    &.leftAlign {
        text-align: left;
    }
    &.lineHeight {
        line-height: 1.1;
    }

    &.editInlineField {
        cursor: ${(props) => (props.className ? "pointer" : "inherit")};

        &.paddingLeft {
            padding-left: 4px;
        }

        &.paddings {
            padding: 0px 18px 0px 0px;
        }

        & .eta {
            color: #222;
        }
        & .archivedColor {
            color: gray;
        }
        & .gray {
            color: gray;
        }
        &:hover {
            color: ${({ theme }) => theme.colors.primary} !important;

            & .inlinePaidArrow.placeholderArrow {
                color: ${({ theme }) => theme.colors.primary} !important;
                fill: currentColor;
            }
            & .hoverInline {
                color: ${({ theme }) => theme.colors.primary} !important;
            }
            & .placeholderArrow {
                color: ${({ theme }) => theme.colors.primary} !important;
                fill: currentColor;
            }

            & .gray,
            & .eta {
                color: ${({ theme }) => theme.colors.primary} !important;
            }
        }

        & .inlinePaidArrow {
            color: ${({ theme }) => theme.colors.primary} !important;
            fill: currentColor;
            font-size: 16px;
            display: inline-block;
            // position: absolute;
            // top:50%;
            // right: 2px;
            vertical-align: bottom;
            margin-left: 5px;
            transform: translate(-2px, -50%);
            &.arrow_without_value {
                position: unset;
                top: unset;
                right: unset;
                transform: unset;
            }

            &.placeholderArrow {
                color: #e9e9e9 !important;
                fill: currentColor;
                // &:hover{
                //     color: ${({ theme }) => theme.colors.primary} !important;
                // }
            }

            &.archivedArrow {
                display: none !important;
            }
        }
    }
    &.archivedColor {
        color: gray !important;
        &.paddingLeft {
            padding-left: 4px;
        }
    }
    & .gray {
        color: #a8a8a8 !important;
    }
`;

const TooltipBox = styled.div`
    position: absolute;
    text-align: center;
    align-items: center;
    gap: 10px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px 20px 5px 10px;
    color: #4a4a4a;
    font-size: 13px;

    & .inlinePaidArrow {
        color: ${({ theme }) => theme.colors.primary} !important;
        fill: currentColor;
        font-size: 16px;
        display: inline-block;
        // position: absolute;
        // top:50%;
        // right: 2px;
        vertical-align: bottom;
        margin-left: 5px;
        transform: translate(-2px, -50%);
        &.arrow_without_value {
            position: unset;
            top: unset;
            right: unset;
            transform: unset;
        }
        &.archivedArrow {
            display: none !important;
        }
    }
    & .hoverInline {
        color: ${({ theme }) => theme.colors.primary} !important;
    }
`;

// tslint:disable-next-line:no-empty-interface
interface HoverableItemProps {
    className?: string;
    archived?: boolean;
    handleSetRowId?(): void;
}

interface State {
    isHovered: boolean;
}

export class HoverableItem extends PureComponent<HoverableItemProps, State> {
    public state: State = {
        isHovered: false,
    };

    public refLabel = React.createRef<HTMLSpanElement>();

    public render(): JSX.Element {
        return (
            <HoverableItemContainer onMouseLeave={this.hideBox}>
                <HoverableItemLabel
                    onClick={this.props.handleSetRowId}
                    ref={this.refLabel}
                    onMouseOver={this.showBox}
                    className={this.props.className}
                    style={this.props.archived ? { color: "#6B6B6B" } : {}}
                >
                    {this.props.children}
                </HoverableItemLabel>

                {this.state.isHovered && this.isEllipsisLabel() && (
                    <TooltipBox>{this.props.children}</TooltipBox>
                )}
            </HoverableItemContainer>
        );
    }

    @Bind()
    private isEllipsisLabel(): boolean {
        if (this.refLabel.current) {
            return (
                this.refLabel.current.offsetWidth <
                this.refLabel.current.scrollWidth
            );
        } else {
            return false;
        }
    }

    @Bind()
    private hideBox(): void {
        this.setState({ isHovered: false });
    }

    @Bind()
    private showBox(): void {
        this.setState({ isHovered: true });
    }
}

export default HoverableItem;
