import Color from "color";
import { ButtonHTMLAttributes, ClassAttributes } from "react";
import { ColorValue } from "../theme";
import styled, { css, PropsWithTheme } from "../theme/styled";

export interface ButtonUIProps {
    /**
     * Flat button
     */
    uiFlat?: boolean;
    /**
     * Specify button color
     */
    uiColor?: ColorValue;
    /**
     * Button text color
     */
    uiTextColor?: ColorValue;
    /**
     * Disabled button
     */
    uiDisabled?: boolean;
    /**
     * Active button
     */
    uiActive?: boolean;
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonUIProps;

const buttonBackgroundColor = (props: PropsWithTheme<ButtonUIProps>) =>
    props.theme.getColor(props.uiColor) || Color(props.theme.colors.background).darken(0.1).string();

/**
 * Just a button
 */
const Button = styled.button<ButtonProps & ClassAttributes<HTMLButtonElement>>`
    min-width: 100px;
    font-weight: 500 !important;
    line-height: 1rem;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
    border-radius: 8px;
    text-shadow: none;
    -webkit-appearance: button; /* stylelint-disable-line */
    cursor: pointer;
    outline: 0;
    border: none;
    min-height: 1em;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
    padding: 0 16px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    transition: opacity, background, color 0.2s ease;
    will-change: opacity, background, color;
    background: #3b97b1;
    color: ${(props) =>
        props.theme.getColor(props.uiTextColor) ||
        props.theme.getContrastTextColor(buttonBackgroundColor(props))};
    box-shadow: 0 1px 5px 0
            ${({ theme }) => Color(theme.colors.divider).alpha(0.2).string()},
        0 2px 2px 0
            ${({ theme }) => Color(theme.colors.divider).alpha(0.14).string()},
        0 3px 1px -2px ${({ theme }) => Color(theme.colors.divider).alpha(0.12).string()};

    &:hover,
    &.active {
        background: #44aecc;
    }
    &:focus {
        border: 1px solid #3377ff;
    }

    ${(props) =>
        props.uiActive &&
        css`
            background: ${Color(buttonBackgroundColor(props))
                .darken(0.2)
                .string()};
        `};
    ${(props) =>
        props.uiFlat &&
        css`
            font-weight: 400;
            box-shadow: 0 0 0 1px
                    ${Color(props.theme.colors.divider).alpha(0.3).string()},
                0 0 0 0
                    ${Color(props.theme.colors.divider).alpha(0.15).string()};
            background: inherit;
            color: ${props.theme.getColor(props.uiTextColor) ||
            props.theme.getColor(props.uiColor) ||
            props.theme.colors.primaryText};

        
            &:hover,
            &.active,
            // &:focus
            {
                background: ${Color(buttonBackgroundColor(props))
                    .alpha(0.12)
                    .string()};
            }

        ${props.uiActive &&
            css`
                background: ${Color(buttonBackgroundColor(props))
                    .alpha(0.12)
                    .string()};
            `}
        `};
    ${(props) =>
        props.uiDisabled &&
        css`
            opacity: 0.65;
            pointer-events: none;
        `};
`;

export default Button;
