import axiosInstance from "./axios-instance";
export const exportPdf = async (payload: any, fileStoreUrl: string) => {
    try {
        const response = await axiosInstance.post(
            "/export-pdf",
            {
                data: payload,
                fileStoreUrl,
            },
            { timeout: 80000 }
        );
        return response.data;
    } catch (error) {
        console.log("Error exporting pdf: ", error);
    }
};
