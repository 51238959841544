import axiosInstance from "./axios-instance";

const getEmailVerificationList = async (email: string) => {
    try {
        const response = await axiosInstance.get(
            `/email-verification/email/${email}`
        );
        return response.data;
    } catch (error) {
        console.log("Error get email verification", error);
    }
};

export {
    getEmailVerificationList
}
