import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import { ClientPricingAction, PricingType } from "../types";
import {
    DraperyItemDetailsFields,
    DraperyItemDetailsFieldsAction,
    PricingGroupItem,
    PricingSimpleItem,
} from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import {
    FormInput,
    SectionTitle,
    TooltipContainer,
    TooltipPopup,
    TooltipText,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from "react";
import { checkIfGroupItem, validatePricingInput } from "../helpers";
import { draperyPricingTypes, draperyWidthRoundUpTypes } from "../constants";

import { Checkbox } from "@ramble/ramble-ui";
import PricingGroupGrid from "../pricing-group-grid";
import SelectList from "../../UI/select-list";
import TooltipSpecialtyFabricHandlingChecked from "../../../assets/images/specialty-fabric-handling-checked.png";
import AddButtonE from "../../UI/buttons/add-button";
import styled from "styled-components";
import PleatModal from "../pleat-modal/pleat-modal";
import { FormFields } from "../../../redux/modules/my-shop/form-fields-reducer";

interface PricingProps {
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    draperyItemDetailsFields: DraperyItemDetailsFields;
    dispatch: Dispatch<DraperyItemDetailsFieldsAction>;
    itemId: number | null;
    formFields: FormFields;
    highLightInput?: string[];
}

const AddButton = styled(AddButtonE)`
    margin: 10px auto 0px 10px; 
    font-size: 15px;
    border-radius: 8px;
    z-index: 10; 
`;


const Pricing: FC<PricingProps> = ({
    browser,
    draperyItemDetailsFields,
    dispatch,
    itemId,
    formFields,
    highLightInput,
}) => {
    const {
        pricingTypeName,
        isClientPricingActionOn,
        clientPricingAction,
        clientPricingValue,
        isPartialWidthRoundingOn,
        partialWidthRoundUpTypeName,
        pleatTypesWithPricing,
        liningAndInterliningWithPricing,
        cafeCurtainsWidthInches,
        cafeCurtainsWidthPricing,
        extraLargePanelsLengthInches,
        extraLargePanelsLengthPricing,
        extraLargePanelsWidthInches,
        extraLargePanelsWidthPricing,
        draperyPinningWidthPricing,
        specialityFabricsMarkUpPercentage,
        embellishmentsWithPricing,
        addOnsWithPricing,
    } = draperyItemDetailsFields;
    const [isPleatModalOpen, setIsPleatModalOpen] = useState(false);
    
    const autoCompleteOffSetting =
        browser && browser.name === "chrome" ? "new-password" : "off";

    const partialWidthRoundUpGridHeaders = [
        {
            title: "HEADER / PLEAT TYPE",
        },
        {
            title: `Base ${pricingTypeName} price`,
            subTitle: "unlined, per width",
        },
    ];

    const liningAndInterliningHeaders = [
        {
            title: "LINING & INTERLINING",
        },
        {
            title: "ADDITIONAL PRICE",
            subTitle: "per width",
        },
    ];

    const addOnsHeaders = [
        {
            title: "TYPE",
        },
        {
            title: `PRICE`,
            subTitle: "per width",
        },
    ];

    const embellishmentsPricingHeaders = [
        {
            title: "TYPE",
        },
        {
            title: "PRICE",
            subTitle: "per applied foot",
        },
    ];
    const formInputStyleClass = "px-1.5 py-2 ml-2 max-w-[80px]";

    const [clientType, setClientType] = useState<string>("");

    const getClientType = () => {
        if (!pricingTypeName || !pricingTypeName.length) {
            return "";
        }
        return pricingTypeName === PricingType.Retail
            ? PricingType.Trade
            : PricingType.Retail;
    };

    const handlePricingTypeChange = (
        value: string | number,
        name: string
    ): void => {
        const targetValue = value as string | number | boolean;
        let updates = {
            [name]: targetValue,
        };
        const selectedItem = draperyPricingTypes.find(
            (pt) => pt.key === targetValue
        );
        const pricingTypeName = selectedItem ? selectedItem.label : "";

        updates = {
            ...updates,
            pricingTypeName,
        };

        if (pricingTypeName && pricingTypeName.length) {
            const clientPricingAction =
                pricingTypeName === PricingType.Retail
                    ? ClientPricingAction.Discount
                    : ClientPricingAction.MarkUp;
            updates.clientPricingAction = clientPricingAction;
        } else {
            setClientType("");
            updates.clientPricingAction = "";
            updates.clientPricingValue = "";
            updates.isClientPricingActionOn = true;
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updates,
        });
    };

    const handlePartialWidthRoundUpChange = (
        value: string | number,
        name: string
    ): void => {
        const targetValue = value as string | number | boolean;
        let updates = {
            [name]: targetValue,
        };

        const selectedItem = draperyWidthRoundUpTypes.find(
            (pt) => pt.key === targetValue
        );
        const selectedValue = selectedItem ? selectedItem.label : "";

        updates = {
            ...updates,
            partialWidthRoundUpTypeName: selectedValue,
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updates,
        });
    };

    const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.currentTarget.name;
        const value = !draperyItemDetailsFields[fieldName];

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [fieldName]: value,
            },
        });
    };

    const handlePricingInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        keyParam?: string,
        type: string = "dollarAmount"
      ) => {
        let value = e.target.value;
      
        const numericValue = parseFloat(value);
      
        if (isNaN(numericValue) || (value.length === 1 && value[0] === '.')) {
          value = '';
        }
      
        if (value === '00') {
          value = '';
        }
      
        if (value.startsWith('0') && value.length > 1) {
          value = `0.${value.slice(1)}`;
        }
      
        value = value.replace(/[^\d.]/g, '');
      
        value = value.replace(/\.{2,}/g, '.');
      
        const firstDotIndex = value.indexOf('.');
        if (firstDotIndex !== -1) {
          value = `${value.slice(0, firstDotIndex + 1)}${value.slice(firstDotIndex + 1).replace(/\./g, '')}`;
        }
      
        const decimalParts = value.split('.');
        if (decimalParts.length > 1) {
          value = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(0, 2)}`;
        } else {
          value = value.slice(0, 3);
        }
      
        const keyboardEvent = e.nativeEvent as any;
        const isBackspaceAction = keyboardEvent.inputType === 'deleteContentForward';
      
        if (isBackspaceAction && value === '0' && numericValue === 0) {
          value = '';
        }
      
        dispatch({
          type: "updateFormFields",
          formFieldUpdates: {
            [keyParam || type]: value,
          },
        });
      };

      const handlePricingGridsInputsChange = ({
        e,
        gridName,
        type = "dollarAmount",
        keyParam,
      }: {
        e: ChangeEvent<HTMLInputElement>;
        gridName: string;
        type?: string;
        keyParam?: string;
      }) => {
        const inputId = e.currentTarget.id;
        const updates = validatePricingInput({
          event: e,
          keyParam,
          type,
        });
        if (inputId === 'draperyPinning') {
            const keyParam = "draperyPinningWidthPricing"; 
            const type = "text";         
            handlePricingInputChange(e, keyParam, type);
        }
        let pricingInputGrid = draperyItemDetailsFields[gridName].map(
            (item: PricingSimpleItem | PricingGroupItem) => {
                let itemTyped;
                const isGroupItem = checkIfGroupItem(item);
                if (isGroupItem) {
                    itemTyped = item as PricingGroupItem;
                    const { items } = itemTyped.group;
                    const itemsUpdated = items.map((i) => {
                        const { id } = i;
                        if (id === inputId) {
                            let roundedValue = parseFloat(updates[inputId]);

                            if (isNaN(roundedValue)) {
                                roundedValue = 0;
                            }

                            if (updates[inputId].startsWith('0') && updates[inputId].length > 1 && !updates[inputId].includes('.')) {
                                updates[inputId] = '0';
                            }

                            return { ...i, price: updates[inputId] };
                        }
                        return i;
                    });
                    itemTyped.group.items = itemsUpdated;
                    return itemTyped;
                }

                itemTyped = item as PricingSimpleItem;
                const { id } = itemTyped;
                if (id === inputId) {
                    let roundedValue = parseFloat(updates[inputId]);

                    if (isNaN(roundedValue)) {
                        roundedValue = 0;
                    }

                    if (updates[inputId].startsWith('0') && updates[inputId].length > 1 && !updates[inputId].includes('.')) {
                        updates[inputId] = '0';
                    }

                    return { ...itemTyped, price: updates[inputId] };
                }
                return itemTyped;
            }
        );
      
        const keyboardEvent = e.nativeEvent as any;
        const isDeleteAction = keyboardEvent.inputType === 'deleteContentBackward';
        const isBackspaceAction = keyboardEvent.inputType === 'deleteContentForward';
      
        if ((isDeleteAction || isBackspaceAction) && updates[inputId] === '0' && updates[inputId] !== e.currentTarget.value) {
          updates[inputId] = '';
        }
      
        const decimalParts = updates[inputId].split('.');
        if (decimalParts.length > 1) {
          updates[inputId] = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(0, 2)}`;
        } else {
          updates[inputId] = updates[inputId].slice(0, 3);
        }
      
        dispatch({
          type: "updateFormFields",
          formFieldUpdates: {
            [gridName]: pricingInputGrid,
          },
        });
    };

    useEffect(() => {
        const clientTypeVal = getClientType();
        setClientType(clientTypeVal);
    }, [pricingTypeName]);

    const openPleatModal = (event: { preventDefault: () => void; }) => {
        event.preventDefault(); 
        setIsPleatModalOpen(true);
    };
    
    const closePleatModal = () => {
        setIsPleatModalOpen(false);
    };

    const updatedPricingRows = [
        {
          id: 'draperyPinning',
          name: 'Drapery Pinning',
          price: draperyPinningWidthPricing,
        },
        ...addOnsWithPricing ? addOnsWithPricing : []
      ];

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="lg:w-3/4 mb-6 pr-6 mx-auto">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    PRICING INFORMATION
                </div>
                <div className="flex flex-col mb-2">
                    <div>
                        <SectionTitle>SETUP</SectionTitle>
                        <div className="flex flex-row items-center">
                            These are
                            <SelectList
                                name="pricingTypeId"
                                flexDirection="column"
                                required
                                noAddButton
                                valueSelect={pricingTypeName}
                                handleClickSelect={handlePricingTypeChange}
                                className="max-w-[150px] ml-2 mr-2"
                                selectList={draperyPricingTypes}
                                customValue
                            />
                            <span className="ml-2">prices</span>
                        </div>
                        {pricingTypeName && !!pricingTypeName.length && (
                            <div className="mt-4">
                                <Checkbox
                                    name="isClientPricingActionOn"
                                    checked={isClientPricingActionOn}
                                    onChange={toggleCheckbox}
                                />
                                <span>{clientPricingAction} these prices</span>
                                &nbsp;
                                <FormInput
                                    id="clientPricingValue"
                                    name="Client Pricing Value"
                                    className={formInputStyleClass}
                                    type="text"
                                    value={clientPricingValue}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "clientPricingValue",
                                            "percentage"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={!isClientPricingActionOn}
                                    required={isClientPricingActionOn}
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                />
                                <span className="ml-2">{`% for my ${clientType}`} clients</span>
                            </div>
                        )}
                    </div>
                    <div className="mt-5">
                        <SectionTitle>DRAPERY FABRICATION</SectionTitle>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center">
                                <Checkbox
                                    name="isPartialWidthRoundingOn"
                                    checked={isPartialWidthRoundingOn}
                                    onChange={toggleCheckbox}
                                />
                                Round partial widths up to the nearest&nbsp;
                                <SelectList
                                    name="partialWidthRoundUpTypeId"
                                    flexDirection="column"
                                    required={isPartialWidthRoundingOn}
                                    noAddButton
                                    valueSelect={partialWidthRoundUpTypeName}
                                    handleClickSelect={
                                        handlePartialWidthRoundUpChange
                                    }
                                    className="max-w-[150px] ml-2 mr-2"
                                    selectList={draperyWidthRoundUpTypes}
                                    customValue
                                />
                                <span className="ml-2">width for Pricing</span>
                            </div>
                            <div className="mt-4">
                                <PricingGroupGrid
                                    headers={partialWidthRoundUpGridHeaders}
                                    rows={pleatTypesWithPricing}
                                    handleInputChange={(e) =>
                                        handlePricingGridsInputsChange({
                                            e,
                                            gridName: "pleatTypesWithPricing",
                                        })
                                    }
                                    formFields={formFields}
                                    draperyItemDetailsFields={draperyItemDetailsFields}
                                    itemId={itemId}
                                    highLightInput={highLightInput}
                                />
                            </div>

                            <AddButton
                                title="Add New Pleat"
                                onClick={openPleatModal}
                            />    
                            <div className="mt-4">
                                <PricingGroupGrid
                                    headers={liningAndInterliningHeaders}
                                    rows={liningAndInterliningWithPricing}
                                    handleInputChange={(e) =>
                                        handlePricingGridsInputsChange({
                                            e,
                                            gridName:
                                                "liningAndInterliningWithPricing",
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <SectionTitle>CAFE CURTAINS</SectionTitle>
                        <div className="flex flex-row items-center">
                            <span>For finished lengths under</span>&nbsp;
                            <FormInput
                                id="cafeCurtainsWidthInches"
                                name="cafeCurtainsWidthInches"
                                className={formInputStyleClass}
                                type="text"
                                value={cafeCurtainsWidthInches}
                                onChange={(e) =>
                                    handlePricingInputChange(
                                        e,
                                        "cafeCurtainsWidthInches"
                                    )
                                }
                                uiReportOnBlur={false}
                                disabled={false}
                                required={true}
                                uiDisabled={false}
                                autoComplete={autoCompleteOffSetting}
                                list="autocompleteOff"
                            />
                            <span>”, subtract $</span>{" "}
                            <FormInput
                                id="cafeCurtainsWidthPricing"
                                name="cafeCurtainsWidthPricing"
                                className={formInputStyleClass}
                                type="text"
                                value={cafeCurtainsWidthPricing}
                                onChange={(e) =>
                                    handlePricingInputChange(
                                        e,
                                        "cafeCurtainsWidthPricing"
                                    )
                                }
                                uiReportOnBlur={false}
                                disabled={false}
                                required={true}
                                uiDisabled={false}
                                autoComplete={autoCompleteOffSetting}
                                list="autocompleteOff"
                            />
                            &nbsp;
                            <span>/width</span>
                        </div>
                    </div>
                    <div className="mt-5">
                        <SectionTitle>EXTRA LARGE PANELS</SectionTitle>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center mb-2">
                                <span>For finished lengths over</span>&nbsp;
                                <FormInput
                                    id="extraLargePanelsLengthInches"
                                    name="extraLargePanelsLengthInches"
                                    className={formInputStyleClass}
                                    type="text"
                                    value={extraLargePanelsLengthInches}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "extraLargePanelsLengthInches"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    required={true}
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                />
                                <span className="ml-2">inches, add $</span>
                                &nbsp;
                                <FormInput
                                    id="extraLargePanelsLengthPricing"
                                    name="Client Pricing Value"
                                    className={formInputStyleClass}
                                    type="text"
                                    value={extraLargePanelsLengthPricing}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "extraLargePanelsLengthPricing"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    required={true}
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                />
                                &nbsp;
                                <span>/additional foot per width</span>
                            </div>
                            <div className="flex flex-row items-center">
                                <span>For finished widths over</span>&nbsp;
                                <FormInput
                                    id="extraLargePanelsWidthInches"
                                    name="extraLargePanelsWidthInches"
                                    className={formInputStyleClass}
                                    type="text"
                                    value={extraLargePanelsWidthInches}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "extraLargePanelsWidthInches"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    required
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                />
                                <span className="ml-2">, add $</span>
                                &nbsp;
                                <FormInput
                                    id="extraLargePanelsWidthPricing"
                                    name="extraLargePanelsWidthPricing"
                                    className={formInputStyleClass}
                                    type="text"
                                    value={extraLargePanelsWidthPricing}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "extraLargePanelsWidthPricing"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    required
                                    uiDisabled={false}
                                    autoComplete={autoCompleteOffSetting}
                                    list="autocompleteOff"
                                />
                                &nbsp;
                                <span>/each width</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-5">
                        <SectionTitle>DRAPERY PINNING</SectionTitle>
                        <div className="flex flex-row items-center">
                            <span>Add $</span>&nbsp;
                            <FormInput
                                id="draperyPinningWidthPricing"
                                name="draperyPinningWidthPricing"
                                className={formInputStyleClass}
                                type="text"
                                value={draperyPinningWidthPricing}
                                onChange={(e) =>
                                    handlePricingInputChange(
                                        e,
                                        "draperyPinningWidthPricing"
                                    )
                                }
                                uiReportOnBlur={false}
                                disabled={false}
                                required={true}
                                uiDisabled={false}
                                autoComplete={autoCompleteOffSetting}
                                list="autocompleteOff"
                            />
                            &nbsp;
                            <span>/width</span>
                        </div>
                    </div> */}
                    <div className="mt-5">
                        <div className="flex flex-row">
                            <SectionTitle>SPECIALTY FABRICS</SectionTitle>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            <div>
                                                <p className="leading-6 m-0">
                                                    This mark-up is applied to
                                                    the total fabrication price
                                                    when the
                                                </p>
                                                <div className="float-left mr-[8px]">
                                                    <img
                                                        src={
                                                            TooltipSpecialtyFabricHandlingChecked
                                                        }
                                                        alt="Image"
                                                        className="border max-h-[22px]"
                                                    />
                                                </div>
                                                <p className="leading-6 m-0">
                                                    option is selected for a
                                                    fabric on a order. If more
                                                    than one fabric requires
                                                    special handling on an
                                                    order, the mark-up will only
                                                    be applied once.
                                                </p>
                                            </div>
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>

                        <div className="flex flex-row items-center">
                            <span>
                                Mark-up total fabrication price for items using
                                specialty fabrics
                            </span>
                            &nbsp;
                            <FormInput
                                id="specialityFabricsMarkUpPercentage"
                                name="specialityFabricsMarkUpPercentage"
                                className={formInputStyleClass}
                                type="text"
                                value={specialityFabricsMarkUpPercentage}
                                onChange={(e) =>
                                    handlePricingInputChange(
                                        e,
                                        "specialityFabricsMarkUpPercentage",
                                        "percentage"
                                    )
                                }
                                uiReportOnBlur={false}
                                disabled={false}
                                required={true}
                                uiDisabled={false}
                                autoComplete={autoCompleteOffSetting}
                                list="autocompleteOff"
                            />
                            &nbsp;
                            <span>%</span>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="mb-2">
                            <SectionTitle>ADD-ONS</SectionTitle>
                        </div>
                        <PricingGroupGrid
                            headers={addOnsHeaders}
                            rows={updatedPricingRows}
                            handleInputChange={(e) =>
                                handlePricingGridsInputsChange({
                                    e,
                                    gridName: "addOnsWithPricing",
                                })
                            }
                            highLightInput={highLightInput}
                        />
                    </div>
                    <div className="mt-5">
                        <div className="flex flex-row">
                            <SectionTitle>EMBELLISHMENTS</SectionTitle>
                            <TooltipPopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            <b>BANDING:</b>
                                            &nbsp;Fabric (usually a contrast
                                            fabric) seamed to the main fabric
                                            around the border or inset within
                                            the drapery.
                                            <br />
                                            <br />
                                            <b>CORDING:</b>
                                            &nbsp;Rope that is sewn into a seam
                                            or edge. This can be a pre-made
                                            decorative cording or welt cording
                                            (non-decorative cording covered with
                                            fabric).
                                            <br />
                                            <br />
                                            <b>FLANGE:</b>&nbsp;A strip of
                                            fabric that is folded in half and
                                            sewn into the seam or edge of the
                                            drapery.
                                            <br />
                                            <br />
                                            <b>TRIM:</b>&nbsp;Strips of fabric
                                            or pre-made trim applied on top of
                                            the main fabric.
                                            <br />
                                            <br />
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>

                        <div className="mt-4">
                            <PricingGroupGrid
                                headers={embellishmentsPricingHeaders}
                                rows={embellishmentsWithPricing}
                                handleInputChange={(e) =>
                                    handlePricingGridsInputsChange({
                                        e,
                                        gridName: "embellishmentsWithPricing",
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
                {isPleatModalOpen && (
                    <PleatModal
                        isPleatModalOpen={isPleatModalOpen}
                        onPleatModalClose={closePleatModal}
                        dispatch={dispatch} 
                        formFields={formFields}  
                        draperyItemDetailsFields={draperyItemDetailsFields}
                        itemId={itemId}                                     
                    />
                )}                               
        </div>
    );
};

export default Pricing;
