import {
    Button,
    Checkbox,
    FormValidationError,
    Modal,
    ModalActions,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    ValidationForm,
} from "@ramble/ramble-ui";
import {
    FabricsOrLiningsItemDetailsFieldsPartial,
    fabricsOrLiningsItemDetailsFieldsInitialState,
    fabricsOrLiningsItemDetailsFieldsReducer,
} from "../../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import { ConfirmationCloseModal } from "../../../modals/confirmation_close_modal";
import {
    FormFieldsPartial,
    formFieldsInitialState,
    formFieldsReducer,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import { ItemModalContent, ItemTabs, TabContainer } from "../styles";
import React, {
    FC,
    FormEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from "react";
import { Tab, TabList } from "react-tabs";
import {
    addFabricsOrLiningsRecord,
    getFabricsOrLiningsRecord,
    updateFabricsOrLiningsRecord,
} from "../../../api/item";
import styled from "../../../theme";

import { Category } from "../../../interfaces/category";
import Details from "./details";
import { PageNames } from "../../../constants";
import Pricing from "./pricing";
import Specifications from "./specifications";
import { Supplier } from "../../../interfaces/supplier";
import { detect } from "detect-browser";
import { getAll as getAllCategories } from "../../../api/category";
import { getAll as getAllSuppliers } from "../../../api/supplier";
import { getSelectedName } from "../helpers";

const CancelButton = styled(Button)`
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #CDCDCD
    color: #222222;

    &:hover {
        background-color: #eeeeee;
    }
`;

interface FabricsOrLiningsItemModalProps {
    isModalOpen: boolean;
    categories: Category[];
    suppliers: Supplier[];
    itemId?: number | null;
    modalCategoryId?: number;
    pageName?: PageNames;
    onModalClose(): void;
    reloadGrid(): void;
    updateFabricsTab?(payload: any): void;
}

interface ScrollInfo {
    scrollX: number;
    scrollY: number;
}

const FabricsOrLiningsItemModal: FC<FabricsOrLiningsItemModalProps> = ({
    isModalOpen,
    categories: propCategories,
    suppliers: propSuppliers,
    itemId,
    modalCategoryId,
    pageName = PageNames.MyShop,
    onModalClose,
    reloadGrid,
    updateFabricsTab
}) => {
    const browser = detect();
    const [categories, setCategories] = useState<Category[]>(propCategories);
    const [isCloseModalActive, setIsCloseModalActive] =
        useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<Supplier[]>(propSuppliers);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [areaScrollInfo, setAreaScrollInfo] = useState<ScrollInfo>({
        scrollX: 0,
        scrollY: 0,
    });
    const [formFields, dispatch] = useReducer(
        formFieldsReducer,
        formFieldsInitialState
    );

    const [fabricsOrLiningsItemDetailsFields, dispatchItemDetails] = useReducer(
        fabricsOrLiningsItemDetailsFieldsReducer,
        fabricsOrLiningsItemDetailsFieldsInitialState
    );

    const [errorElementId, setErrorElementId] = useState<string | null>(null);

    const handleInvalidElem = (elem: HTMLElement): void => {
        const elemId = elem.id;

        if (
            [
                "name",
                "itemCategory",
                "itemTypeProduct",
                "itemTypeService",
            ].includes(elemId)
        ) {
            setActiveTabIndex(0);
        } else {
            setActiveTabIndex(1);
        }

        setErrorElementId(elemId);
    };

    const handleSubmitForm = async (
        e: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();

        const {
            name,
            itemNumber,
            avatarId,
            itemTypeId,
            supplierId,
            categoryId,
            notes,
            isActive,
            cost,
            msrp,
            retailPricingMethod,
            retailPricingValue,
            tradePricingMethod,
            tradePricingValue,
        } = formFields;

        const salesTextArea = document.getElementById(
            "salesDescription"
        ) as HTMLInputElement;

        const orderingTextArea = document.getElementById(
            "orderingDescription"
        ) as HTMLInputElement;

        let payload: FormFieldsPartial = {
            name,
            itemNumber,
            avatarId,
            itemTypeId,
            supplierId,
            categoryId,
            notes,
            isActive,
            cost,
            msrp,
            retailPricingMethod,
            retailPricingValue,
            tradePricingMethod,
            tradePricingValue,
        };

        if (salesTextArea) {
            payload.salesDescription = salesTextArea.value;
        }

        if (orderingTextArea) {
            payload.orderingDescription = orderingTextArea.value;
        }

        const {
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
            isDropRepeatOn,
            isMatchRepeatAtSeamsOn,
            isPleatToPatternOn,
            directionOfUseTypeId,
            isSpecialtyFabricHandlingOn,
        } = fabricsOrLiningsItemDetailsFields;

        let detailsPayload: FabricsOrLiningsItemDetailsFieldsPartial = {
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
            isDropRepeatOn,
            isMatchRepeatAtSeamsOn,
            isPleatToPatternOn,
            directionOfUseTypeId,
            isSpecialtyFabricHandlingOn,
        };

        let comboPayload = {
            ...payload,
            ...detailsPayload,
        };

        let result: any;

        if (itemId) {
            comboPayload = {
                ...comboPayload,
                id: itemId,
            };
            result = await updateFabricsOrLiningsRecord(comboPayload);
        } else {
            result = await addFabricsOrLiningsRecord(comboPayload);
        }

        const errors = result.data.errors;
        const status = result.status;

        if (errors) {
            setAreaScrollInfo({
                scrollX: 0,
                scrollY: 0,
            });
            throw FormValidationError.fromErrorDictionary(errors);
        } else if ([201, 200].includes(status)) {
            reloadGrid(); //do not change the order these are called
            if(updateFabricsTab) await updateFabricsTab(comboPayload);
            onSaveClose();
        }
    };

    const handleKeyPressForm = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const selectTab = (index: number) => {
        setActiveTabIndex(index);
        setAreaScrollInfo({
            scrollX: 0,
            scrollY: 0,
        });
    };

    const toggleInactive = () => {
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: { isActive: !formFields.isActive },
        });
    };

    // const onClose = () => {
    // 	dispatch({ type: "resetFormFields" });
    // 	setActiveTabIndex(0);
    // 	onModalClose();
    //     };

    const onClose = () => {
        setIsCloseModalActive(true);
    };

    const onSaveClose = () => {
        dispatch({ type: "resetFormFields" });
        setActiveTabIndex(0);
        onModalClose();
    };

    const renderDetails = (): JSX.Element => {
        return (
            <TabContainer>
                <Details
                    categories={categories}
                    suppliers={suppliers}
                    browser={browser}
                    formFields={formFields}
                    fabricsOrLiningsItemDetailsFields={
                        fabricsOrLiningsItemDetailsFields
                    }
                    dispatch={dispatch}
                />
            </TabContainer>
        );
    };

    const renderPricing = (): JSX.Element => {
        return (
            <TabContainer>
                <Pricing
                    browser={browser}
                    formFields={formFields}
                    dispatch={dispatch}
                />
            </TabContainer>
        );
    };

    const renderSpecifications = (): JSX.Element => {
        return (
            <TabContainer>
                <Specifications
                    browser={browser}
                    fabricsOrLiningsItemDetailsFields={
                        fabricsOrLiningsItemDetailsFields
                    }
                    dispatch={dispatchItemDetails}
                />
            </TabContainer>
        );
    };

    const getModalTitle = (): JSX.Element | "" => {
        const { categoryName, name } = formFields;

        if (!categoryName && !name) {
            return (
                <span className="text-twPrimary font-medium">Add New Item</span>
            );
        }

        return (
            <div>
                {categoryName && (
                    <>
                        <span className="text-twBlue">{categoryName}</span>
                        <span className="p-1">|</span>
                    </>
                )}
                <span className="text-twBlue">{`${name || ""}`}</span>
            </div>
        );
    };

    const fetchCategoriesSuppliers = useCallback(async () => {
        let supp: Supplier[];
        let cat: Category[];

        try {
            supp = await getAllSuppliers();
            cat = await getAllCategories();
        } catch (error) {
            console.error("Error fetching categories and or suppliers:", error);
            return;
        }
        setCategories(cat);
        setSuppliers(supp);
    }, []);

    useEffect(() => {
        if (pageName !== PageNames.MyShop) {
            fetchCategoriesSuppliers();
        }
    }, [pageName]);

    useEffect(() => {
        if (errorElementId) {
            const errorElement = document.getElementById(errorElementId);
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                errorElement.focus();
            }
        }
    }, [errorElementId]);

    useEffect(() => {
        if (itemId) {
            getFabricsOrLiningsRecord({ recordId: itemId })
                .then((res) => {
                    const itemUpdates: FormFieldsPartial = {
                        ...res,
                    };

                    const itemDetailsUpdates: FabricsOrLiningsItemDetailsFieldsPartial =
                        {
                            ...res,
                        };

                    const categoryName = getSelectedName({
                        list: categories as [],
                        findByKey: "id",
                        findValue: itemUpdates.categoryId,
                        nameField: "name",
                    });

                    const supplierName = getSelectedName({
                        list: suppliers as [],
                        findByKey: "id",
                        findValue: itemUpdates.supplierId,
                        nameField: "displayName",
                    });

                    dispatch({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...itemUpdates,
                            categoryName,
                            supplierName,
                        },
                    });

                    dispatchItemDetails({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...itemDetailsUpdates,
                        },
                    });
                })
                .catch((err) => {
                    console.log(
                        `error fetching fabrics or linings item: ${err}`
                    );
                });
        } else {
            let updates: any = { itemTypeId: 1 }; 
            if (modalCategoryId && categories && categories.length) {
                const categoryName = getSelectedName({
                    list: categories as [],
                    findByKey: "id",
                    findValue: modalCategoryId,
                    nameField: "name",
                });
                updates = {
                    ...updates,
                    categoryId: modalCategoryId,
                    categoryName,
                };
            }

            dispatch({
                type: "updateFormFields",
                formFieldUpdates: updates,
            });
        }
    }, [itemId, modalCategoryId, categories]);

    return (
        <>
            <PageDimmer uiActive={isModalOpen} />
            <Modal
                uiActive={isModalOpen}
                uiOnRequestClose={() => {
                    return;
                }}
                uiDefaultSizes={false}
            >
                <ValidationForm
                    uiOnSubmit={handleSubmitForm}
                    uiReportValidityMode="firstInvalid"
                    uiOnInvalidElem={handleInvalidElem}
                    onKeyPress={handleKeyPressForm}
                    uiPadding={false}
                    className="h-full"
                >
                    <ModalHeader className="border-0">
                        <div className="flex justify-between w-full pb-2 border-b-8 border-twSecondary">
                            {getModalTitle()}
                            <Checkbox
                                checked={!formFields.isActive}
                                onClick={toggleInactive}
                                className="font-normal text-base"
                            >
                                Make Inactive
                            </Checkbox>
                        </div>
                    </ModalHeader>

                    <ItemModalContent className="mb-8">
                        <PopoverScrollContext.Provider value={areaScrollInfo}>
                            <ItemTabs
                                selectedIndex={activeTabIndex}
                                onSelect={selectTab}
                                forceRenderTabPanel
                                className="flex flex-col flex-1 h-full"
                            >
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Pricing</Tab>
                                    <Tab>Specifications</Tab>
                                </TabList>
                                {renderDetails()}
                                {renderPricing()}
                                {renderSpecifications()}
                            </ItemTabs>
                        </PopoverScrollContext.Provider>
                    </ItemModalContent>

                    <ModalActions>
                        <CancelButton
                            type="button"
                            uiColor="secondary"
                            onClick={onClose}
                            className="rounded-lg mr-auto block shadow-none"
                        >
                            Cancel
                        </CancelButton>

                        <Button
                            className="rounded-lg border border-twBlue2 block shadow-none"
                            uiColor="primary"
                        >
                            Save
                        </Button>
                    </ModalActions>
                </ValidationForm>
            </Modal>
            <ConfirmationCloseModal
                    uiAcceptLabel="Yes"
                    uiDeclineLabel="No"
                    uiHeader="Setup Needed"
                    uiActive={isCloseModalActive}
                    uiOnConfirm={() => {
                        dispatch({ type: "resetFormFields" });
                        setActiveTabIndex(0);
                        onModalClose();
                    }}
                    uiOnClose={() => setIsCloseModalActive(false)}
                    uiOnRequestClose={() => setIsCloseModalActive(false)}
                    uiCloseOnOutsideClick={true}
                    uiCloseOnEsc={true}
                >Are you sure you want to exit without saving the changes?
            </ConfirmationCloseModal>
        </>
    );
};

export default FabricsOrLiningsItemModal;
