import { GridTableCell, GridTableRow } from "@ramble/ramble-ui";
import React, { FC } from "react";
import styled, { css } from "../../../theme";

import { AvatarImage } from "../avatar";
import CopyToClipboard from "../copy-to-clipboard";
import { SortTableRowInfo } from "./sort_table";

const RowE: FC<RowProps> = (props) => (
    <GridTableRow className={props.className} onClick={props.onClick}>
        {props.children}
    </GridTableRow>
);

const Row = styled(RowE)`
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    border-bottom: solid #e2e2e2 1px;
    padding: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #eee;
    }

    ${(props) =>
        props.italic &&
        css`
            color: #aaa;
            font-style: italic;
        `};
`;

const BasicGridTableCell = styled(GridTableCell)`
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    font-weight: 400;
    border-left: 1px solid #e2e2e2;

    &:first-child {
        border-left: none;
    }
`;

const DisplayNameE: FC<LevelProps> = (props) => (
    <BasicGridTableCell className={props.className}>
        {props.children}
    </BasicGridTableCell>
);

const DisplayName = styled(DisplayNameE)<LevelProps>`
    text-align: left;
    padding-left: ${(props) => props.level * 28 + 10}px;
`;

const Email = styled(BasicGridTableCell)`
    overflow: unset;
    white-space: normal;
`;

const Avatar = styled(AvatarImage)`
    margin-right: 8px;
`;

export interface RowProps {
    className?: string;
    italic?: boolean;
    children?: React.ReactNode;
    onClick(): void;
}

export interface LevelProps {
    level: number;
    className?: string;
    children?: React.ReactNode;
}

export interface SortTableRowProps extends LevelProps {
    data: SortTableRowInfo;
    onChoosed(id: number): void;
}

const SortTableRow: FC<SortTableRowProps> = (props) => (
    <Row
        onClick={() => props.onChoosed(props.data.id)}
        italic={!props.data.active}
        className="grid-table-row"
    >
        <DisplayName className={props.className} level={props.level}>
            <Avatar
                avatarId={props.data.avatarId}
                firstName={props.data.firstName}
                lastName={props.data.lastName}
                companyName={props.data.companyName}
                width={28}
                height={28}
                fontSize={15}
            />
            {props.data.displayName}
            {!props.data.active && " (Inactive)"}
        </DisplayName>

        <Email>
            {props.data.email && props.data.email && (
                <CopyToClipboard
                    title={props.data.email}
                    copy={props.data.email}
                    tooltipMessage="Email Copied"
                />
            )}
        </Email>

        <BasicGridTableCell>
            {props.data.cellPhone && props.data.cellPhone}
            {props.data.homePhone &&
                !props.data.cellPhone &&
                props.data.homePhone &&
                props.data.homePhone}
            {props.data.officePhone &&
                !props.data.cellPhone &&
                !props.data.homePhone &&
                props.data.officePhone &&
                props.data.officePhone}
        </BasicGridTableCell>
    </Row>
);

export default SortTableRow;
