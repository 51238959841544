import { ActionDescriptor } from "../../common";
import { DraperyOrderFabric } from "./interfaces";
import { Omit } from "../../../../types/index";

export const ADD_DRAPERY_ORDER_FABRIC = "draperyOrderFabric/ADD";
export type AddDraperyOrderFabricAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_FABRIC,
    Omit<DraperyOrderFabric, "id"> & { accountId: number } & { draperyOrderDesignEmbellishmentId?: number } & { draperyOrderDesignLiningId?: number } & { supplierName?: string } & { customSupplier?: string },
    DraperyOrderFabric
>;

export const EDIT_DRAPERY_ORDER_FABRIC = "draperyOrderFabric/EDIT";
export type EditDraperyOrderFabricAction = ActionDescriptor<typeof EDIT_DRAPERY_ORDER_FABRIC, DraperyOrderFabric & { accountId: number } & { supplierName?: string }, {}>;

export const GET_DRAPERY_ORDER_FABRIC = "draperyOrderFabric/GET";
export type GetDraperyOrderFabricAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_FABRIC, { id: number }, DraperyOrderFabric>;

export const DELETE_DRAPERY_ORDER_FABRIC = "draperyOrderFabric/DELETE";
export type DeleteDraperyOrderFabricAction = ActionDescriptor<typeof DELETE_DRAPERY_ORDER_FABRIC, { id: number }, {}>;

export const SEARCH_DRAPERY_ORDER_FABRICS = "draperyOrderFabric/SEARCH";
export type SearchDraperyOrderFabricsAction = ActionDescriptor<typeof SEARCH_DRAPERY_ORDER_FABRICS, {
     draperyOrderId: number,
     draperyOrderDesignEmbellishmentId?: number,
    }, DraperyOrderFabric[]>;

export interface DraperyOrderFabricActionsMap {
    [ADD_DRAPERY_ORDER_FABRIC]: AddDraperyOrderFabricAction;
    [EDIT_DRAPERY_ORDER_FABRIC]: EditDraperyOrderFabricAction;
    [GET_DRAPERY_ORDER_FABRIC]: GetDraperyOrderFabricAction;
    [DELETE_DRAPERY_ORDER_FABRIC]: DeleteDraperyOrderFabricAction;
    [SEARCH_DRAPERY_ORDER_FABRICS]: SearchDraperyOrderFabricsAction;
}
