import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { DraperyOrderDesignEmbellishment } from "./interfaces";

export const ADD_DRAPERY_ORDER_DESIGN_EMBELLISHMENT = "draperyOrderDesignEmbellishment/ADD";
export type AddDraperyOrderDesignEmbellishmentAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_DESIGN_EMBELLISHMENT,
    Omit<DraperyOrderDesignEmbellishment, "id"> & { accountId: number },
    DraperyOrderDesignEmbellishment
>;

export const EDIT_DRAPERY_ORDER_DESIGN_EMBELLISHMENT = "draperyOrderDesignEmbellishment/EDIT";
export type EditDraperyOrderDesignEmbellishmentAction = ActionDescriptor<typeof EDIT_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, DraperyOrderDesignEmbellishment, {}>;

export const GET_DRAPERY_ORDER_DESIGN_EMBELLISHMENT = "draperyOrderDesignEmbellishment/GET";
export type GetDraperyOrderDesignEmbellishmentAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, { id: number }, DraperyOrderDesignEmbellishment>;

export const DELETE_DRAPERY_ORDER_DESIGN_EMBELLISHMENT = "draperyOrderDesignEmbellishment/DELETE";
export type DeleteDraperyOrderDesignEmbellishmentAction = ActionDescriptor<typeof DELETE_DRAPERY_ORDER_DESIGN_EMBELLISHMENT, { id: number }, {}>;

export const SEARCH_DRAPERY_ORDER_DESIGN_EMBELLISHMENTS = "draperyOrderDesignEmbellishment/SEARCH";
export type SearchDraperyOrderDesignEmbellishmentsAction = ActionDescriptor<typeof SEARCH_DRAPERY_ORDER_DESIGN_EMBELLISHMENTS, {
     draperyOrderDesignId: number,
    }, DraperyOrderDesignEmbellishment[]>;

export interface DraperyOrderDesignEmbellishmentActionsMap {
    [ADD_DRAPERY_ORDER_DESIGN_EMBELLISHMENT]: AddDraperyOrderDesignEmbellishmentAction;
    [EDIT_DRAPERY_ORDER_DESIGN_EMBELLISHMENT]: EditDraperyOrderDesignEmbellishmentAction;
    [GET_DRAPERY_ORDER_DESIGN_EMBELLISHMENT]: GetDraperyOrderDesignEmbellishmentAction;
    [DELETE_DRAPERY_ORDER_DESIGN_EMBELLISHMENT]: DeleteDraperyOrderDesignEmbellishmentAction;
    [SEARCH_DRAPERY_ORDER_DESIGN_EMBELLISHMENTS]: SearchDraperyOrderDesignEmbellishmentsAction;
}
