import {
    ClientBlock,
    ClientFields,
    ClientFormInput,
    ClientFormLabel,
    ClientFormSubtitle,
    LeftCell,
    RightCellWithBorder,
    SalesTaxInput,
    ThreeItemsWrapper,
    TwoItemsWrapper,
    ValidationRadioInput,
} from "./client_modal";
import React, { ChangeEvent, useEffect } from "react";
import styled, { theme } from "../../theme";
import { highlightStyle } from "../../components/calculator/calculator";
import SelectList from "../../components/UI/select-list";
import { detect } from "detect-browser";

interface Props {
    formFields: ClientFields;
    stateList: string[];
    taxList: string[];
    billingTypeList: string[];
    showSubClientMenuList: boolean;

    handleInitialBillingInfoWrapper(value: string): void;
    handleFieldChange(e: ChangeEvent<HTMLInputElement>, val?: string): void;
    handleFieldState(value: string, name: string): void;
    handleFieldFromSelect(value: string, name: string): void;
    highlightedSection: string | null | undefined;
}

const BillingIformation = styled.div`
    width: 100%;
    border-bottom: 1px solid #338daa;
    padding-bottom: 1rem;

    label {
        color: ${theme.colors.grayRGBA};
    }

    > div {
        display: flex;
        align-items: flex-start;

        > label {
            margin-right: 20px;
        }
    }
`;

const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 30px !important;

    > label:hover,
    &:hover {
        cursor: pointer;
    }
`;

const SalesTaxWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    > label:first-child {
        margin-right: 15px;
        width: auto !important;
        min-width: 100px;
    }

    > label {
        width: 100% !important;
    }
`;

const BillingTypeWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > label:first-child {
        margin-right: 15px;
        width: auto !important;
        min-width: 100px;
    }

    > div {
        flex: 1;

        > div {
            flex: 1;

            > label {
                width: 100%;
            }
        }
    }

    > label {
        width: 100% !important;
    }
`;

const BillingTypeSelectList = styled(SelectList)`
    position: relative; 
    flex: 1;
    .select-wrapper {
        position: relative;
    }
    ul {
        position: absolute;
        top: -200%;
        left: 20;
        z-index: 1;
    }
    @media screen and (max-width: 1094px) {
        ul {
            top: -100%;
        }
    }
    .select-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: auto; 
        border-left: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-right: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-bottom: ${(props) =>
            props.uiInverted ? "0" : "1px solid hsla(0, 0%, 13.9%, 0.15)"};
        border-top: ${(props) =>
            props.uiInverted ? "1px solid hsla(0, 0%, 13.9%, 0.15)" : "0"};
        border-radius: ${(props) =>
            props.uiInverted ? "0.5em 0.5em 0 0" : "0 0 0.5em 0.5em"};
        margin-top: ${(props) => !props.uiInverted && "-4px"};
        margin-bottom: ${(props) => props.uiInverted && "-4px"};
        max-height: 300px;
        overflow: auto;
        background-color: #fff;
        z-index: 999;
    }
`;

const SalesTaxBillingTypeWrap = styled.div`
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
`;

const SalesTaxPercentLabel = styled.span`
    font-size: 20px;
    color: ${theme.colors.grayRGBA};
    padding: 0 10px;
`;

const BillingInfo = (props: Props): JSX.Element => {
    const browser = detect();
    const {
        stateList,
        handleInitialBillingInfoWrapper,
        handleFieldChange,
        handleFieldState,
        handleFieldFromSelect,
        billingTypeList,
        highlightedSection,
    } = props;
    let { formFields } = props;
    const billToParentVal = formFields.billToParent ? "1" : "0";

    useEffect(() => {
        handleInitialBillingInfoWrapper(billToParentVal);
    }, []);

    return (
        <ClientBlock>
            <BillingIformation>
                <ClientFormSubtitle>BILLING CONTACT</ClientFormSubtitle>
                <div>
                    <ClientFormLabel>BILL TO:</ClientFormLabel>
                    <RadioLabel>
                        <ValidationRadioInput
                            id="billToParent1"
                            name="billToParent"
                            type="radio"
                            checked={formFields.billToParent}
                            value="1"
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={!formFields.parentClientId}
                        />
                        Parent Client
                    </RadioLabel>
                    <RadioLabel>
                        <ValidationRadioInput
                            id="billToParent0"
                            name="billToParent"
                            type="radio"
                            checked={!formFields.billToParent}
                            value="0"
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                        />
                        This Client Directly
                    </RadioLabel>
                </div>
            </BillingIformation>
            <LeftCell>
                <TwoItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="billingFirstName">
                            First Name
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingFirstName"
                            name="billingDetails.firstName"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.firstName || ""}
                            onChange={(e) => handleFieldChange(e, "billing")}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <ClientFormLabel htmlFor="billingLastName">
                            Last Name
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingLastName"
                            name="billingDetails.lastName"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.lastName || ""}
                            onChange={(e) => handleFieldChange(e, "billing")}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="billingCompany">
                        Company Name
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingCompany"
                        name="billingDetails.company"
                        className="label"
                        type="text"
                        value={formFields.billingDetails.company || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="billingEmail">
                        Email
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingEmail"
                        name="billingDetails.email"
                        className="label"
                        type="email"
                        value={formFields.billingDetails.email || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <TwoItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="billingCellPhone">
                            Cell Phone
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingCellPhone"
                            name="billingDetails.cellPhone"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.cellPhone || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <ClientFormLabel htmlFor="billingHomePhone">
                            Home Phone
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingHomePhone"
                            name="billingDetails.homePhone"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.homePhone || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <TwoItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="billingOfficePhone">
                            Office Phone
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingOfficePhone"
                            name="billingDetails.officePhone"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.officePhone || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <ClientFormLabel htmlFor="billingFax">
                            Fax
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingFax"
                            name="billingDetails.fax"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.fax || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </TwoItemsWrapper>
                <div>
                    <ClientFormLabel htmlFor="billingWebsite">
                        Website
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingWebsite"
                        name="billingDetails.website"
                        className="label"
                        type="text"
                        value={formFields.billingDetails.website || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
            </LeftCell>
            <RightCellWithBorder>
                <ClientFormSubtitle>BILLING ADDRESS</ClientFormSubtitle>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="billingStreetAddress">
                        Street Address
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingStreetAddress"
                        name="billingDetails.streetAddress"
                        className="label"
                        type="text"
                        value={formFields.billingDetails.streetAddress || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>

                <div className="mb-2">
                    <ClientFormLabel htmlFor="billingUnitSuite">
                        Suite / Unit
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingUnitSuite"
                        name="billingDetails.unitSuite"
                        className="label"
                        type="text"
                        value={formFields.billingDetails.unitSuite || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <ThreeItemsWrapper>
                    <div>
                        <ClientFormLabel htmlFor="billingCityTown">
                            City
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingCityTown"
                            name="billingDetails.cityTown"
                            className="label"
                            type="text"
                            value={formFields.billingDetails.cityTown || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                    <div>
                        <SelectList
                            label={"State"}
                            selectList={stateList}
                            handleClickSelect={handleFieldState}
                            valueSelect={
                                formFields.billingDetails.stateProvince
                            }
                            flexDirection={"column"}
                            name="billingDetails.stateProvince"
                            smallPadding
                            customValue
                            noAddButton
                            noClearBox
                            uiDropArrow
                        />
                    </div>
                    <div>
                        <ClientFormLabel htmlFor="billingZIPCode">
                            Zip
                        </ClientFormLabel>
                        <ClientFormInput
                            id="billingZIPCode"
                            name="billingDetails.zipCode"
                            className="label"
                            type="number"
                            value={formFields.billingDetails.zipCode || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                    </div>
                </ThreeItemsWrapper>
                <div className="mb-2">
                    <ClientFormLabel htmlFor="billingCountry">
                        Country
                    </ClientFormLabel>
                    <ClientFormInput
                        id="billingCountry"
                        name="billingDetails.country"
                        className="label"
                        type="text"
                        value={formFields.billingDetails.country || ""}
                        onChange={handleFieldChange}
                        uiReportOnBlur={false}
                        autoComplete={
                            browser && browser.name === "chrome"
                                ? "new-password"
                                : "off"
                        }
                        list="autocompleteOff"
                    />
                </div>
                <SalesTaxBillingTypeWrap>
                    <BillingTypeWrap>
                        <ClientFormLabel>Billing Type</ClientFormLabel>
                        <BillingTypeSelectList
                            style={
                                highlightedSection === `billingType`
                                    ? highlightStyle
                                    : {}
                            }
                            selectList={billingTypeList}
                            handleClickSelect={handleFieldFromSelect}
                            selectWidth={190}
                            valueSelect={formFields.billingDetails.billingType}
                            flexDirection="row"
                            name="billingType"
                            placeholder
                            decorativeBorder
                            uiInverted
                            popup
                            smallPadding
                            required
                            hideAddButton
                        />
                    </BillingTypeWrap>
                    <SalesTaxWrap>
                        <ClientFormLabel htmlFor="billingSalesTax">
                            Sales Tax
                        </ClientFormLabel>
                        <SalesTaxInput
                            id="billingSalesTax"
                            name="billingSalesTax"
                            className="label"
                            type="text"
                            value={formFields.billingSalesTax || ""}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                        <SalesTaxPercentLabel>%</SalesTaxPercentLabel>
                    </SalesTaxWrap>
                </SalesTaxBillingTypeWrap>
            </RightCellWithBorder>
            {/*<ClientFormFooter>
                <SelectList
                    label="Sales Tax"
                    selectList={taxList}
                    handleClickSelect={handleFieldFromSelect}
                    selectWidth={190}
                    valueSelect={formFields.billingSalesTax}
                    flexDirection="row"
                    name="billingSalesTax"
                    placeholder
                    decorativeBorder
                    uiInverted
                    popup
                />
            </ClientFormFooter>*/}
        </ClientBlock>
    );
};

export default BillingInfo;
