import React, { FC, memo } from "react";

import BusinessWomanIcon from "../../assets/icons/icons8-businesswoman.svg";
import { SidebarItem } from "./sidebar_item";
import TagIcon from "../../assets/icons/icons8-pricing.svg";
import TruckIcon from "../../assets/icons/truck.svg";
import OverviewIcon from "../../assets/icons/overview_sidebar_icon.svg";
import styled from "../../theme";

export interface SidebarProps {
    /**
     * Expanded sidebar mode
     */
    // expanded: boolean;
    /**
     * Current active value
     */
    activeValue?: string;
    /**
     * Navigation callback
     * @param to Navigation value
     */
    onNavigateTo(to: string): void;
}

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    min-width: 65px;
`;

export const Sidebar: FC<SidebarProps> = memo((props) => (
    <Container>
        <SidebarItem
            // expanded={props.expanded}
            value="overview"
            active={props.activeValue === "overview"}
            label="Overview"
            icon={<OverviewIcon width="1em" height="1em" />}
            onItemClick={props.onNavigateTo}
        />
        <SidebarItem
            // expanded={props.expanded}
            value="clients"
            active={props.activeValue === "clients"}
            label="Clients"
            icon={<BusinessWomanIcon width="1em" height="1em" />}
            onItemClick={props.onNavigateTo}
        />
        <SidebarItem
            // expanded={props.expanded}
            value="myshop"
            active={props.activeValue === "myshop"}
            label="My Shop"
            icon={<TagIcon width="1em" height="1em" />}
            onItemClick={props.onNavigateTo}
        />
        <SidebarItem
            // expanded={props.expanded}
            value="suppliers"
            active={props.activeValue === "suppliers"}
            label="Suppliers"
            icon={<TruckIcon width="1em" height="1em" />}
            onItemClick={props.onNavigateTo}
        />
    </Container>
));
