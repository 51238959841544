import {
    Button,
    Checkbox,
    Form,
    FormField,
    Input,
    Modal,
    ModalContent,
    Select,
    TextArea,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { TabPanel, Tabs } from "react-tabs";
import styled, { breakpoints, css, theme } from "../../theme";

import ErrorsIconE from "../../assets/icons/error.svg";
import { HelpPopup } from "../content/help_popup";
import NoIssuesIconE from "../../assets/icons/arrow_checked_issues.svg";
import { PageContent as PageContentE } from "../content/page_content";
import SearchSelect from "../../components/UI/search_select/search_select";
import SelectList from "../UI/select-list";
import SidebarCollapseIconE from "../../assets/icons/slider_arrow_right.svg";
import SidebarExpandIconE from "../../assets/icons/slider_arrow_left.svg";
import TooltipPleastToPatternE from "../../assets/icons/tooltip/pleast_to_pattern.svg";
import TooltipRailroadE from "../../assets/icons/tooltip/railroad.svg";
import TooltipUpTheRollE from "../../assets/icons/tooltip/up_the_roll.svg";
import TooltipVertStripsE from "../../assets/icons/tooltip/vert_strips.svg";
import timelineChecked from "../../assets/icons/timeline-checked.png";

export const PageContent = styled(PageContentE)`
    margin-bottom: 0;
`;

export const ErrorsIcon = styled(ErrorsIconE)`
    width: 20px;
`;

export const SidebarCollapseIcon = styled(SidebarCollapseIconE)`
    width: 20px;
`;

export const SidebarExpandIcon = styled(SidebarExpandIconE)`
    width: 20px;
`;

export interface CalculatorQuestionNumberProps {
    uiError?: boolean;
}
export interface CalculatorFullnessProps {
    draperyFullness?: string;
    uiError?: boolean;
}

export interface CalculatorCustomizeProps {
    uiError?: boolean;
}

export interface CalculatorRadioWrapperWithImg {
    uiChecked?: boolean;
    uiDisabled?: boolean;
    noPadding?: boolean;
    isError?: boolean;
    draperyFullness?: string;
}

export interface VContainerProps {
    uiRightBorder?: boolean;
    uiBottomBorder?: boolean;
    uiTopBorder?: boolean;
    uiLeftBorder?: boolean;
    uiPaddingLeft?: boolean;
}

export interface PrependAppendInputGroupProps {
    uiSmall?: boolean;
}

export interface HContainerProps {
    uiWrap?: boolean;
    uiStretch?: boolean;
    uiBaseLine?: boolean;
    text?: string;
    uiGap?: boolean;
    uiColumnDirection?: boolean;
}

export interface CalculatorFieldInputProps {
    smallPadding?: boolean;
    uiWidth?: number;
    uiFlexCol?: boolean;
    uiMinWidth?: number;
}

export interface DraperyHeaderProps {
    noMarginTop?: boolean;
    noMarginBottom?: boolean;
    uiTimeStatus?: boolean;
    uiTimeStatusChecked?: boolean;
}

export interface ImageProps {
    uiUrl?: string;
    uiUrlHover?: string;
}

export interface SummaryComponentTableCellProps {
    uiFlex?: number;
}

export interface SummerTableProps {
    text?: string;
}

export interface SummaryTableProps {
    position?: string;
    text?: string;
    last?: string;
}

export const BasicButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    // border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

export const CalculatorSectionContainer = styled.div`
    flex: 1 1 auto;
    background-color: ${theme.colors.contentBackground};
    margin: 0;
    padding: 0;
    box-shadow: 2px 2px 15px 0 #ddd;
    height: calc(100% - 40px);
    width: 100%;
    position: relative;
`;

export const CalculatorForm = styled(Form)`
    width: 100%;
    height: 100%;
    background-color: white;
    margin: 0 auto;
    font-weight: 400;
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const CalculatorFormField = styled(
    FormField
)<CalculatorRadioWrapperWithImg>`
    display: block;
    margin-bottom: 0;
    padding: 7px 0 8px 0;
    position: relative;
    transition: all 0.2s;
    margin: ${(props) =>
        props.draperyFullness === "calculator" ? "1rem 0" : "initial"};

    > .label {
        border: 0;
        padding-left: 30px;
        font-weight: normal;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            width: 1px;
            background-color: #5c5d5f;
        }
    }

    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}

    ${(props) =>
        props.noPadding &&
        css`
            padding: 0;
        `}
    
        ${(props) =>
        props.isError &&
        css`
            background-color: rgba(255, 0, 0, 0.4);
            border-radius: 8px;
        `}
`;

export const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 10px !important;

    > label:hover,
    &:hover {
        cursor: pointer;
    }

    &.design_category {
        padding-left: 10px;
        margin-right: 30px !important;
        font-size: 16px;
        line-height: normal;
        > label {
            background-color: rgb(230, 230, 230);
            border: 1px solid rgb(179, 179, 179);
            width: 18px;
            height: 18px;
        }
        &.active {
            > label {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: ${({ theme }) => theme.colors.primary};
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                }
            }
        }
    }
`;

export const RadioInput = styled(ValidationPopupInput)`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 0;
    margin-right: 5px;
    border: 1px solid ${theme.colors.grayRGBA};
    border: ${(props) =>
        props.checked ? `5px solid ${theme.colors.blue}` : ""};

    input {
        visibility: hidden;
    }
`;

export const CalculatorFormQuestionNumber = styled.div<CalculatorQuestionNumberProps>`
    background-color: ${(props) => (props.uiError ? "#8b0000" : "#3B97B1")};
    height: 30px;
    width: 30px;
    border-radius: 50px;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    vertical-align: middle;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CalculatorFormFieldHeader = styled.div`
    display: flex;
    align-items: center;

    .subtitle {
        width: 100%;
        margin-bottom: 10px;
        padding-left: 10px;
    }
`;

export const CalculatorFormFieldLabel = styled.span<CalculatorFullnessProps>`
    padding-left: ${(props) =>
        props.draperyFullness === "calculator" ? "10px" : "20px"};
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        width: ${(props) =>
            props.draperyFullness === "calculator" ? "0" : "1px"};
        background-color: #5c5d5f;
    }
    color: #545454;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const DraperyHeader = styled.h2<DraperyHeaderProps>`
    color: #3b97b1;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
    }
    ${(props) =>
        props.noMarginTop &&
        css`
            margin-top: 0;
        `};
    ${(props) =>
        props.noMarginBottom &&
        css`
            margin-bottom: 0;
        `};
    ${(props) =>
        props.uiTimeStatus &&
        css`
            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                background-color: ${props.uiTimeStatusChecked
                    ? "#eef5f6"
                    : "#fff"};
                border-radius: 50%;
                border: 2px solid #438cac;
                left: -77px;
                top: 50%;
                transform: translateY(-50%);
            }
        `};
    ${(props) =>
        props.uiTimeStatusChecked &&
        css`
            &::after {
                position: absolute;
                content: "";
                display: block;
                width: 30px;
                height: 40px;
                left: -68px;
                top: -24%;
                background-image: url(${timelineChecked});
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        `};
    &.firstCheck::after {
        top: -8%;
    }
`;

export const RegularInputGroup = styled.div`
    margin-top: 15px;
    margin-left: 15px;
    display: block;
    padding: 3px 10px;
    width: fit-content;

    & label {
        border: solid #545454 1px;
        padding: 0.4em 0.6em !important;
    }
`;

export const RegularInputGroupLabel = styled.div`
    margin: 0 8px;
    display: inline-block;
`;

export const CalculatorRadioGroup = styled.div`
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 5px 0 5px 10px;
    transition: all 0.2s;
`;

export const CalculatorRadioGroupWrap = styled(CalculatorRadioGroup)`
    flex-wrap: wrap;

    .subtitle {
        width: 100%;
        margin-bottom: 15px;
    }

    &.isError {
        background: rgba(255, 0, 0, 0.4);
        border-radius: 8px;
    }
`;

export const CalculatorRadio = styled(Input)`
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 0;
    margin-right: 5px;
    position: relative;
    border: 1px solid #b3b3b3;
    background-color: #e6e6e6;

    &::before {
        content: "";
        position: ${(props) => props.checked && "absolute"};
        border-radius: 50%;
        background-color: #3b97b1;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
    }

    > .input {
        visibility: hidden;
    }

    > svg {
        margin-left: 10px;
        width: 20px;
    }
`;

export const CalculatorImageRadio = styled(Input)`
    width: 0;
    height: 0;
    font-size: 0;
    margin-right: 5px;
    position: relative;
    visibility: hidden;

    &::before {
        content: "";
        position: ${(props) => props.checked && "absolute"};
        border-radius: 50%;
        background-color: #63b6d4;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
    }

    > .input {
        visibility: hidden;
    }

    > svg {
        margin-left: 10px;
        width: 20px;
    }
`;

export const CalculatorImageRadioImg = styled.img<ImageProps>`
    width: 100%;
    height: 70px;
    background-image: url(${(props) => props.uiUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

export const CalculatorRadioWrapper = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
    position: relative;
    padding-left: 10px;
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    > label:hover,
    &:hover {
        cursor: pointer;
    }
`;

export const CalculatorRadioWrapperWithImg = styled.label<CalculatorRadioWrapperWithImg>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em;
    border: 1px solid #969696;
    border-radius: 0.5em;
    width: 165px;
    height: 125px;
    margin: 0 25px 25px 0;
    ${(props) =>
        props.uiDisabled &&
        css`
            background-color: #f8f6f9;
        `};
    ${(props) =>
        props.uiChecked &&
        css`
            border: 1px #3b97b1 solid;
            background: #3b97b126;
            color: #3b97b1;
        `};

    &:hover {
        ${(props) =>
            !props.uiDisabled &&
            css`
                cursor: pointer;
                border: 1px #3b97b1 solid;
                background: #3b97b126;
                color: #3b97b1;
            `};
    }

    img {
        width: 100%;
    }

    svg {
        margin: auto;
    }
`;

export const CalculatorSelect = styled(Select)`
    width: 55px;
    background-color: #fff;

    > .select {
        padding: 0.4em 0.6em !important;
        border-radius: 8px;
        background-color: white;
    }
`;

export const CalculatorCheckbox = styled(Checkbox)`
    margin: 6px 0 6px 10px;
`;

export const NoIssuesIcon = styled(NoIssuesIconE)`
    width: 25px;
`;

export const TooltipUpTheRoll = styled(TooltipUpTheRollE)`
    margin: 20px 0;
    width: 200px;
`;

export const TooltipRailroad = styled(TooltipRailroadE)`
    margin: 20px 0;
    width: 200px;
`;

export const TooltipPleastToPattern = styled(TooltipPleastToPatternE)`
    margin: 20px 0;
    height: 150px;
`;

export const TooltipVertStrips = styled(TooltipVertStripsE)`
    margin: 20px 0;
    height: 150px;
`;

export const TooltipHeader = styled.div`
    margin: 10px 0;
    font-weight: 600;
`;

export const TooltipText = styled.div`
    margin: 10px 0;
`;

export const TooltipImgContainer = styled.div`
    background: white;
    border: 1px solid #969696;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    max-width: 88%;
    > img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export const TooltipPopup = styled(HelpPopup)`
    max-width: 300px;
    max-height: 50%;
    display: flex !important;

    > svg {
        color: #f3dfe4;
    }
`;

export const TooltipWidePopup = styled(HelpPopup)`
    max-width: 400px;
    width: 60%;
    max-height: 50%;
    display: flex !important;
`;

export const TooltipContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 25px);
`;

export const TooltipRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TooltipCell = styled.div`
    flex: 1;

    &:first-child {
        margin-right: 5px;
    }

    &:last-child {
        margin-left: 5px;
    }
`;

export const CalculatorFormWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CalculatorFormLabel = styled.label`
    margin-right: 10px;
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
    ${(props) =>
        props.className === "application-name" &&
        css`
            margin-right: 20px !important;
        `}
    ${(props) =>
        props.className === "fabrication-price" &&
        css`
            display: flex;
            justify-content: flex-end;
            margin-right: 2px !important;
        `}
        &.disabledColor {
        color: lightgray;
    }
`;

export const CalculatorFieldInput = styled.div<CalculatorFieldInputProps>`
    display: flex;
    transition: all 0.2s;
    ${(props) =>
        props.uiFlexCol &&
        css`
            flex-direction: column;
        `};
    align-items: ${(props) => (props.uiFlexCol ? "flex-start" : "center")};
    ${(props) =>
        props.smallPadding &&
        css`
            margin-bottom: 10px;

            label {
                padding-top: 0.2em;
                padding-bottom: 0.2em;
            }
        `};
    ${(props) =>
        props.uiWidth &&
        css`
            max-width: ${props.uiWidth}px;
            width: 100%;

            > div {
                width: 100%;
            }
        `};
    ${(props) =>
        props.uiMinWidth &&
        css`
            min-width: ${props.uiMinWidth}px;
            width: 100%;

            > div {
                width: 100%;
            }
        `};

    .ml {
        margin-left: 35px;
        flex-shrink: 0;
    }

    .input {
        width: 100%;
    }

    .name-input {
        border: solid #545454 1px;
    }
    .price-input {
        width: 30%;
        input {
            text-align: center;
        }
    }
`;

export const CalculatorInput = styled(Input)`
    border-radius: 0.5em;

    .input {
        width: 100%;
    }
`;

export const CheckboxInputWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    ${CalculatorFormLabel} {
        min-width: 115px;
    }

    ${CalculatorFieldInput} {
        margin-bottom: 15px;
    }

    ${CalculatorInput} {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }
`;

export const ContentCalculator = styled(ModalContent)`
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    .react-tabs__tab-list {
        border-bottom: 6px solid #338daa;
        margin-bottom: 0;
    }

    .react-tabs__tab {
        margin-right: 10px;
        border-radius: 5px 5px 0 0;
        border: 1px solid #aaa;
        border-bottom: none;
        bottom: 0;
        background-color: #fcfafa;

        & :last-child {
            margin-right: 0;
        }
    }

    .react-tabs__tab-panel {
        flex: 1;
    }

    .react-tabs__tab--selected {
        background-color: #338daa;
        color: #fff;
        border-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel--selected {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
    }

    .react-tabs__tab:focus::after {
        position: relative;
    }
`;

export const ContentCalculatorSub = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 30px 0;
    height: 100%;
`;

export const CalculatorTabs = styled(Tabs)`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 30px;
    height: 100%;
`;

export const TabPanelBox = styled(TabPanel).attrs({
    className: "react-tabs__tab-panel",
})`
    overflow: auto;
    flex: 1 auto !important;
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
    padding-right: 10px;
    min-width: 500px;
`;

export const HContainer = styled.div<HContainerProps>`
    display: flex;
    width: 100%;
    align-items: ${(props) => {
        switch (true) {
            case props.uiStretch:
                return "stretch";
            case props.uiBaseLine:
                return "baseline";
            default:
                return "center";
        }
    }};
    ${(props) =>
        props.uiWrap &&
        css`
            flex-wrap: wrap;
        `};
    ${(props) =>
        props.text &&
        css`
            border: 1px solid #e6e6e6;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            margin-bottom: 50px;
        `};
    ${(props) =>
        props.uiGap &&
        css`
            gap: 50px;
        `};
    ${(props) => props.uiColumnDirection && `flex-direction: column;`}

    & .work_order_edit_mode_alert_container {
        background: #f6f6f6;
        min-height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        border: none;

        & .work_order_edit_mode_alert_message {
            font-size: 14px;
            color: #545454;
            line-height: 20px;
        }
    }
`;

export const CalculatorExtraDiv = styled.div`
    font-weight: 400;
`;

export const PrependAppendInputGroup = styled.div<PrependAppendInputGroupProps>`
    margin-left: 15px;
    display: block;
    padding: 3px 10px;
    background-color: #f3f0f5;
    width: fit-content;
    border-radius: 0.5em;
    transition: all 0.2s;
    color: #545454;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & label {
        border: solid #545454 1px;
        padding: 0.4em 0.6em !important;
        border-radius: 6px;
        background-color: #fff;
    }

    ${(props) =>
        props.uiSmall &&
        css`
            background-color: transparent;
            margin-left: 0;
            padding: 3px 0;

            & label {
                padding: 0.2em 0.4em !important;
            }
            ${CalculatorCheckbox} {
                margin: 0;
                padding: 0 !important;
                border: none;
            }
        `};
`;

export const StyleSelectContainer = styled.div`
    background-color: #d6eaf0;
    border-radius: 0.5em;
    padding: 5px 10px;
    width: 225px;
    margin-left: 210px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #545454;
    font-family: Inter;
    display: flex;
    align-items: center;
    input {
        padding: 0.37em 2em 0.37em 1em !important;
    }

    .select-wrapper {
        width: auto;
    }
`;

export const PrependAppendInputGroupLabel = styled.div`
    margin: 0 8px;
    display: inline-block;
    color: #545454;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const PrependAppendInput = styled(Input)`
    width: 70px;
    border-radius: 0.5em;
`;

export const VContainer = styled.div<VContainerProps>`
    display: flex;
    flex-direction: column;
    padding-right: 0.7rem;
    ${(props) =>
        props.uiPaddingLeft &&
        css`
            padding-left: 0.7rem;
        `};
    ${(props) =>
        props.uiRightBorder &&
        css`
            border-right: 1px solid ${theme.colors.lightGrayText};
        `};
    ${(props) =>
        props.uiBottomBorder &&
        css`
            border-bottom: 1px solid ${theme.colors.lightGrayText};
        `};
    ${(props) =>
        props.uiTopBorder &&
        css`
            border-top: 1px solid ${theme.colors.lightGrayText};
        `};
    ${(props) =>
        props.uiLeftBorder &&
        css`
            border-left: 1px solid ${theme.colors.lightGrayText};
        `};

    .title {
        color: #376c83;
        text-transform: capitalize;
    }

    .noCapitalize.title {
        text-transform: unset;
    }

    .title_ml {
        margin-left: 20px;
    }

    ${CalculatorFormLabel} {
        margin-right: 0;
    }

    ${PrependAppendInputGroup} {
        padding-bottom: 0;
    }
`;
export const TextAreaContainer = styled.div`
    width: 90%;
    display: flex;
    margin-top: 10px;

    span {
        margin-top: 5px;
        margin-right: 10px;
    }

    ${TextArea} {
        width: 100%;
        border: solid #545454 1px;
        height: 80px;
    }
`;

export const NotesContainer = styled.div`
    width: 100%;

    ${TextArea} {
        width: 100%;
        height: 110px;
        border-radius: 0.5em;
    }
`;

export const NoteLetters = styled.div`
    width: 100%;
    text-align: right;
`;

export const CancelModal = styled(Modal)`
    width: 350px;
    height: 200px;
    display: flex;
    padding: 15px 15px;

    ${HContainer} {
        justify-content: space-between;
    }
`;

export const CancelModalContent = styled.div`
    width: 300px;
    margin: auto auto;
    font-size: 18px;
    text-align: center;
`;

export const CancelModalBtn = styled(Button)`
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    width: 75px;
`;

export const DisableAutocompleteInput = styled.input`
    display: none;
`;

export const SummaryContainer = styled.div`
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding-top: 100px;
    @media screen and (max-width: 1024px) {
        width: 92%;
        margin-left: 15px;
    }
`;

export const SummaryTimeline = styled.div`
    width: 14px;
    border-right: 1px solid #bad5e1;
    border-left: 1px solid #bad5e1;
    background-color: #438cac;
    flex-shrink: 0;
    margin-right: 30px;
    margin-bottom: 145px;
`;

export const SummaryContent = styled.div`
    flex-grow: 1;
    width: 100%;
`;

export const SummaryInfoBlock = styled.div`
    padding: 40px 20px;
    border-top: 1px solid #5c5d5f;
    position: relative;

    &:first-child {
        border-top: none;
        padding-top: 0;
    }
`;

export const SummaryTable = styled.div`
    display: flex;
    // margin-right: 15px;
    width: 100%;
`;

export const SummaryTableCell = styled.div<SummaryTableProps>`
    padding: 10px;
    text-align: start;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-weight: 400 !important;
    ${(props) =>
        props.position === "center" &&
        css`
            text-align: center;
            color: #3b97b1;
            background-color: #ecf6f9;
            font-weight: 500 !important;
            line-height: 16px !important;
            padding: 24px;
        `}
    ${(props) =>
        props.text === "fabrics" &&
        props.last !== "last" &&
        css`
            border-right: 1px solid #dadada;
            height: 100%;
        `}
        ${(props) =>
        props.text === "fabrics" &&
        props.last === "last" &&
        css`
            border-right: none;
        `}
        ${(props) =>
        props.text === "title" &&
        css`
            background-color: #ecf6f9;
            color: #3b97b1;
            display: flex;
            font-weight: 500 !important;
            line-height: 16px !important;
            padding: 24px;
        `};

    ${(props) =>
        props.text === "content" &&
        css`
            border-right: 1px solid rgba(218, 218, 218);
            border-left: 1px solid rgba(218, 218, 218);
            //height: -webkit-fill-available;
        `};
    ${(props) =>
        props.text === "total-per-unit" &&
        css`
            border-right: 1px solid rgba(218, 218, 218);
            border-left: 1px solid rgba(218, 218, 218);
            height: -webkit-fill-available;
        `};
    &:last-child {
        border-bottom: none;
    }

    ${CalculatorInput} {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }

    .select-wrapper {
        input {
            padding: 0.25em 2em 0.25em 1em !important;
        }
    }
    .boldText {
        font-weight: 600;
    }
    @media (max-width: 1620px) {
        .nowrap {
            white-space: nowrap;
        }
    }
    @media (min-width: 1621px) {
        .nowrap {
            white-space: normal;
        }
    }
    .nonBold {
        font-weight: 400;
    }
    p,
    span,
    div {
        font-weight: 400;
    }
`;

export const SummaryTableCol = styled.div<SummerTableProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${(props) =>
        props.text === "fabrics" &&
        css`
            width: 100%;
        `};
    ${(props) =>
        props.text === "not-fabrics" &&
        css`
            width: 50%;
        `};

    &:last-child {
        border-right: none;
    }
`;

export const SummaryComponentTable = styled.div`
    display: flex;
    margin-right: 15px;
    flex-direction: column;
`;

export const SummaryComponentTableCell = styled.div<SummaryComponentTableCellProps>`
    border-right: 1px solid #438dae;
    padding: 10px;
    word-break: break-all;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        border-right: none;
    }

    ${CalculatorInput} {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }

    .select-wrapper {
        input {
            padding: 0.25em 2em 0.25em 1em !important;
        }
    }

    ${(props) =>
        props.uiFlex &&
        css`
            flex: ${props.uiFlex};
        `};
`;

export const SummaryComponentTableRow = styled.div`
    display: flex;
    border-bottom: 1px solid #438dae;
    position: relative;

    &:last-child {
        border-bottom: none;
    }
`;

export const SummaryFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
`;

export const CancelBtn = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    color: #fff;
    display: block;
`;

export const AddSummaryComponentBtn = styled(CancelBtn)`
    margin-top: 10px;
`;

export const DeleteBtn = styled(Button)`
    min-width: 50px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline-color: #fff0;
    padding: 10px;
    top: 10px;
    font-size: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    right: -5px;
`;

export const DuplicateBtn = styled(CancelBtn)`
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 10px;
    color: #3b97b1;
    border: 1px solid #3b97b1;
    background-color: #fff;

    ${(props) =>
        props.uiDisabled &&
        `
            color: #D4D4D4;
            border-color: #D4D4D4;
        `}
    &:hover {
        background: #f9f9f9;
    }
`;

export const EditButton = styled(CancelBtn)`
    margin-left: auto;
    margin-bottom: auto;
`;

export const Separator = styled.div`
    height: 30px;
    width: 100%;
`;

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    ${breakpoints.down(
        "lg",
        css`
            flex-wrap: wrap;
        `
    )};
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    ${CalculatorFieldInput} {
        margin-right: 15px;

        label {
            width: 100%;
        }

        label:last-child {
            padding: 6px 25px 6px 6px;
        }

        &:first-child {
            label:last-child {
                padding: 6px 25px 6px 6px !important;
            }
        }

        .search-select {
            padding: 6px 25px 6px 6px !important;
        }

        &:last-child {
            margin-right: 0;

            label:last-child {
                padding: 6px 6px;
            }
        }

        &:nth-child(2) {
            input {
                padding: 6px 25px 6px 6px !important;
            }
        }
    }
`;

export const HeaderMinRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;
    ${breakpoints.down(
        "lg",
        css`
            flex-direction: row;
            width: auto;
            align-items: center;
            margin-top: 15px;

            > label {
                margin-right: 24px;
            }
        `
    )};

    ${HContainer} {
        height: 35px;

        & > label {
            flex-shrink: 0;
        }
    }

    ${CalculatorFieldInput} {
        label {
            padding: 6px 6px;
        }
    }
`;

// temp
export const DrawingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 460px;
`;

export const FooterContainer = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 60px;
    right: 0;
    background: #fbfbfb;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 20px;
    overflow-y: hidden;
    z-index: 100;
`;

export const FooterSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 2px 2px 15px 0 #ddd;
    padding: 8px 65px 8px 30px;
    background: #ddd;
    border-top: 1px solid #9d9a9a;
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const FooterCancelBtn = styled(BasicButton)`
    color: #3b97b1;
    &:hover {
        background: #eeeeee;
    }
    background: #f6f6f6;
    font-weight: 500;
    border: 1px solid #3b97b1;

    &:hover {
        background: #eeeeee;
    }
`;

export const FooterSaveBtn = styled(BasicButton)`
    margin-left: auto;
    font-weight: 500;
    color: #3b97b1;
    background: #f6f6f6;
    font-weight: 500;
    border: 1px solid #3b97b1;
    &:hover {
        background: #eeeeee;
    }
`;

export const FooterSaveNextBtn = styled(BasicButton)`
    // width: 180px;
    margin-left: 15px;
    font-weight: 500;
`;

export const SidebarButton = styled.div`
    background: #3b97b1;
    width: 30px;
    height: 60px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
`;

export const SidebarContainer = styled.div`
    border-left: 1px solid #3b97b1;
    margin-left: 10px;
    width: 350px;
    position: relative;
`;

export const SidebarContainerSub = styled.div`
    position: absolute;
    overflow: auto;
    top: 0;
    bottom: 0;
`;

export const SidebarRow = styled.div`
    margin-bottom: 10px;
`;

export const SidebarFormLabel = styled(CalculatorFormLabel)`
    width: 60px;
`;

export const SidebarSearchSelect = styled(
    SearchSelect
)<CalculatorRadioWrapperWithImg>`
    flex: 1;
    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};

    label {
        padding: 5px 25px 5px 10px;
    }
`;

export const SidebarSelectList = styled(
    SelectList
)<CalculatorRadioWrapperWithImg>`
    flex: 1;
    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};

    input {
        padding: 5px 25px 5px 10px !important;
    }
`;

export const SidebarWorkOrdersFormInput = styled(CalculatorInput)`
    padding: 5px 10px 5px 10px !important;
    width: 250px;
    margin-left: 25px;
`;

export const SidebarIssuesContainer = styled.div`
    width: 100%;

    margin-bottom: 5px;
    padding: 10px 20px 10px 10px;

    ul {
        margin: 0;
        padding: 3px 25px 3px 25px;
    }
`;

export const SidebarIssuesText = styled.div`
    font-weight: 400;
    color: rgba(27, 28, 29, 0.87);
    font-size: 15px;
    padding: 3px 25px 3px 25px;
`;

export const SidebarIssuesHeader = styled.div`
    color: #3b97b1;
`;

export const SidebarIssuesTitle = styled.div`
    color: #3b97b1;
    border-top: 1px #969696 solid;
    padding-top: 5px;
    margin-top: 2px;
`;

export const SidebarAlertTitle = styled.div`
    color: #3b97b1;
    font-weight: 500;
    font-style: italic;
    padding-top: 5px;
    margin-top: 2px;
`;

export const SidebarNoIssues = styled.div`
    border-top: 1px #969696 solid;
    font-weight: 400;
    padding-top: 5px;
    margin-top: 2px;
    font-style: italic;
    display: flex;
    align-items: center;

    > span {
        padding-top: 7px;
    }
`;

export const DraperyFullnessTooltip = styled.div`
    .industry-standards {
        background: white;
        border: 1px solid black;
        margin: 10px 20px;
        padding: 10px;

        .industry-standards-table {
            display: flex;

            .industry-standards-column {
                display: flex;
                flex-direction: column;
            }

            .border {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid black;
            }
        }
    }
`;

export const ImageView = styled.img`
    max-width: 100%;
    max-height: 70px;
`;

export const FabricImageBox = styled.div`
    position: relative;
    width: 100%;
    height: inherit;
    padding-top: 50%;
    margin: 50px auto 0 0;
    min-width: 150px;
    min-height: 78px;
`;

export const FabricImageSubBox = styled.div`
    border: 1px solid black;
    border-radius: 0.7em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const FabricImageView = styled.img`
    width: 100%;
    border-radius: 10px;
    min-height: 128px;
    max-height: 320px;
    object-fit: contain;
`;

export const FabricButtonContainer = styled.div`
    margin: 15px auto;
    text-align: center;
`;

export const FabricButton = styled.button`
    border-radius: 8px;
    min-width: 100px;
    background-color: #3b97b1;
    border: 0;
    color: #fff;
    font-size: 15px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    outline: none;
    &:hover {
        background-color: #44aecc;
    }
`;

export const ValidationErrorElement = styled.li`
    cursor: pointer;
    &:hover {
        color: #3b97b1;
    }
`;

export const ValidationConfirmationElement = styled.div`
    cursor: pointer;

    &:hover {
        color: #3b97b1;
    }
`;

export const ValidationConfirmationCheckbox = styled(Checkbox)`
    margin: 2px 2px;
`;

export const ValidationConfirmationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const AddLiningButton = styled(BasicButton)`
    padding: 0.5em 1.5em;
    margin-top: 60px;
    margin-left: 15px;
`;

export const WorkOrderNumber = styled.div`
    color: ${(props) => (props.className === "wo" ? "#3b97b1" : "#545454")};
    padding-top: 3px;
    cursor: ${(props) => (props.className === "wo" ? "pointer" : "default")};
    text-align: ${(props) => (props.className === "left" ? "left" : "center")};
    min-height: 140px;
    div {
        font-size: 16px;
        color: #545454;
        &:first-child {
            p {
                &:first-child {
                    font-weight: bold;
                }
            }
        }
    }
    p {
        margin: 0 0 1px;
        font-size: 16px;
        color: #545454;
    }

    &:hover {
        ${(props) =>
            props.className === "woNumber"
                ? `
            color: #3b97b1;
            cursor: pointer;
        `
                : ""}
    }
`;

export const ResetPanelConfirmation = styled.div`
    color: ${theme.colors.grayRGBA};

    span {
        color: ${theme.colors.primary};
        font-style: italic;
        padding-top: 10px;
        display: block;
    }
`;

export const BreadCrumbSeparator = styled.span`
    font-size: 14pt;
    font-weight: 400;
    color: ${theme.colors.grayRGBA};
    padding: 0 5px;
`;

export const ClientNameSeperator = styled.span`
    font-size: 16pt;
    font-weight: 400;
    color: ${theme.colors.grayRGBA};
    padding: 0 5px;
`;

export const BreadCrumbName = styled.div`
    color: ${theme.colors.grayRGBA};
    font-size: 15pt;
`;
