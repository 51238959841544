import React from "react";
import styled from "../../../theme";

interface DetailValueProps {
    label?: string;
    value: string | JSX.Element;
    clickable?: boolean;
    className?: string;
    href?: string;
    target?: string;
}

const Container = styled.div`
    margin-bottom: 4px;
    display: flex;
`;

const Label = styled.span`
    color: #3b97b1;
    margin-right: 3px;
    word-break: normal;
`;

const Link = styled.a`
    color: rgba(0, 0, 0, 0.87);

    &:hover {
        color: #41a1dc;
    }
`;

const Value = styled.div`
    word-break: break-all;
`;

const DetailValue: React.FunctionComponent<DetailValueProps> = (props) => (
    <Container>
        {props.label && <Label>{props.label}</Label>}
        {props.clickable ? (
            <Link href={props.href} target={props.target}>
                {props.value}
            </Link>
        ) : (
            <Value>{props.value}</Value>
        )}
    </Container>
);

export default DetailValue;
