import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { WorkroomSetting } from "./interfaces";

export const ADD_WORKROOM_SETTING = "workroomSetting/ADD";
export type AddWorkroomSettingAction = ActionDescriptor<typeof ADD_WORKROOM_SETTING,
    Omit<WorkroomSetting, "id"> & { accountId: number },
    WorkroomSetting
>;

export const EDIT_WORKROOM_SETTING = "workroomSetting/EDIT";
export type EditWorkroomSettingAction = ActionDescriptor<typeof EDIT_WORKROOM_SETTING,
    Omit<WorkroomSetting, "type" | "name">, {}
>;

export const GET_WORKROOM_SETTING = "workroomSetting/GET";
export type GetWorkroomSettingAction = ActionDescriptor<typeof GET_WORKROOM_SETTING, { id: number }, WorkroomSetting>;

export const DELETE_WORKROOM_SETTING = "workroomSetting/DELETE";
export type DeleteWorkroomSettingAction = ActionDescriptor<typeof DELETE_WORKROOM_SETTING, { id: number }, {}>;

export const SEARCH_WORKROOM_SETTINGS = "workroomSetting/SEARCH";
export type SearchWorkroomSettingsAction = ActionDescriptor<typeof SEARCH_WORKROOM_SETTINGS, {
    accountId: number;
    type?: number;
}, WorkroomSetting[]>;

export interface WorkroomSettingActionsMap {
    [ADD_WORKROOM_SETTING]: AddWorkroomSettingAction;
    [EDIT_WORKROOM_SETTING]: EditWorkroomSettingAction;
    [GET_WORKROOM_SETTING]: GetWorkroomSettingAction;
    [DELETE_WORKROOM_SETTING]: DeleteWorkroomSettingAction;
    [SEARCH_WORKROOM_SETTINGS]: SearchWorkroomSettingsAction;
}
