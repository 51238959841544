import React, {FormEvent, PureComponent} from "react";
import { connect } from "react-redux";
import {DraperyModule, RootState} from "../redux/modules";
import {
    Modal,
    ModalActions,
    ModalHeader as ModalHeaderE,
    ValidationForm,
    ModalContent,
    ValidationPopupInput
} from "@ramble/ramble-ui";
import styled, {theme} from "../theme";
import {CancelButton, SaveButton} from "../containers/supplier/supplier_modal";
import Bind from "lodash-decorators/bind";
import {SearchSelectProps} from "../components/UI/search_select/search_select";

const ModalContainer = styled(Modal)`
    width: 40%;
    height: 40%;
    top: 15%;
    left: 15%;
`;
const ModalHeader = styled(ModalHeaderE)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        font-size: 26px;
    }
`;

const ModalContentRoom = styled(ModalContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;

    .react-tabs__tab-list {
        border-bottom: 6px solid #338daa;
        margin-bottom: 0;
    }

    .react-tabs__tab {
        margin-right: 10px;
        border-radius: 5px 5px 0 0;
        border: 1px solid #aaa;
        border-bottom: none;
        bottom: 0;
        background-color: white;
        color: ${theme.colors.grayRGBA};

        &:last-child {
            margin-right: 0;
        }
    }

    .react-tabs__tab-panel {
        flex: 1;
    }

    .react-tabs__tab--selected {
        background-color: #338daa;
        color: #fff;
        border-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel--selected {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
    }

    .react-tabs__tab:focus::after {
        position: relative;
    }
`;
const RoomFormInput = styled(ValidationPopupInput)`
    padding: 0.35em 0.5em;
    width: 50%;
`;

const SearchSelectWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;

const ModalForm = styled(ValidationForm)`
    height: 100%;
    min-height: inherit;
`;

export const RoomLabel = styled.label`
    color: ${theme.colors.grayRGBA};
`;

export const ClientLabel = styled.label`
    color: ${theme.colors.grayRGBA};
`;
const TwoItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export interface AddRoomProps {
    isModalOpen: boolean;
    uiHeader: string;
    closeModal(): void;
    addRoom(value: string, reffererModal?: string): Promise<never[] | undefined>;
    handleSearchClient: SearchSelectProps["onSearch"];
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string        
    ): void;
    addRoomError: string; 
}

interface State {
    roomName: string;
    tabIndex: number;
    error: string;
}

export class RoomModal extends PureComponent<AddRoomProps, State>{

    public constructor(props: AddRoomProps) {
        super(props);
        this.state={
            roomName: "",
            tabIndex: 0,
            error: "",
        }
    }

    @Bind()
    private onInputChange(e: any){
        this.setState({ roomName: e.target.value })
    }
  
    private submitForm = async (e: FormEvent<HTMLFormElement>) => {
        const { addRoom, closeModal } = this.props;
        const { roomName } = this.state;
        await addRoom(roomName, "addRoomModal");
        if(this.props.addRoomError) return; 
        closeModal();
    };

    componentWillReceiveProps(nextProps: Readonly<AddRoomProps>, nextContext: any): void {
        if(nextProps.addRoomError !== this.props.addRoomError){
            this.setState({ error: nextProps.addRoomError });
        }
    }

    private handleInvalidElem = (e: HTMLElement): void => {
        if (e.dataset.index && +e.dataset.index !== this.state.tabIndex) {
            this.setState({ tabIndex: +e.dataset.index }, () => {
                e.scrollIntoView({ behavior: "smooth", block: "center" });
            });
        } else {
            e.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    render(): JSX.Element {
        return(
            <ModalContainer
                uiActive={this.props.isModalOpen}
                uiOnRequestClose={() => {
                    this.setState({error: ""}); 
                }}
                uiDefaultSizes={false}
            >
                <ModalForm
                    id="roomForm"
                    uiOnSubmit={this.submitForm}
                    uiPadding={false}
                    uiReportValidityMode="firstInvalid"
                    uiOnInvalidElem={this.handleInvalidElem}
                >
                    <ModalHeader>
                        <div>
                            <span className="modal-title">
                               {this.props.uiHeader}
                            </span>
                            </div>
                    </ModalHeader>

                    <ModalContentRoom>

                        <TwoItemsWrapper>
                            <SearchSelectWrapper>
                                <ClientLabel>Client Name: {this.props.draperyOrder.clientName}</ClientLabel>
                            </SearchSelectWrapper>
                                <RoomLabel>Room Name</RoomLabel>
                                <RoomFormInput
                                    id="roomName"
                                    name="roomName"
                                    className="label"
                                    onChange={this.onInputChange}
                                    type="text"
                                    uiReportOnBlur={false}
                                    list="autocompleteOff"
                                    required={true}
                                />
                                 {this.state.error && (
                                        <div
                                            className="border bg-red-200 border-black/25 mt-4 p-2"
                                            style={{ backgroundColor: "rgb(254 202 202)" }}
                                            id="errorMessage"
                                        >
                                            {this.state.error}
                                        </div>
                                    )}
                        </TwoItemsWrapper>
                    </ModalContentRoom>
                    <ModalActions>
                        <CancelButton
                            type="button"
                            uiColor="secondary"
                            onClick={this.props.closeModal}
                        >
                            Cancel
                        </CancelButton>

                        <SaveButton
                            type="submit"
                            form="roomForm"
                            uiColor="primary"
                        >
                            Save
                        </SaveButton>
                    </ModalActions>
                </ModalForm>
            </ModalContainer>
        )

    }
}

function mapStateToProps(
    state: RootState,
    ownProps: AddRoomProps
): AddRoomProps {
    return {
        ...ownProps,
    };
}

export default connect(mapStateToProps)(RoomModal);