import ClientHoverBox, { getParentClients } from "../clients/client_hover_box";
import React, { FC, memo } from "react";

import { AvatarImage } from "./avatar";

import { ClientModule } from "../../redux";
import MoreDetailsButton from "./buttons/more-details-button";
import styled from "../../theme";
import EditButton from "../UI/buttons/edit-button";

const Heading = styled.div`
    color: #4a4a4a;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    height: 100%;

    & .actions__container {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }
`;

const DetailsContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    font-size: 14px;
    padding: 5px 0 5px 0;
    margin-bottom: 5px;
    // border-bottom: 11px solid #cbe3f0;
    background-color: #fff;
    z-index: 99;
`;

const EditClientButton = styled(EditButton)`
    font-size: 15px;
    margin: 0;
`;

const DetailsBox = styled.div`
    width: 100%;
    justify-content: space-between;
`;

const Avatar = styled(AvatarImage)`
    margin-right: 20px;
`;

const NameSeperator = styled.span`
    font-size: 24px;
    color: #9e999f;
    padding: 0 1px;
`;

const Name = styled.a`
    color: #51a6c5;
    font-size: 20px;

    &:hover {
        color: #41a1dc;
    }
`;

const VerticalCenterContainer = styled.div`
    display: flex;
    align-items: center;
`;

export interface BasicDetailInfo {
    id: number;
    displayName: string;
    avatarId?: string;
    firstName?: string;
    lastName?: string;
    parent?: ClientModule.BasicClientInfo;
}

export interface DetailsProps {
    info: BasicDetailInfo;
    detailsDisplayed: boolean;
    onToggleDetails(): void;
    onGoBack(): void;
    onAncestorClick?(id: number): void;
    onClick?(id: number): void;
}

const BasicDetail: FC<DetailsProps> = memo((props) => (
    <DetailsContainer>
        <Avatar
            avatarId={props.info.avatarId}
            firstName={props.info.firstName}
            lastName={props.info.lastName}
            width={69}
            height={69}
            fontSize={40}
        />

        <DetailsBox>
            <Heading>
                <VerticalCenterContainer>
                    {props.info.parent &&
                        getParentClients(props.info.parent).map(
                            (ancestorClient) => (
                                <>
                                    <ClientHoverBox
                                        key={ancestorClient.id}
                                        client={ancestorClient}
                                        onClick={props.onAncestorClick}
                                    />
                                    <NameSeperator>|</NameSeperator>
                                </>
                            )
                        )}
                    <Name>{`${props.info.displayName}`}</Name>
                </VerticalCenterContainer>

                <div className="actions__container">
                    <MoreDetailsButton
                        isDetailsDisplayed={props.detailsDisplayed}
                        onToggleClientDetails={props.onToggleDetails}
                    />
                    <EditClientButton
                        onClick={() =>
                            props.onClick && props.onClick(props.info.id)
                        }
                        title="Edit"
                        className="edit-button"
                    />
                </div>
            </Heading>
        </DetailsBox>
    </DetailsContainer>
));

export default BasicDetail;
