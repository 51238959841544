import { Api } from "@quipa/api";
import { flagRoot } from "../../utils";

export type DraperyOrderAllInfo = Api.DraperyOrderAllInfo;
export type DraperyOrder = Api.DraperyOrder;
export type DraperyOrderItem = Api.DraperyOrderItem;
export type DraperyOrderDesign = Api.DraperyOrderDesign;
export type DraperyOrderDesignEmbellishment =
    Api.DraperyOrderDesignEmbellishment;
export type DraperyOrderDesignEmbellishmentEdgeJson =
    Api.DraperyOrderDesignEmbellishmentEdgeJson;
export type Shape = Api.DraperyOrderDesignDrawaingShape;
export type Point = Api.DraperyOrderDesignDrwaingPoint;
export type DraperyOrderDesignLining = Api.DraperyOrderDesignLining;
export type DraperyOrderFabric = Api.DraperyOrderFabric;
export type DraperyOrderFabricJson = Api.DraperyOrderFabricJson;
export type DraperyOrderSummary = Api.DraperyOrderSummary;
export type DraperyOrderSummaryComponent = Api.DraperyOrderSummaryComponent;
export type DraperyOrderDesignPanelJson = Api.DraperyOrderDesignPanelJson;

export interface State {
    id: number;
    window?: string;
    related?: string;
    quantity?: number | undefined;
    hasRelatedWorkOrders: boolean;
    isReturnLeftoverMaterials: boolean;
}

export function defaults(): State {
    const st: State = {
        id: 0,
        window: "",
        hasRelatedWorkOrders: false,
        isReturnLeftoverMaterials: false,
        related: "",
    };
    return flagRoot(st);
}
