import React, { FC } from "react";
import styled, { css, theme } from "../../../theme";

import AddButtonE from "../buttons/add-button";
import { SearchSelectBasicProps } from "./search_select";

export interface SearchSelectResultInfo {
    id: number;
    itemId?: number | undefined;
    displayName: string;
    parentName?: string;
    categorie?: string;
    parentCategoryId?: string;
    tags?: string;
    columnType?: string;
    depth?: number;
}

export interface SearchSelectResultsProps extends SearchSelectBasicProps {
    /**
     * Results
     */
    className?: string;
    results: SearchSelectResultInfo[];
    addButtonText?: string;
    searchValue: string;
    tags?: string;
    /**
     * Result click callback
     * @param id
     * @param name
     */
    onResultClick(id: number, name: string,itemId:number): void;
    /**
     * Close callback
     */
    onClose(): void;
    openModalClick?(): void;
    resultsDisplayed: boolean;
    highlightedSection?: string | null;
    name?: string;
}

interface ItemProps extends SearchSelectBasicProps {
    id: number;
    itemId?: number;
    name: string;
    parentName?: string;
    categorie?: string;
    parentCategoryId?: string;
    tags?: string;
    className?: string;
    search: string;
    columnType?: string;
    results?: any;
    index?: number;
    onMouseDown(id: number, name: string, itemId?:number): void;
    depth?: number;
}

const ItemBasicValue = styled.div<SearchSelectBasicProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ItemName = styled(ItemBasicValue)`
    margin-right: 10px;
`;

const ItemParentName = styled(ItemBasicValue)`
    font-style: italic;
    padding-right: 5px;
    ${(props) =>
        props.columnType === "shop" &&
        css`
            &:first-child {
                padding-left: 5px !important;
            }
        `}

    ${(props) =>
        props.searchType === "header" &&
        css`
            color: #65666b;
        `}
    ${(props) =>
        props.searchType === "modal" &&
        css`
            font-style: normal !important;
            padding-right: 0;
        `}
`;

const ItemContainer = styled.div<SearchSelectBasicProps>`
    padding: 8px 8px 8px 13px;
    color: #353435;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        background-color: #f8f5f8;
    }

    ${(props) =>
        props.searchType === "header" &&
        css`
            color: #353435;
        `}

    ${(props) =>
        props.searchType === "modal" &&
        css`
            color: #353435;
        `}
        ${(props) =>
        props.searchPage === "myshop" &&
        css`
            justify-content: initial;
        `}
        ${(props) =>
        props.columnType === "shop" &&
        css`
            justify-content: initial;
            padding: 5px 8px 5px 16px;
        `}
`;

const NoResultsMessage = styled.div`
    padding: 8px 8px 8px 13px;
    color: ${theme.colors.grayRGBA};
`;

const Container = styled.div<SearchSelectBasicProps>`
    position: absolute;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ededed;
    z-index: 100;
    width: 100%;
    ${(props) =>
        props.searchType === "header" &&
        css`
            border-top-color: transparent;
            padding: 1px 1px;
        `}
    ${(props) =>
        props.searchPage === "myshop" &&
        css`
            position: initial;
            border: none;
            border-radius: 0px 0px 17px 17px;
            background: #fff;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        `}
        ${(props) =>
        props.searchPage === "myshop" &&
        props.resultsDisplayed &&
        css`
            margin-bottom: 20px;
        `}
`;

const ScrollableContainer = styled.div<SearchSelectBasicProps>`
    max-height: 158px;
    overflow: auto;
    ${(props) =>
        props.searchType === "header" &&
        css`
            max-height: 300px;
        `}

    ${(props) =>
        props.searchType === "modal" &&
        css`
            max-height: 158px;
        `}

    ${(props) =>
        props.searchType === "calculator" &&
        css`
            max-height: 250px;
        `}
`;

const AddButton = styled(AddButtonE)`
    width: 100%;
    font-size: 15px;
`;

const Separator = styled.span`
    margin: 0 3px;
`;
const FormattedSearchResult = (props: any) => {
    const { str, search } = props;

    const lowStr = str.toLowerCase();
    const lowFind = search.toLowerCase();

    const idx = lowStr.indexOf(lowFind);

    if (idx === -1) {
        return str;
    }
    if (str.length - idx - 1 === search.length - 1) {
        return (
            <span>
                {str.substring(0, idx)}
                <b>{str.substring(idx)}</b>
            </span>
        );
    } else {
        return (
            <span>
                {str.substring(0, idx)}
                <b>{str.substring(idx, idx + search.length)}</b>
                {str.substring(idx + search.length)}
            </span>
        );
    }
};

const Item: FC<ItemProps> = (props) => {
    return (
        <ItemContainer
            searchType={props.searchType}
            searchPage={props.searchPage}
            columnType={props.columnType}
            onMouseDown={() => props.onMouseDown(props.id, props.name,props.itemId)}
        >
            {!props.parentName && props.columnType !== "shop" && (
                <ItemName>
                    {props.search ? (
                        <FormattedSearchResult
                            search={props.search.trim()}
                            str={props.name}
                            depth={props.depth}
                        />
                    ) : (
                        <div
                            style={{
                                marginLeft: `${props.depth || 0}rem`,
                                fontStyle:
                                    props.depth && props.depth > 0
                                        ? "italic"
                                        : "normal",
                            }}
                        >
                            {props.name}
                        </div>
                    )}
                </ItemName>
            )}{" "}
            {props.parentName && props.searchPage !== "myshop" && (
                <>
                    <ItemName>
                        {props.search ? (
                            <FormattedSearchResult
                                search={props.search.trim()}
                                str={props.name}
                            />
                        ) : (
                            props.name
                        )}
                    </ItemName>
                    {props.parentName && (
                        <ItemParentName searchType={props.searchType}>
                            {`Sub of `}
                            {props.parentName}
                        </ItemParentName>
                    )}
                </>
            )}
            {props.columnType !== "shop" && props.columnType !== undefined && (
                <ItemParentName searchType={props.searchType}>
                    {props.columnType}
                </ItemParentName>
            )}
            {props.parentName && props.searchPage === "myshop" && (
                <>
                    <ItemParentName searchType={props.searchType}>
                        {props.parentName}
                    </ItemParentName>
                    <Separator> | </Separator>
                    <ItemName>
                        {props.search ? (
                            <FormattedSearchResult
                                search={props.search.trim()}
                                str={props.name}
                            />
                        ) : (
                            props.name
                        )}
                    </ItemName>
                </>
            )}{" "}
            {props.categorie && props.columnType === "shop" && (
                <>
                    <Separator></Separator>
                    <ItemName>
                        {props.search ? (
                            <FormattedSearchResult
                                search={props.search.trim()}
                                str={props.name}
                            />
                        ) : (
                            <div className="ml-1">{props.name}</div>
                        )}
                    </ItemName>
                </>
            )}{" "}
            {/*props.tags && (
            <ItemParentName
                searchType={props.searchType}
            >
                Tags: {props.tags}
            </ItemParentName>
        )*/}
        </ItemContainer>
    );
};

const SearchSelectResults: FC<SearchSelectResultsProps> = (props) => {
    const { addButtonText } = props;
    const addButtonTxt =
        addButtonText ||
        (props.searchItem === "newItem" 
        ? "" 
        : props.searchItem === "client"
        ? "Add New Client"
        : props.searchItem === "supplier"
        ? "Add New Supplier"
        : props.searchItem === "category"
        ? "Add New Category"
        : props.searchItem === "room"
        ? "Add New Room"
        : "");
    let idx1 = 0;
    let idx2 = 0;
    let idx3 = 0;
    let idx4 = 0;

    const filterNames =
        props.columnType === "shop"
            ? props.results &&
              props.results.length > 0 &&
              props.results.sort((a: any, b: any) => {
                  return a.categorie
                      .toLowerCase()
                      .localeCompare(b.categorie.toLowerCase());
              })
            : props.results &&
              props.results.length > 0 &&
              props.results[0].tags === "filter-category"
            ? props.results &&
              props.results.sort((a: any, b: any) => {
                  if (a.displayName && b.parentName !== null) {
                      return a.displayName
                          .toLowerCase()
                          .localeCompare(b.parentName.toLowerCase());
                  } else if (a.parentName !== null && b.displayName) {
                      return a.parentName
                          .toLowerCase()
                          .localeCompare(b.displayName.toLowerCase());
                  } else {
                      return a.displayName
                          .toLowerCase()
                          .localeCompare(b.displayName.toLowerCase());
                  }
              })
            : props.results &&
              props.searchItem === "category" || props.searchItem === "newItem" &&
              !props.searchValue
            ? props.results
            : props.results &&
              props.results.sort((a: any, b: any) => {
                  return a.displayName
                      .toLowerCase()
                      .localeCompare(b.displayName.toLowerCase());
              });

    const uniqueCategories = new Set<string>();

    return (
        <Container
            searchType={props.searchType}
            searchPage={props.searchPage}
            resultsDisplayed={props.resultsDisplayed}
        >
            {filterNames && filterNames.length > 0 && (
                <ScrollableContainer searchType={props.searchType}>
                    {(props.searchPage === "calculator" ||
                        (props.searchItem === "supplier" &&
                            props.searchPage === "myshop") ||
                        (props.searchItem === "category" &&
                            props.searchPage === "myshop")) && (
                        <AddButton
                            onMouseDown={props.openModalClick}
                            title={addButtonTxt}
                            highlightedSection={props.highlightedSection}
                            name={props.name}
                        />
                    )}
                    {filterNames.map((r, index) => {
                        if (r.columnType === "Item Name") idx1++;
                        if (r.columnType === "Item #") idx2++;
                        if (r.columnType === "Supplier") idx3++;
                        if (r.columnType === "Purchase Description") idx4++;
                        switch (r.columnType) {
                            case "Item Name":
                                return idx1 === 1 ? (
                                    <div>
                                        <Item
                                            searchType={props.searchType}
                                            searchPage={props.searchPage}
                                            key={index}
                                            id={r.id}
                                            name={r.displayName}
                                            onMouseDown={props.onResultClick}
                                            parentName={r.parentName}
                                            tags={r.tags}
                                            search={props.searchValue}
                                            columnType={r.columnType}
                                            itemId={r.itemId}
                                        />
                                    </div>
                                ) : (
                                    <Item
                                        searchType={props.searchType}
                                        searchPage={props.searchPage}
                                        key={index}
                                        id={r.id}
                                        name={r.displayName}
                                        onMouseDown={props.onResultClick}
                                        parentName={r.parentName}
                                        tags={r.tags}
                                        search={props.searchValue}
                                        itemId={r.itemId}
                                    />
                                );
                            case "Item #":
                                return idx2 === 1 ? (
                                    <div>
                                        <Item
                                            searchType={props.searchType}
                                            searchPage={props.searchPage}
                                            key={index}
                                            id={r.id}
                                            name={r.displayName}
                                            onMouseDown={props.onResultClick}
                                            parentName={r.parentName}
                                            tags={r.tags}
                                            search={props.searchValue}
                                            columnType={r.columnType}
                                            itemId={r.itemId}
                                        />
                                    </div>
                                ) : (
                                    <Item
                                        searchType={props.searchType}
                                        searchPage={props.searchPage}
                                        key={index}
                                        id={r.id}
                                        name={r.displayName}
                                        onMouseDown={props.onResultClick}
                                        parentName={r.parentName}
                                        tags={r.tags}
                                        search={props.searchValue}
                                        itemId={r.itemId}
                                    />
                                );
                            case "Supplier":
                                return idx3 === 1 ? (
                                    <div>
                                        <Item
                                            searchType={props.searchType}
                                            searchPage={props.searchPage}
                                            key={index}
                                            id={r.id}
                                            name={r.displayName}
                                            onMouseDown={props.onResultClick}
                                            parentName={r.parentName}
                                            tags={r.tags}
                                            search={props.searchValue}
                                            columnType={r.columnType}
                                            itemId={r.itemId}
                                        />
                                    </div>
                                ) : (
                                    <Item
                                        searchType={props.searchType}
                                        searchPage={props.searchPage}
                                        key={index}
                                        id={r.id}
                                        name={r.displayName}
                                        onMouseDown={props.onResultClick}
                                        parentName={r.parentName}
                                        tags={r.tags}
                                        search={props.searchValue}
                                        itemId={r.itemId}
                                    />
                                );
                            case "Purchase Description":
                                return idx4 === 1 ? (
                                    <div>
                                        <Item
                                            searchType={props.searchType}
                                            searchPage={props.searchPage}
                                            key={index}
                                            id={r.id}
                                            name={r.displayName}
                                            onMouseDown={props.onResultClick}
                                            parentName={r.parentName}
                                            tags={r.tags}
                                            search={props.searchValue}
                                            columnType={r.columnType}
                                            itemId={r.itemId}
                                        />
                                    </div>
                                ) : (
                                    <Item
                                        searchType={props.searchType}
                                        searchPage={props.searchPage}
                                        key={index}
                                        id={r.id}
                                        name={r.displayName}
                                        onMouseDown={props.onResultClick}
                                        parentName={r.parentName}
                                        tags={r.tags}
                                        search={props.searchValue}
                                        itemId={r.itemId}
                                    />
                                );
                            case "shop":
                                if (
                                    r.categorie &&
                                    !uniqueCategories.has(r.categorie)
                                ) {
                                    uniqueCategories.add(r.categorie);

                                    const itemsForCategory = filterNames.filter(
                                        (item) => item.categorie === r.categorie
                                    );

                                    return (
                                        <div key={`category-${r.categorie}`}>
                                            {r.parentCategoryId && (
                                                <>
                                                    <div className="flex items-center pt-1 pb-2">
                                                        <ItemParentName
                                                            searchType={
                                                                props.searchType
                                                            }
                                                            columnType={
                                                                r.columnType
                                                            }
                                                        >
                                                            {r.parentCategoryId}
                                                        </ItemParentName>
                                                        <Separator>/</Separator>
                                                        <ItemParentName
                                                            searchType={
                                                                props.searchType
                                                            }
                                                        >
                                                            {r.categorie}
                                                        </ItemParentName>
                                                    </div>
                                                </>
                                            )}
                                            {!r.parentCategoryId && (
                                                <div className="pt-1 pb-2">
                                                    <ItemParentName
                                                        searchType={
                                                            props.searchType
                                                        }
                                                        columnType={
                                                            r.columnType
                                                        }
                                                    >
                                                        {r.categorie}
                                                    </ItemParentName>
                                                </div>
                                            )}

                                            {itemsForCategory.map(
                                                (item, itemIndex) => (
                                                    <Item
                                                        searchType={
                                                            props.searchType
                                                        }
                                                        searchPage={
                                                            props.searchPage
                                                        }
                                                        key={itemIndex}
                                                        id={item.id}
                                                        name={item.displayName}
                                                        onMouseDown={
                                                            props.onResultClick
                                                        }
                                                        parentName={
                                                            item.parentName
                                                        }
                                                        tags={item.tags}
                                                        search={
                                                            props.searchValue
                                                        }
                                                        parentCategoryId={
                                                            item.parentCategoryId
                                                        }
                                                        categorie={
                                                            item.categorie
                                                        }
                                                        columnType={
                                                            item.columnType
                                                        }
                                                        results={props.results}
                                                        index={index}
                                                        itemId={r.itemId}
                                                    />
                                                )
                                            )}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            default:
                                return (
                                    <Item
                                        searchType={props.searchType}
                                        searchPage={props.searchPage}
                                        key={index}
                                        id={r.id}
                                        name={r.displayName}
                                        onMouseDown={props.onResultClick}
                                        parentName={r.parentName}
                                        tags={r.tags}
                                        search={props.searchValue}
                                        parentCategoryId={r.parentCategoryId}
                                        categorie={r.categorie}
                                        columnType={r.columnType}
                                        results={props.results}
                                        index={index}
                                        depth={r.depth}
                                        itemId={r.itemId}
                                    />
                                );
                        }
                    })}
                </ScrollableContainer>
            )}

            {(!filterNames || !filterNames.length) && (
                <>
                    {(props.searchPage === "calculator" ||
                        (props.searchItem === "supplier" &&
                            props.searchPage === "myshop") ||
                        (props.searchItem === "category" &&
                            props.searchPage === "myshop")) && (
                        <AddButton
                            onMouseDown={props.openModalClick}
                            title={addButtonTxt}
                            highlightedSection={props.highlightedSection}
                            name={props.name}
                        />
                    )}
                    <NoResultsMessage>No Results found</NoResultsMessage>
                </>
            )}
        </Container>
    );
};

export default SearchSelectResults;
