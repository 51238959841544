import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import {
    FormFields,
    FormFieldsAction,
    FormFieldsPartial,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    TextAreaContainer,
} from "../styles";
import React, {
    ChangeEvent,
    Dispatch,
    FC,
    useEffect,
    useState,
    useCallback,
    useRef
} from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../UI/search_select/search_select";

import { Category } from "../../../interfaces/category";
import { FabricsOrLiningsItemDetailsFields } from "../../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import ItemAvatar from "../item-avatar";
import LockedInput from "../locked-input";
import LockedLabel from "../locked-label";
import { Supplier } from "../../../interfaces/supplier";
import { TextArea } from "@ramble/ramble-ui";
import CategoryModal from "../category/category-modal";
import { getAll } from "../../../api/category";
import { getAll as getAllItems } from "../../../api/item";

interface StandardItemModalDetailsProps {
    categories: Category[];
    suppliers: Supplier[];
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    formFields: FormFields;
    fabricsOrLiningsItemDetailsFields: FabricsOrLiningsItemDetailsFields;
    dispatch: Dispatch<FormFieldsAction>;
    openModalClick?(): void;
    pathIdForModal?: string | null;
    pathNameForModal?: string;
    fabricPatternModalCategoryId?: number;
    supplierName?: string;
    supplierId?: number;
    categoryName?: string;
    isCategory?: boolean;
    isCategoryErrorMessage?: boolean;
    setIsCategory?: (value: boolean) => void;
    setIsCategoryErrorMessage?: (value: boolean) => void;
    isName?: boolean;
    setIsName?: (value: boolean) => void;
    validationError?: boolean;
    isNameMessageError?: boolean;
    setIsNameMessageError?: (value: boolean) => void
}

const StandardItemModalDetails: FC<StandardItemModalDetailsProps> = ({
    suppliers,
    browser,
    formFields,
    fabricsOrLiningsItemDetailsFields,
    dispatch,
    openModalClick,
    fabricPatternModalCategoryId,
    supplierName,
    supplierId,
    categoryName,
    isCategory,
    isCategoryErrorMessage,
    setIsCategory,
    setIsCategoryErrorMessage,
    isName,
    setIsName,
    validationError,
    setIsNameMessageError,
    isNameMessageError,
    }) => {
    const [isSalesEdited, setIsSalesEdited] = useState<boolean>(false);
    const [isOrderingEdited, setIsOrderingEdited] = useState<boolean>(false);
    const { name, isDefault } = formFields;
    const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const getDimensions = (
        key?: string,
        value?: string,
        categoryName?: string
    ) => {        
        const {
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
        } = fabricsOrLiningsItemDetailsFields;
        const obj: Record<string, string | number> = {
            fabricWidthInches,
            verticalRepeatInches,
            horizontalRepeatInches,
        };
        const newObj = { ...obj, [key as string]: value };

        const formatValue = (label: string, val: string) =>
            val ? `${label}: ${val}"` : "";

            if (categoryName === "Fabrics & Trims" || categoryName === "Linings") {
                return [
                    formatValue("W", newObj.fabricWidthInches as string),
                    formatValue("VR", newObj.verticalRepeatInches as string),
                    formatValue("HR", newObj.horizontalRepeatInches as string),
                ]
                    .filter(Boolean)
                    .join(" / ");
            } else {
                return "";
            }
    };

    const fetchList = useCallback(async () => {
        try {
            const list = await getAll();
            setCategories(list);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }, []);

    const fetchItems = useCallback(async () => {
        try {
            const listItems = await getAllItems();
            setItems(listItems);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }, []);

    const fetchListWrapper = useCallback(async () => {
        try {
            await Promise.all([fetchList(), fetchItems()]);
        } catch (error) {
            console.error("Error in fetchListWrapper:", error);
        }
    }, [fetchList, fetchItems]);

    useEffect(() => {
        fetchListWrapper();
    }, [fetchListWrapper]);

    const setOrderingDescription = () => {
        const textArea = document.getElementById(
            "orderingDescription"
        ) as HTMLInputElement;

        if (!textArea) {
            return;
        }

        let value = "";
        const { name, itemNumber, orderingDescription } = formFields;

        if (orderingDescription) {
            value = orderingDescription;
        } else {
            const dimensions = getDimensions();
            const data = [name, itemNumber, dimensions];
            let dataValue = data.filter((str) => str).join("\n");

            value = dataValue && dataValue.length ? `${dataValue}\n` : ""; 
        }
        textArea.value = value;
    };

    const setSalesDescription = () => {
        const textArea = document.getElementById(
            "salesDescription"
        ) as HTMLInputElement;

        if (!textArea) {
            return;
        }

        let value = "";
        const { supplierName, name, itemNumber, salesDescription } = formFields;
        if (salesDescription) {
            value = salesDescription;
        } else {
            const dimensions = getDimensions();
            const data = [supplierName, name, itemNumber, dimensions];
            let dataValue = data.filter((str) => str).join("\n");
            value = dataValue && dataValue.length ? `${dataValue}\n` : ""; //NOSONAR
        }

        textArea.value = value;
    };

    const [isNameUnique, setIsNameUnique] = useState(false);
    const [isNameUniqueError, setIsNameUniqueError] = useState(false)

    const isNameInUse = (name: string, items: { name: string }[]): boolean => {
        const lowerCaseName = name.toLowerCase();   
        return items.some(item => item.name.toLowerCase() === lowerCaseName);
    };

    const handleItemNameNumberChange = (
        e: ChangeEvent<HTMLInputElement>
    ): void => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;

        let updatedForm: FormFieldsPartial = {};
        let salesDescription: string = "";
        let orderingDescription: string = "";

        // if (setIsCategory) {
        //     setIsCategory(false);
        // }
        // if (setIsName) {
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
        // if (key === "itemNumber"){
        //     if (setIsCategory) {
        //         setIsCategory(false);
        //     }
        //     if(setIsName){
        //         setIsName(false);
        //     }
        //     setIsNameUnique(false);
        // }
        if (key === "name") {
            updatedForm.name = value;
        
            const isTaken = isNameInUse(value.trim(), items);
            if (nameInputRef.current) {
                if (value.trim() === "") {
                    nameInputRef.current.setCustomValidity("");
                   if(setIsName){
                    setIsName(true);
                   }
                   if (setIsNameMessageError) {
                    setIsNameMessageError(true)
                    }
                    setIsNameUnique(false);
                    setIsNameUniqueError(false)
                } else if (isTaken) {
                    nameInputRef.current.setCustomValidity("Item name already in use!");
                    if(setIsName){
                        setIsName(false);
                       }
                    if (setIsNameMessageError) {
                        setIsNameMessageError(false)
                    }
                    setIsNameUnique(true);
                    setIsNameUniqueError(true)
                } else {
                    if(setIsName){
                        setIsName(false);
                       }
                    if (setIsNameMessageError) {
                        setIsNameMessageError(false)
                    }
                    nameInputRef.current.setCustomValidity("");
                    setIsNameUnique(false);
                    setIsNameUniqueError(false)
                }
                nameInputRef.current.reportValidity();
            }
        } else if (key === "itemNumber") {
            updatedForm.itemNumber = value;
        }
        


        const existingDimensions = getDimensions();

        const obj = { ...formFields, [key]: value };
        const mapObjOne = [
            obj.name,
            obj.itemNumber,
            obj.supplierName,
            existingDimensions,
        ];
        const mapObjTwo = [obj.name, obj.itemNumber, existingDimensions];
        mapObjOne.forEach((item, index) => {
            if (item) {
                salesDescription += `${item}\n`;
            }
            return { salesDescription, orderingDescription };
        });
        mapObjTwo.forEach((item, index) => {
            if (item) {
                orderingDescription += `${item}\n`;
            }
            return { salesDescription, orderingDescription };
        });
        updatedForm.salesDescription = salesDescription;
        updatedForm.orderingDescription = orderingDescription;

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const nest = (
        items: Category[],
        id: number | null = null,
        link = "parentCategoryId"
    ): Category[] => {
        return items
            .filter((item) => item[link] === id)
            .map((item) => ({
                ...item,
                children: nest(items, item.id),
            }));
    };

    const handleSearchCategory: SearchSelectProps["onSearch"] = async (
        term: string
    ) => {
        // if(setIsName){
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
        const flatten = (array: any, depth: number) =>
            array.flatMap((item: any) => [
                {
                    name: item.name,
                    children: item.children,
                    depth,
                    id: item.id,
                    parentCategoryId: item.parentCategoryId,
                },
                ...flatten(item.children || [], depth + 1),
            ]);
        try {
            let results: { id: number; displayName: string; depth: number }[] =
                [];

            results = flatten(nest(categories), 0)
                .filter((r: Category) => {
                    if (term && term.length) {
                        return r.name
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    }
                    return r;
                })
                .map((r: any) => ({
                    id: r.id,
                    displayName: r.name,
                    depth: r.depth,
                }));

            return results;
        } catch (error) {
            console.error("Error searching categories:", error);
            return [
                {
                    id: 0,
                    displayName: "",
                },
            ];
        }
    };

    const handleSelectCategory = (id: number, name: string) => {
        const cleanName = name.replace("└─ ", "").trim();
        // if(setIsName){
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
        let categoryName: any[] = [];
        let getSubCategory: any;

        if (name === "") {
            if (setIsCategory) {
                setIsCategory(true);
            }
            if (setIsCategoryErrorMessage) {
                setIsCategoryErrorMessage(true);
            }
        }
        if (id > 0) {
            const getCategory = categories.filter(
                (category) =>
                    category.id === 101 ||
                    category.parentCategoryId === 101 ||
                    category.id === 110 ||
                    category.parentCategoryId === 110
            );
            categoryName = getCategory.filter((item) => {
                return (
                    item.name.toLowerCase().trim() === cleanName.toLowerCase()
                );
            });
            getSubCategory = categories.find((cat) => cat.id === id);
        }
        const idCat =
            (getSubCategory &&
                getSubCategory.parentCategoryId &&
                getSubCategory.parentCategoryId) ||
            0;

        const type =
            name && name.includes(categoryName[0] && categoryName[0].name)
                ? 1
                : undefined;
        const formFieldUpdates: FormFieldsPartial = {
            categoryId: id,
            categoryName: cleanName,
            itemTypeId: type,
            parentCategoryId: idCat,
        };

        if (id === 102) {
            formFieldUpdates.itemTypeId = 2;
        } else if (id !== 108) {
            formFieldUpdates.itemTypeId = 1;
        } else {
            formFieldUpdates.itemTypeId = 2;
        }
        
        if (id === 0 || id !== 101){
            const salesDescription = formFields.salesDescription;
            const orderingDescription = formFields.orderingDescription;
            const pattern = /^\b[A-Z]{1}: [\d\.]+"\s*\/\s*[A-Z]{2}: [\d\.]+"\s*\/\s*[A-Z]{2}: [\d\.]+"\s*$/gm;
            formFieldUpdates.orderingDescription = salesDescription !== "" ? salesDescription.replace(pattern, "") : "";
            formFieldUpdates.salesDescription = orderingDescription !== "" ? orderingDescription.replace(pattern, "") : "";
            
            dispatch({
                type: "updateFormFields",
                formFieldUpdates,
            });
            return;
        }
        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const handleSearchSupplier: SearchSelectProps["onSearch"] = async (
        term: string
    ) => {
        // if (setIsCategory) {
        //     setIsCategory(false);
        // }
        // if(setIsName){
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
        try {
            let results = [...suppliers];
            results = results
                .filter((r) => r.active)
                .filter((r) => {
                    if (term && term.length) {
                        return r.displayName
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    }
                    return r;
                })
                .sort((a, b) => a.displayName.localeCompare(b.displayName));

            return results.map((r) => ({
                id: r.id,
                displayName: r.displayName,
            }));
        } catch (error) {
            console.error("Error searching suppliers:", error);
            return [
                {
                    id: 0,
                    displayName: "",
                },
            ];
        }
    };

    const handleSelectSupplier = (id: number, name: string) => {
        const formFieldUpdates: FormFieldsPartial = {
            supplierId: id,
            supplierName: name,
        };
        // if (formFieldUpdates.supplierId === 0){
        //     if (setIsCategory) {
        //         setIsCategory(false);
        //     }
        //     if(setIsName){
        //         setIsName(false);
        //     }
        //     setIsNameUnique(false);
        // }
        let updatedSalesDescription: string = "";
        const existingDimensions = getDimensions();
        const mapObj = [
            formFields.name,
            formFields.itemNumber,
            name,
            existingDimensions,
        ];
        mapObj.forEach((item, index) => {
            if (item.length > 0) {
                updatedSalesDescription += `${item || ""}\n`;
            }
        });
        formFieldUpdates.salesDescription = updatedSalesDescription;
        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const handlePrefillTextAreaChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;
        const hasValue = value.length;
        if (hasValue) {
            key === "salesDescription"
                ? setIsSalesEdited(true)
                : setIsOrderingEdited(true);
        const formFieldUpdates = formFields;
        formFieldUpdates[key] = value;
        // if (setIsCategory) {
        //     setIsCategory(false);
        // }
        // if(setIsName){
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
        }

    };

    const handleEnterPressed = (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
        const key = e.key;
        if (key === "Enter" || key === "Return") {
            e.preventDefault();

            const textarea = e.currentTarget;
            const value = textarea.value;
            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;

            const part1 = value.slice(0, selectionStart);
            const part2 = value.slice(selectionEnd);

            const newValue = part1 + "\n" + part2;

            textarea.value = newValue;
            textarea.selectionStart = textarea.selectionEnd =
                selectionStart + 1;
        }
        // if (setIsCategory) {
        //     setIsCategory(false);
        // }
        // if(setIsName){
        //     setIsName(false);
        // }
        // setIsNameUnique(false);
    };

    const handleOpenCategoryModalClick = () => {
        setIsAddCategoryModalOpen(true);
    };

    const closeAddCategoryModal = () => {
        setIsAddCategoryModalOpen(false);
    };

    const handleFieldChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let key = e.currentTarget.id;
        let value: string | number = e.currentTarget.value;
        let moreUpdates = {};
        if (["itemTypeProduct", "itemTypeService"].includes(key)) {
            key = "itemTypeId";
            value = +value;
            moreUpdates = {
                retailPricingMethod: "",
                retailPricingValue: "",
                tradePricingMethod: "",
                tradePricingValue: "",
            };
        }
        // handleInputFocus();

        const updatedForm = {
            [key]: value,
            ...moreUpdates,
        };

        if (setIsCategoryErrorMessage) {
            setIsCategoryErrorMessage(false)
        }
        if (setIsNameMessageError) {
            setIsNameMessageError(false)
        }
        if (setIsNameUnique) {
            setIsNameUnique(false)
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    useEffect(() => {
        if (fabricPatternModalCategoryId === 101) {
            handleSelectCategory(
                fabricPatternModalCategoryId,
                "Fabrics & Trims"
            );
        } else if (fabricPatternModalCategoryId === 110) {
            handleSelectCategory(fabricPatternModalCategoryId, "Linings");
        }
        if (
            fabricPatternModalCategoryId === 101 ||
            fabricPatternModalCategoryId === 110
        ) {
            const updatedForm = {
                itemTypeId: 1,
            };
            dispatch({
                type: "updateFormFields",
                formFieldUpdates: updatedForm,
            });
        }
    }, []);

    useEffect(() => {
        setSalesDescription();
    }, [formFields.supplierName, formFields.name, formFields.itemNumber]);

    //whenever there is an update to the these 3, supplierName, item name and itemNumber
    //salesDescription text area is to be prefilled.
    //but after salesDescription is edited, a change to the 3, should not prefill anymore
    useEffect(() => {
        if (!isSalesEdited) {
            setSalesDescription();
        }
    }, [
        formFields.supplierName,
        formFields.name,
        formFields.itemNumber,
        formFields.categoryId,
        fabricsOrLiningsItemDetailsFields.fabricWidthInches,
        fabricsOrLiningsItemDetailsFields.verticalRepeatInches,
        fabricsOrLiningsItemDetailsFields.horizontalRepeatInches,
        isSalesEdited,
    ]);

    //whenever there is an update to the these 2, item name and itemNumber
    //orderingDescription text area is to be prefilled.
    //but after orderingDescription is edited, a change to the 2, should not prefill anymore
    useEffect(() => {
        if (!isOrderingEdited) {
            setOrderingDescription();
        }
    }, [
        formFields.name,
        formFields.itemNumber,
        formFields.categoryId,
        fabricsOrLiningsItemDetailsFields.fabricWidthInches,
        fabricsOrLiningsItemDetailsFields.verticalRepeatInches,
        fabricsOrLiningsItemDetailsFields.horizontalRepeatInches,
        isOrderingEdited,
    ]);
    useEffect(() => {
        let updatedForm: FormFieldsPartial = {};
        updatedForm.supplierName = supplierName;
        updatedForm.supplierId = supplierId;
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    }, [supplierName, supplierId]);

    // useEffect(() => {
    //     if (!isCategory) {
    //         if(setIsName){
    //             setIsName(false);
    //         }
    //         setIsNameUnique(false);
    //     }
    // }, [isCategory, setIsName, setIsNameUnique]);
    
    // const handleInputFocus = () => {
    //     if (setIsCategory) {
    //         setIsCategory(false);
    //     }
    //     if (setIsName) {
    //         setIsName(false);
    //     }
    //     setIsNameUnique(false);
    //     };
    //     useEffect(() => {
    //         if (items && items.length > 0) {
    //             const isExisting = items.some(i => i.name === name.trim())
    //             if (!isExisting) return
    //             if (nameInputRef.current) {
    //                 nameInputRef.current.setCustomValidity("Item name already in use!");
    //                 if (setIsName) {
    //                     setIsName(false);
    //                 }
    //                 setIsNameUnique(true);
    //             }
    //         }
    //     }, [validationError])
    useEffect(() => {
        if (!name && isNameMessageError) {
            if (setIsName) {
                setIsName(true)
            }
            if (setIsNameMessageError) {
                setIsNameMessageError(true)
            }
        } else if (items && items.length > 0) {
            const isExisting = items.some(i => i.name === name.trim())
            if (!isExisting) return
            if (nameInputRef.current) {
                nameInputRef.current.setCustomValidity("Item name already in use!");
                if (setIsName) {
                    setIsName(false);
                }
                setIsNameUnique(true);
                setIsNameUniqueError(true)
            }
        }
    }, [validationError])

    const handleRemoveErrorMessage = (field: string): void => {
        if (!field) return

        switch (field) {
            case 'name':
                setIsNameUnique(false)
                if (setIsNameMessageError) {
                    setIsNameMessageError(false)
                }
                break;
            case 'item':
                case 'textarea':
                    if (setIsCategoryErrorMessage) {
                        setIsCategoryErrorMessage(false)
                    }
                    if (setIsNameMessageError) {
                        setIsNameMessageError(false)
                    }
                    if (setIsNameUnique) {
                        setIsNameUnique(false)
                    }
                    break;   
            default:
                return
        }
    }

    const handleCheckError = () => {
        if (!name && isName) {
            if (setIsNameMessageError) {
                setIsNameMessageError(true)
            }
            if (setIsCategoryErrorMessage) {
                setIsCategoryErrorMessage(false)
            }
        }

        if (name && isNameUniqueError) {
            if (items && items.length > 0) {
                const isExisting = items.some(i => i.name === name.trim())
                if (!isExisting) return
                if (nameInputRef.current) {
                    nameInputRef.current.setCustomValidity("Item name already in use!");
                    if (setIsName) {
                        setIsName(false);
                    }
                    if (setIsCategoryErrorMessage) {
                        setIsCategoryErrorMessage(false)
                    }
                    setIsNameUnique(true);
                    setIsNameUniqueError(true)
                }
            }
        }
    }

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="w-2/4 mb-6 pr-6">
                <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                    ITEM INFORMATION
                </div>
                <div className="mb-2">
                    <FormLabel htmlFor="Itemcategory">
                    {isCategoryErrorMessage && (
                        <span className="category-required">Category is required!</span>
                    )}
                    <span className="category-text">
                        Category<span className="required-asterisk">*</span>
                    </span>
                    </FormLabel>
                    {isDefault ? (
                        <LockedInput
                            id="itemCategoryLocked"
                            text={formFields.categoryName}
                            toolTipText="This cannot be edited"
                        />
                    ) : (
                        <SearchSelect
                            id="itemCategory"
                            searchType="modal"
                            searchItem="category"
                            searchPage="myshop"
                            onItemClick={(id, name) =>
                                {  
                                    if (setIsCategory) {
                                        setIsCategory(false);
                                    }
                                    if (setIsCategoryErrorMessage) {
                                        setIsCategoryErrorMessage(false);
                                    }
                                    handleSelectCategory(id, name)}
                            }
                            onFocusMessage={() => {
                                if (setIsNameMessageError) {
                                    setIsNameMessageError(false)
                                }
                                setIsNameUnique(false)
                                if (setIsNameMessageError) {
                                    setIsNameMessageError(false)
                                }
                                if (isCategory) {
                                    if (setIsCategoryErrorMessage) {
                                        setIsCategoryErrorMessage(true)
                                    }
                                }
                            }}
                            onBlurMessage={() => {
                                if (setIsCategoryErrorMessage) {
                                    setIsCategoryErrorMessage(false)
                                }
                            }}
                            onSearch={handleSearchCategory}
                            initialValue={formFields.categoryName}
                            placeholder="Please select"
                            keepKeyword
                            onKeywordCleared={() => handleSelectCategory(0, "")}
                            openModalClick={handleOpenCategoryModalClick}
                            className={isCategory ? 'required-field' : ''}
                        />
                    )}
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemName">
                        {isNameMessageError && (
                            <span className="name-required">Name is required!</span>
                        )}
                        {isNameUnique && !isName && (
                            <span className="name-required">Item name is already in use!</span>
                        )}
                        <span className="name-text">
                            Name<span className="required-asterisk">*</span>
                        </span>
                    </FormLabel>
                    {isDefault ? (
                        <LockedInput
                            id="nameLocked"
                            text={name}
                            toolTipText="This cannot be edited"
                        />
                    ) : (
                        <FormInput
                            id="name"
                            name="Item Name"
                            className={`px-1.5 py-2 ${isName || isNameUniqueError ? 'required' : 'notRequired'}`}
                            type="text"
                            value={formFields.name}
                            onChange={
                                handleItemNameNumberChange}
                            onBlur={() => handleRemoveErrorMessage('name')}
                            onFocus={() => handleCheckError()}
                            uiReportOnBlur={false}
                            disabled={false}
                            uiDisabled={false}
                            pattern="^.*$"
                            uiValidityMessages={{
                                patternMismatch: "Please fill out this field..",
                            }}
                            list="autocompleteOff"
                            autoComplete="off"
                            ref={nameInputRef}
                        />
                    )}
                </div>
                <div className="flex flex-col mb-2">
                    <FormLabel htmlFor="itemNumber">Item #</FormLabel>
                    <FormInput
                        id="itemNumber"
                        name="Item Number"
                        className="px-1.5 py-2"
                        type="text"
                        value={formFields.itemNumber}
                        onChange={handleItemNameNumberChange}
                        onFocus={() => handleRemoveErrorMessage('item')}
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        list="autocompleteOff"
                        autoComplete="off"
                    />
                </div>
                <div className="mb-2">
                    <FormLabel htmlFor="Itemcategory">
                        Preferred Supplier
                    </FormLabel>
                    <SearchSelect
                        onItemClick={(id, name) =>
                            handleSelectSupplier(id, name)
                        }
                        onSearch={handleSearchSupplier}
                        initialValue={formFields.supplierName}
                        placeholder="Please select"
                        keepKeyword
                        onKeywordCleared={() => handleSelectSupplier(0, "")}
                        searchType="modal"
                        searchItem="supplier"
                        searchPage="myshop"
                        required={false}
                        openModalClick={openModalClick}
                        onFocusMessage={() => {
                            if (setIsNameMessageError) {
                                setIsNameMessageError(false)
                            }
                            setIsNameUnique(false)
                            if (setIsNameMessageError) {
                                setIsNameMessageError(false)
                            }
                            if (isCategory) {
                                if (setIsCategoryErrorMessage) {
                                    setIsCategoryErrorMessage(false)
                                }
                            }
                        }}                    />
                </div>
                <div className="mt-4">
                    {isDefault ||
                    formFields.categoryName === "Fabrics & Trims" ||
                    formFields.categoryName === "Linings" ? (
                        <LockedLabel
                            text="Type"
                            toolTipText="This cannot be edited"
                        />
                    ) : (
                        <p className="m-0">
                            Type<span className="text-red-600">*</span>
                        </p>
                    )}
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeProduct"
                            name="itemType"
                            type="radio"
                            checked={formFields.itemTypeId === 1}
                            value={1}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={isDefault}
                            required={formFields.itemTypeId === -1}
                        />
                        Product
                    </RadioLabel>
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="itemTypeService"
                            name="itemType"
                            type="radio"
                            checked={formFields.itemTypeId === 2}
                            value={2}
                            onChange={handleFieldChange}
                            uiReportOnBlur={false}
                            disabled={
                                isDefault ||
                                formFields.categoryName === "Fabrics & Trims" ||
                                formFields.categoryName === "Linings"
                            }
                            required={formFields.itemTypeId === -1}
                        />
                        Service
                    </RadioLabel>
                </div>
                <TextAreaContainer>
                    <span>Sales Description</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Client-facing description visible on estimates and
                        invoices
                    </p>
                    <TextArea
                        id="salesDescription"
                        name="salesDescription"
                        onChange={handlePrefillTextAreaChange}
                        onKeyDown={handleEnterPressed}
                        onFocus={() => handleRemoveErrorMessage('textarea')}
                    />
                </TextAreaContainer>
                <TextAreaContainer>
                    <span>Ordering Description</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Supplier-facing description visible on purchase orders
                    </p>
                    <TextArea
                        id="orderingDescription"
                        name="orderingDescription"
                        onChange={handlePrefillTextAreaChange}
                        onKeyDown={handleEnterPressed}
                        onFocus={() => handleRemoveErrorMessage('textarea')}
                    />
                </TextAreaContainer>
                <TextAreaContainer>
                    <span>Internal Notes</span>
                    <p className="m-0 text-sm text-twBlue1">
                        Notes for you and your team only; not visible on any
                        forms
                    </p>
                    <TextArea
                        id="notes"
                        name="notes"
                        value={formFields.notes}
                        onChange={handleFieldChange}
                        onKeyDown={handleEnterPressed}
                        onFocus={() => handleRemoveErrorMessage('textarea')}
                    />
                </TextAreaContainer>
            </div>
            <div>
                <CategoryModal
                    isModalOpen={isAddCategoryModalOpen}
                    onModalClose={closeAddCategoryModal}
                    editCategoryId={null}
                    categories={categories}
                    reloadList={fetchListWrapper}
                />
            </div>
            <ItemAvatar dispatch={dispatch} formFields={formFields} />
        </div>
    );
};

export default StandardItemModalDetails;
