import {
    Category,
    CategoryNew,
    CategoryView,
    GetAllQueryParams,
    UpdateCategory,
} from "../interfaces/category";

import axiosInstance from "./axios-instance";

const getAll = async (queryParams?: GetAllQueryParams) => {
    try {
        const response = await axiosInstance.get<Category[]>("/category", {
            params: queryParams,
        });
        return response.data;
        console.log("")
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
};

const getRecord = async ({ recordId }: { recordId: number }) => {
    try {
        const response = await axiosInstance.get<CategoryView>(
            `/category/${recordId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error adding new category:", error);
        throw error;
    }
};

const addRecord = async (payload: CategoryNew) => {
    try {
        const response = await axiosInstance.post<Category>(
            "/category",
            payload
        );
        return response;
    } catch (error) {
        console.error("Error adding new category:", error);
        throw error;
    }
};

const updateRecord = async (payload: UpdateCategory) => {
    try {
        const response = await axiosInstance.put<UpdateCategory>(
            `/category/${payload.id}`,
            payload
        );
        return response;
    } catch (error) {
        console.error("Error updating category:", error);
        throw error;
    }
};

export { getAll, getRecord, addRecord, updateRecord };
