import { Api } from "@quipa/api";
import { flagRoot } from "../../utils";

export type Room = Api.Room;

// NOTE: NOT NEEDED

// tslint:disable-next-line:no-empty-interface
export interface State {
}

export function defaults(): State {
    const st: State = {
    };
    return flagRoot(st);
}
