import { AccountModule, RootState } from "../redux";
import {
    Button,
    Checkbox,
    FormValidationError,
    Loader,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { NotificationsModule } from "../redux";
import { DispatchProp, connect } from "react-redux";
import {
    HBorder,
    HContainer,
    HSpace,
    VBorder,
    VContainer,
    VSpace,
} from "../components/UI/more_detail/container";
import { PlanInfo, SubscriptionInfo } from "../redux/modules/account";
import React, {
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    PureComponent,
} from "react";
import styled, { theme } from "../theme";
import { Crisp } from "crisp-sdk-web";
import { AccountAvatar } from "../components/UI/avatar";
import { AvatarModal } from "../modals/avatar_modal";
import { BillingItem } from "../components/account_settings/billing_grid_row";
import { BillingTable } from "../components/account_settings/billing_table";
import Bind from "lodash-decorators/bind";
import { CancellationModal } from "../modals/cancellation-modal";
import { ChangePlanModal } from "../modals/change_plan_modal";
import { Config } from "../utils";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import { PageHeader } from "../components/content/page_header";
import { QuickbookConnectModal } from "../modals/quickbook_connect_modal";
import SelectList from "../components/UI/select-list";
import TrashIconE from "../assets/icons/account_settings_trash.svg";
import {
    getCustomerPlanInfo,
    getCustomerBillingHistory,
    cancelSubscription,
    getLastDigits,
    updateStripeProfile,
    updateStripePayment,
} from "../api/stripe";
import { updateCrispProfile, getCrispProfile } from "../api/crisp";
import { UpdateCreditCardModal } from "../modals/update_credit_card";
import { ValidationError } from "@quipa/api";
import { detect } from "detect-browser";
import moment from "moment";
import { BillingHistoryModal } from "../modals/billing_history";

interface AccountSettingsProps {
    /**
     * Account id
     */
    accountId: number;
    /**
     * True when quickbook account was connected
     */
    quickbookConnected: boolean;
    /**
     * Account last card digits
     */
    // cardDigits: string | null;
    /**
     * User role
     */
    userRole: "admin" | "office_worker";
    /**
     * User role
     */
    type: "subscribed" | "unsubscribed";
}

export const SaveButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
    margin-left: auto;
`;

export const ChangePlanButton = styled(Button)`
    width: 150px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

export const BilllingCommentSpan = styled.span`
    color: #3b97b1;
`;

export const Form = styled(ValidationForm)`
    font-weight: 400;
    width: 97%;
    max-width: 1200px;
`;
export const CustomizedForm = styled(Form)`
    width: 100%;
`;

export const FormSubtitle = styled.h4`
    color: #338daa;
    font-weight: 400;
    margin-top: 10px;
`;

export const Subtitle = styled.h4`
    color: #000;
    font-weight: 400;
    margin-top: 0;
    margin-top: 30px;
`;

export const Content = styled.h4`
    color: ${theme.colors.grayRGBA};
    font-weight: 400;
    margin-top: 0;
`;

export const FormLabel = styled.label`
    color: ${theme.colors.grayRGBA};
    font-weight: 400;

    .red-asterisk {
        color: #ff6084;
    }
`;

export const FormInput = styled(ValidationPopupInput)`
    height: 34px;
    border-color: #3b97b1;
`;

export const DisableAutocompleteInput = styled.input`
    display: none;
`;

export const BillingCheckBox = styled(Checkbox)`
    margin: 10px 0;
`;

const SubContentSection = styled.div`
    min-width: 800px;
`;

const ClientSettingsAdittionalForm = styled.div`
    margin-left: 20px;
    width: 80%;
    display: flex;
`;

const SubtitleWrapper = styled.div`
    display: flex;
    align-items: center;

    > h4 {
        margin: 0;
    }

    > h4:last-child {
        margin-left: 5px;
    }

    > span {
        margin-left: 70px;
        cursor: pointer;
    }
`;

// const ClientSettingsTextArea = styled(TextArea)`
//     max-width: 450px;
//     height: 80px;
//     font-size: 13px;
// `;

const Plus = styled.span`
    font-size: 25px;
    font-weight: 400;
    border: 2px solid #969696;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 1px 11px;
    cursor: pointer;
    color: ${theme.colors.grayText};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TrashIcon = styled(TrashIconE)`
    width: 40px;
    height: 40px;
    stroke: #969696;
    fill: #fff;
`;

const TrashContainer = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const AvatarContainer = styled.div`
    flex: 1;
    min-width: 220px;
    max-width: 350px;
    width: 100%;
`;

const AvatarActionContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

const GrayBorder = styled.span`
    padding: 11px 0 6px;
    border: 1px solid #969696;
    margin: 0 10px 0 15px;
`;

// const ConfirmationEmail = styled.div`
//     color: #ff6084;
//     font-style: italic;
//     margin-top: 20px;
// `;

const defaultBillingInfoValues = (): AccountModule.BillingInfo => ({
    companyName: "",
    email: "",
    address: "",
    unitSuite: "",
    city: "",
    state: "",
    zipCode: "",
});

const defaultFieldValues = (): AccountInfoFields => ({
    avatarId: "",
    billingInfo: defaultBillingInfoValues(),
    clientFacingInfo: defaultBillingInfoValues(),
    sameAsBilling: false,
});

interface AccountInfoFields {
    avatarId?: string | null;
    billingInfo: AccountModule.BillingInfo;
    clientFacingInfo: AccountModule.BillingInfo;
    sameAsBilling: boolean;
}

interface State {
    historyItems: BillingItem[];
    plans: PlanInfo[];
    historyLoading: boolean;
    creditCardModalActive: boolean;
    billingHistoryModalActive: boolean;
    changePlanModalActive: boolean;
    quickbookModalActive: boolean;
    formFields: AccountInfoFields;
    imageModalOpen: boolean;
    estimateNotes: string;
    last4: string;
    isCancellationModalOpen: boolean;
    updateCardError: string;
    isCancellationMembership: boolean;
    currentPlanInfo: SubscriptionInfo | undefined;
}

export class AccountSettings extends PureComponent<
    AccountSettingsProps & DispatchProp,
    State
> {
    public constructor(props: AccountSettingsProps & DispatchProp) {
        super(props);

        this.state = {
            historyItems: [],
            last4: "",
            plans: [],
            historyLoading: true,
            billingHistoryModalActive: false,
            creditCardModalActive: false,
            changePlanModalActive: false,
            quickbookModalActive: false,
            formFields: defaultFieldValues(),
            imageModalOpen: false,
            estimateNotes: "",
            isCancellationModalOpen: false,
            updateCardError: "",
            isCancellationMembership: false,
            currentPlanInfo: undefined,
        };
    }

    public async componentDidMount(): Promise<void> {
        const { dispatch, accountId } = this.props;
        if(!localStorage.getItem(`login-event-${accountId}`)){
            localStorage.setItem(`login-event-${accountId}`, 'login' + Math.random());
        }
        const profile = await getCrispProfile(accountId);
        if(profile){
            Crisp.user.setEmail(profile.email);
            Crisp.user.setNickname(`${profile.person.nickname}`); 
        }

        const last4 = await getLastDigits(accountId);
        const billingRes = await getCustomerBillingHistory(accountId);

        let items: BillingItem[] = [];
        if (billingRes.length > 0) {
            items = billingRes.map((i: any) => ({
                amount: i.amount,
                date: new Date(i.date),
                description: i.description,
                method: i.source,
                receiptUrl: i.receiptUrl,
            }));
        }

        const plansRes = await dispatch(AccountModule.getPlans(accountId));
        if (!plansRes.error) {
            this.setState({ plans: plansRes.payload });
        }

        await this.loadCurrentPlanInfo();

        const res = await dispatch(AccountModule.getAccount(accountId));
        if (!res.error) {
            this.setState({
                formFields: {
                    avatarId: res.payload.avatarId,
                    billingInfo: res.payload.billingInfo,
                    clientFacingInfo: res.payload.clientFacingInfo,
                    sameAsBilling: res.payload.sameAsBilling,
                },
                historyItems: items,
                historyLoading: false,
                last4: last4,
            });
        }
    }

    public render(): JSX.Element {
        const browser = detect();
        const {
            quickbookModalActive,
            creditCardModalActive,
            billingHistoryModalActive,
            changePlanModalActive,
            formFields,
            last4,
            historyItems,
            historyLoading,
            isCancellationModalOpen,
            isCancellationMembership,
            currentPlanInfo,
            plans,
        } = this.state;

        // const { cardDigits } = this.props;
        const monthlyPrice =
            currentPlanInfo && currentPlanInfo.monthlyPrice
                ? currentPlanInfo.monthlyPrice
                : 0;

        const discountAmount =
            currentPlanInfo && currentPlanInfo.discountAmount
                ? currentPlanInfo.discountAmount
                : 0;
        const isCanceled =
                currentPlanInfo && currentPlanInfo.cancelAtPeriodEnd
                    ? true
                    : false;
        const currentPlan = currentPlanInfo
            ? currentPlanInfo.planType
            : undefined;
        const planName = currentPlanInfo ? currentPlanInfo.planName : '';
        let currentPath;
        if (typeof window !== 'undefined') {
            currentPath = window.location.href;
        } else {
            console.log('Not in a browser environment, unable to access window.location');
        }
        const baseUrl = currentPath && currentPath.replace(/\/account_settings$/, '');
        
        let stripeKey = 'pk_test_1SiljWdVwCkxpoBGYxDSagtF';

        if (baseUrl === 'https://dev.quipa.com'){
            stripeKey = 'pk_test_1SiljWdVwCkxpoBGYxDSagtF';
        }

        if (baseUrl === 'https://staging.quipa.com'){
            stripeKey = 'pk_test_1SiljWdVwCkxpoBGYxDSagtF';
        }

        if (baseUrl === 'https://my.quipa.com'){
            stripeKey = 'pk_live_Ma3R4qIDnTMFj6ly1RQA1Nyi';
        }
        return (
            <PageContent>
                <PageHeader title="Account Settings" />
                <ContentSection>
                    <SubContentSection>
                        <VSpace uiHeight="20px" />

                        <Form
                            uiOnSubmit={this.handleSubmitForm}
                            uiReportValidityMode="firstInvalid"
                            onKeyPress={this.handleKeyPressForm}
                            uiPadding={false}
                            id="mainAccountSettingsForm"
                        >
                            <DisableAutocompleteInput name="firstName" />

                            <HContainer>
                                <AvatarContainer>
                                    <AccountAvatar
                                        avatarId={formFields.avatarId}
                                        openAvatarModal={this.openAvatarModal}
                                        deleteAvatarImage={
                                            this.deleteAvatarImage
                                        }
                                    />
                                    <AvatarActionContainer>
                                        <Plus onClick={this.openAvatarModal}>
                                            +
                                        </Plus>
                                        {formFields.avatarId && (
                                            <>
                                                <GrayBorder />
                                                <TrashContainer
                                                    onClick={
                                                        this.deleteAvatarImage
                                                    }
                                                >
                                                    <TrashIcon />
                                                </TrashContainer>
                                            </>
                                        )}
                                    </AvatarActionContainer>
                                </AvatarContainer>

                                <HSpace uiWidth="20px" />
                                <HBorder
                                    uiWidth="1px"
                                    uiBorderColor="#3b97b1"
                                />
                                <HSpace uiWidth="20px" />

                                <VContainer uiFlex="1" uiMinWidth="225px">
                                    <FormSubtitle>
                                        BILLING INFORMATION
                                    </FormSubtitle>

                                    <VSpace uiHeight="41px" />

                                    <FormLabel htmlFor="companyName">
                                        Company Name
                                        <span className="red-asterisk">*</span>
                                    </FormLabel>
                                    <FormInput
                                        id="companyName"
                                        name="billingInfo.companyName"
                                        className="label"
                                        type="text"
                                        required
                                        value={
                                            formFields.billingInfo
                                                .companyName || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />

                                    <FormLabel htmlFor="address">
                                        Address
                                    </FormLabel>
                                    <FormInput
                                        id="address"
                                        name="billingInfo.address"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.billingInfo.address || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />

                                    <FormLabel htmlFor="unitSuite">
                                        Suite / Unit
                                    </FormLabel>
                                    <FormInput
                                        id="unitSuite"
                                        name="billingInfo.unitSuite"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.billingInfo.unitSuite ||
                                            ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />

                                    <VContainer>
                                        <FormLabel htmlFor="city">
                                            City
                                        </FormLabel>
                                        <FormInput
                                            id="city"
                                            name="billingInfo.city"
                                            className="label"
                                            type="text"
                                            value={
                                                formFields.billingInfo.city ||
                                                ""
                                            }
                                            onChange={this.handleFieldChange}
                                            uiReportOnBlur={false}
                                            autoComplete={
                                                browser &&
                                                browser.name === "chrome"
                                                    ? "new-password"
                                                    : "off"
                                            }
                                            list="autocompleteOff"
                                        />
                                    </VContainer>

                                    <HContainer>
                                        <VContainer uiFlex="1">
                                            <SelectList
                                                label={"State"}
                                                // tslint:disable-next-line:max-line-length
                                                selectList={[
                                                    "AL",
                                                    "AK",
                                                    "AZ",
                                                    "AR",
                                                    "CA",
                                                    "CO",
                                                    "CT",
                                                    "DE",
                                                    "DC",
                                                    "DR",
                                                    "FL",
                                                    "GA",
                                                    "HI",
                                                    "ID",
                                                    "IL",
                                                    "IN",
                                                    "IA",
                                                    "KS",
                                                    "KY",
                                                    "LA",
                                                    "ME",
                                                    "MT",
                                                    "NE",
                                                    "NV",
                                                    "NH",
                                                    "NJ",
                                                    "NM",
                                                    "NY",
                                                    "NC",
                                                    "ND",
                                                    "OH",
                                                    "OK",
                                                    "OR",
                                                    "MD",
                                                    "MA",
                                                    "MI",
                                                    "MN",
                                                    "MS",
                                                    "MO",
                                                    "PA",
                                                    "RI",
                                                    "SC",
                                                    "SD",
                                                    "TN",
                                                    "TX",
                                                    "UT",
                                                    "VT",
                                                    "VA",
                                                    "WA",
                                                    "WV",
                                                    "WI",
                                                    "WY",
                                                ]}
                                                handleClickSelect={
                                                    this.handleFieldState
                                                }
                                                valueSelect={
                                                    formFields.billingInfo.state
                                                }
                                                flexDirection={"column"}
                                                name="billingInfo.state"
                                                smallPadding
                                                uiBorderColor="#3b97b1"
                                                customValue
                                                noAddButton
                                                noClearBox
                                                uiDropArrow
                                            />
                                        </VContainer>

                                        <HSpace uiWidth="10px" />

                                        <VContainer uiFlex="2">
                                            <FormLabel htmlFor="zipCode">
                                                Zip
                                            </FormLabel>
                                            <FormInput
                                                id="zipCode"
                                                name="billingInfo.zipCode"
                                                className="label"
                                                type="text"
                                                value={
                                                    formFields.billingInfo
                                                        .zipCode || ""
                                                }
                                                onChange={
                                                    this.handleFieldChange
                                                }
                                                uiReportOnBlur={false}
                                                autoComplete={
                                                    browser &&
                                                    browser.name === "chrome"
                                                        ? "new-password"
                                                        : "off"
                                                }
                                                list="autocompleteOff"
                                            />
                                        </VContainer>
                                    </HContainer>

                                    <FormLabel htmlFor="billingEmailOwner">
                                        Email
                                        <span className="red-asterisk">*</span>
                                    </FormLabel>
                                    <FormInput
                                        id="billingEmailOwner"
                                        name="billingInfo.email"
                                        className="label"
                                        type="email"
                                        required
                                        value={
                                            formFields.billingInfo.email || ""
                                        }
                                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />

                                    <FormLabel htmlFor="billingPhone">
                                        Phone
                                        <span className="red-asterisk">*</span>
                                    </FormLabel>
                                    <FormInput
                                        id="billingPhone"
                                        name="billingInfo.phone"
                                        className="label"
                                        type="text"
                                        required
                                        value={
                                            formFields.billingInfo.phone || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                    />

                                    <VSpace uiHeight="20px" />
                                </VContainer>

                                <HSpace uiWidth="20px" />
                                <HBorder
                                    uiWidth="1px"
                                    uiBorderColor="#3b97b1"
                                />
                                <HSpace uiWidth="20px" />

                                <VContainer uiFlex="1" uiMinWidth="225px">
                                    <FormSubtitle>
                                        CLIENT FACING INFORMATION
                                    </FormSubtitle>

                                    <BillingCheckBox
                                        id="sameAsBilling"
                                        name="sameAsBilling"
                                        checked={formFields.sameAsBilling}
                                        onChange={this.handleFieldChange}
                                    >
                                        Same as Billing
                                    </BillingCheckBox>

                                    <FormLabel htmlFor="companyName">
                                        Company Name
                                        <span className="red-asterisk">*</span>
                                    </FormLabel>
                                    <FormInput
                                        id="clientFacingCompanyName"
                                        name="clientFacingInfo.companyName"
                                        className="label"
                                        type="text"
                                        required
                                        value={
                                            formFields.clientFacingInfo
                                                .companyName || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <FormLabel htmlFor="address">
                                        Address
                                    </FormLabel>
                                    <FormInput
                                        id="clientFacingAddress"
                                        name="clientFacingInfo.address"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.clientFacingInfo
                                                .address || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <FormLabel htmlFor="unitSuite">
                                        Suite / Unit
                                    </FormLabel>
                                    <FormInput
                                        id="clientFacingUnitSuite"
                                        name="clientFacingInfo.unitSuite"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.clientFacingInfo
                                                .unitSuite || ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <FormLabel htmlFor="city">City</FormLabel>
                                    <FormInput
                                        id="clientFacingCity"
                                        name="clientFacingInfo.city"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.clientFacingInfo.city ||
                                            ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <HContainer>
                                        <VContainer uiFlex="1">
                                            <SelectList
                                                label={"State"}
                                                selectList={[
                                                    "AL",
                                                    "AK",
                                                    "AZ",
                                                    "AR",
                                                    "CA",
                                                    "CO",
                                                    "CT",
                                                    "DE",
                                                    "DC",
                                                    "DR",
                                                    "FL",
                                                    "GA",
                                                    "HI",
                                                    "ID",
                                                    "IL",
                                                    "IN",
                                                    "IA",
                                                    "KS",
                                                    "KY",
                                                    "LA",
                                                    "ME",
                                                    "MT",
                                                    "NE",
                                                    "NV",
                                                    "NH",
                                                    "NJ",
                                                    "NM",
                                                    "NY",
                                                    "NC",
                                                    "ND",
                                                    "OH",
                                                    "OK",
                                                    "OR",
                                                    "MD",
                                                    "MA",
                                                    "MI",
                                                    "MN",
                                                    "MS",
                                                    "MO",
                                                    "PA",
                                                    "RI",
                                                    "SC",
                                                    "SD",
                                                    "TN",
                                                    "TX",
                                                    "UT",
                                                    "VT",
                                                    "VA",
                                                    "WA",
                                                    "WV",
                                                    "WI",
                                                    "WY",
                                                ]}
                                                handleClickSelect={
                                                    this.handleFieldState
                                                }
                                                valueSelect={
                                                    formFields.clientFacingInfo
                                                        .state
                                                }
                                                flexDirection={"column"}
                                                name="clientFacingInfo.state"
                                                smallPadding
                                                uiBorderColor="#3b97b1"
                                                customValue
                                                noAddButton
                                                disabled={
                                                    formFields.sameAsBilling
                                                }
                                                noClearBox
                                                uiDropArrow
                                            />
                                        </VContainer>

                                        <HSpace uiWidth="10px" />

                                        <VContainer uiFlex="2">
                                            <FormLabel htmlFor="zipCode">
                                                Zip
                                            </FormLabel>
                                            <FormInput
                                                id="clientFacingZipCode"
                                                name="clientFacingInfo.zipCode"
                                                className="label"
                                                type="number"
                                                value={
                                                    formFields.clientFacingInfo
                                                        .zipCode || ""
                                                }
                                                onChange={
                                                    this.handleFieldChange
                                                }
                                                uiReportOnBlur={false}
                                                autoComplete={
                                                    browser &&
                                                    browser.name === "chrome"
                                                        ? "new-password"
                                                        : "off"
                                                }
                                                list="autocompleteOff"
                                                disabled={
                                                    formFields.sameAsBilling
                                                }
                                                uiDisabled={
                                                    formFields.sameAsBilling
                                                }
                                            />
                                        </VContainer>
                                    </HContainer>

                                    <FormLabel htmlFor="clientFacingEmailOwner">
                                        Email
                                    </FormLabel>
                                    <FormInput
                                        id="clientFacingEmailOwner"
                                        name="clientFacingInfo.email"
                                        className="label"
                                        type="email"
                                        value={
                                            formFields.clientFacingInfo.email ||
                                            ""
                                        }
                                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <FormLabel htmlFor="clientFacingPhone">
                                        Phone
                                    </FormLabel>
                                    <FormInput
                                        id="clientFacingPhone"
                                        name="clientFacingInfo.phone"
                                        className="label"
                                        type="text"
                                        value={
                                            formFields.clientFacingInfo.phone ||
                                            ""
                                        }
                                        onChange={this.handleFieldChange}
                                        uiReportOnBlur={false}
                                        autoComplete={
                                            browser && browser.name === "chrome"
                                                ? "new-password"
                                                : "off"
                                        }
                                        list="autocompleteOff"
                                        disabled={formFields.sameAsBilling}
                                        uiDisabled={formFields.sameAsBilling}
                                    />

                                    <VSpace uiHeight="20px" />
                                </VContainer>
                            </HContainer>

                            <VSpace uiHeight="30px" />

                            <HContainer>
                                <SaveButton
                                    uiColor="primary"
                                    type="submit"
                                    form="mainAccountSettingsForm"
                                >
                                    Save
                                </SaveButton>
                            </HContainer>
                        </Form>

                        <VSpace uiHeight="40px" />
                        <VBorder uiHeight="20px" uiBorderColor="#e8f6fc" />
                        <VSpace uiHeight="40px" />

                        <ClientSettingsAdittionalForm>
                            <HContainer>
                                <VContainer uiFlex="1">
                                    <FormSubtitle>
                                        BILLING / MEMBERSHIP
                                    </FormSubtitle>
                                    <SubtitleWrapper>
                                        <Subtitle>ACCOUNT ID</Subtitle>
                                        <Content>
                                            {this.props.accountId}
                                        </Content>
                                    </SubtitleWrapper>
                                    <VSpace uiHeight="10px" />

                                    <Subtitle>CURRENT PLAN</Subtitle>
                                    {!currentPlanInfo && (
                                        <Loader />
                                    )}
                                    {currentPlanInfo &&
                                        <>
                                            <Content>{planName}</Content>
                                            <Content>
                                                {new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }).format(
                                                    (monthlyPrice - discountAmount) /
                                                        100,
                                                )}{" "}
                                                monthly
                                            </Content>
                                            <Content>
                                                {isCanceled || isCancellationMembership ? 'End Date':'Renewal Date'}:{" "}
                                                {currentPlanInfo &&
                                                currentPlanInfo.renewalDate
                                                    ? moment(
                                                        currentPlanInfo.renewalDate,
                                                    ).format("MMMM DD, YYYY")
                                                    : ""}
                                            </Content>

                                            <VSpace uiHeight="10px" />
                                            <SubtitleWrapper>
                                                <ChangePlanButton
                                                    uiColor="primary"
                                                    onClick={this.openChangePlanModal}
                                                    disabled
                                                    uiDisabled
                                                >
                                                    Change Plan
                                                </ChangePlanButton>
                                                {this.props.type === "subscribed" && !isCanceled && !isCancellationMembership && (
                                                    <BilllingCommentSpan
                                                        onClick={
                                                            this.openCancellationModal
                                                        }
                                                    >
                                                        Cancel Membership
                                                    </BilllingCommentSpan>
                                                )}
                                            </SubtitleWrapper>
                                            {/* {isCancellationMembership && (
                                                <ConfirmationEmail>
                                                    You will receive a confirmation
                                                    email shortly.
                                                </ConfirmationEmail>
                                            )} */}
                                        </>
                                    }
                                </VContainer>

                                <HSpace uiWidth="20px" />
                                <HBorder
                                    uiWidth="1px"
                                    uiBorderColor="#3b97b1"
                                />
                                <HSpace uiWidth="20px" />

                                <VContainer uiFlex="1">
                                    {/* <VSpace uiHeight="40px" /> */}
                                    <SubtitleWrapper>
                                    {last4 &&
                                        <>
                                            <Subtitle>CURRENT CARD</Subtitle>
                                            <Content className="card">
                                                {`x${last4}`}
                                            </Content>
                                        </>
                                    }
                                    </SubtitleWrapper>
                                    <VSpace uiHeight="10px" />
                                    <ChangePlanButton
                                        uiColor="primary"
                                        onClick={this.openCreditCardModal}
                                        // disabled
                                        // uiDisabled
                                    >
                                        Change Card 
                                    </ChangePlanButton>
                                    

                                    <Subtitle>PAYMENT HISTORY</Subtitle>
                                    {historyLoading && (
                                        <Loader />
                                    )}
                                    <BillingTable
                                        items={historyItems}
                                        loading={historyLoading}
                                        onOpenModal={this.openBillingHistoryModal}
                                    />
                                </VContainer>
                            </HContainer>
                        </ClientSettingsAdittionalForm>

                        <VSpace uiHeight="40px" />
                        <VBorder uiHeight="20px" uiBorderColor="#e8f6fc" />
                        <VSpace uiHeight="40px" />

                        {/* <ClientSettingsAdittionalForm>
                            <CustomizedForm
                                uiOnSubmit={this.handleSubmitCustomizedForm}
                                uiReportValidityMode="firstInvalid"
                                uiPadding={false}
                                id="customizedForm"
                            >
                                <HContainer>
                                    <VContainer uiFlex="1">
                                        <FormSubtitle>CUSTOMIZED FORMS</FormSubtitle>
                                        <SubtitleWrapper>
                                            <Subtitle>ESTIMATE NOTES</Subtitle>
                                        </SubtitleWrapper>
                                        <ClientSettingsTextArea
                                            onChange={this.handleTextAreaChange}
                                            value={this.state.estimateNotes}
                                            placeholder="Appears in the notes section of all new Estimates."
                                        />
                                        <VSpace uiHeight="10px" />
                                        <ChangePlanButton
                                            type="submit"
                                            form="customizedForm"
                                            uiColor="primary"
                                            onClick={this.handleSaveEstimateNote}
                                        >
                                            Save
                                        </ChangePlanButton>
                                    </VContainer>
                                </HContainer>
                            </CustomizedForm>
                        </ClientSettingsAdittionalForm> */}

                        {/*
                        <VSpace uiHeight="10px" />
                        <VBorder uiHeight="2px" uiBorderColor="#e8f6fc" />
                        <VSpace uiHeight="10px" />

                        <CardSection>

                            {userRole === "admin" && !quickbookConnected && (
                                <Button
                                    className="connect-to-quickBook"
                                    uiFlat
                                    uiColor={theme.colors.secondary}
                                    onClick={this.openQuickbookModal}
                                >
                                    Connect to QuickBooks
                                </Button>
                            )}
                        </CardSection>*/}
                    </SubContentSection>
                </ContentSection>

                <AvatarModal
                    uiActive={this.state.imageModalOpen}
                    uiOnRequestClose={this.closeAvatarModal}
                    onSave={this.saveAvatarImage}
                />
                <UpdateCreditCardModal
                    configKey={stripeKey}
                    onAddCard={this.updateCreditCard}
                    onRequestClose={this.closeCreditCardModal}
                    active={creditCardModalActive}
                    updateCardError={this.state.updateCardError}
                    onUpdateCardError={this.handleUpdateCardError}
                />
                <BillingHistoryModal
                    onRequestClose={this.closeBillingHistoryModal}
                    active={billingHistoryModalActive}
                    items={historyItems}
                />
                <ChangePlanModal
                    onRequestClose={this.closeChangePlanModal}
                    plans={plans}
                    currentPlan={currentPlan}
                    active={changePlanModalActive}
                    onChangePlan={this.changePlan}
                />
                <QuickbookConnectModal
                    active={quickbookModalActive}
                    oauthClientId={Config.quickbookClientId}
                    oauthRedirectUri={Config.quickbookRedirectUri}
                    onRequestClose={this.closeQuickbookModal}
                    onAccepted={this.connectToQuickbook}
                />
                <CancellationModal
                    active={isCancellationModalOpen}
                    onRequestClose={this.closeCancellationModal}
                    handleConfirmCancellation={this.handleConfirmCancellation}
                    accountId={this.props.accountId}
                />
            </PageContent>
        );
    }

    @Bind()
    private openChangePlanModal(): void {
        this.setState({
            changePlanModalActive: true,
        });
    }

    @Bind()
    private openCreditCardModal(): void {
        this.setState({
            creditCardModalActive: true,
        });
    }

    @Bind()
    private openBillingHistoryModal(): void {
        this.setState({
            billingHistoryModalActive: true,
        });
    }

    @Bind()
    private openCancellationModal(): void {
        this.setState({
            isCancellationModalOpen: true,
        });
    }

    @Bind()
    private closeCreditCardModal(): void {
        this.setState({
            creditCardModalActive: false,
            updateCardError:'',
        });
    }

    @Bind()
    private closeBillingHistoryModal(): void {
        this.setState({
            billingHistoryModalActive: false,
        });
    }

    @Bind()
    private closeChangePlanModal(): void {
        this.setState({
            changePlanModalActive: false,
        });
    }

    @Bind()
    private closeCancellationModal(): void {
        this.setState({
            isCancellationModalOpen: false,
        });
    }

    @Bind()
    private closeQuickbookModal(): void {
        this.setState({
            quickbookModalActive: false,
        });
    }

    // @Bind()
    // // tslint:disable-next-line:no-empty
    // private handleSaveEstimateNote(): void {}

    @Bind()
    private async updateCreditCard(
        token: string,
    ): Promise<void> {
        
        const {  accountId } = this.props;
        try {
            await updateStripePayment(accountId, token);
            this.setState({
                updateCardError: '',
            });
            window.location.reload();
            return;
        }catch (error){
            if (error.response && error.response.data && error.response.data.error) {
                this.setState({
                    updateCardError: `${error.response.data.error}`,
                });
            } else {
                this.setState({
                    updateCardError: `${error.message}`,
                });
            }
        }
    }

    @Bind()
    private async changePlan(planType: string): Promise<void> {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            AccountModule.subscribeAccount(accountId, planType),
        );
        if (res.error) {
            throw new Error(res.payload.message);
        }
        await this.loadCurrentPlanInfo();
        this.setState({
            changePlanModalActive: false,
        });
    }

    @Bind()
    private async connectToQuickbook(
        code: string,
        realmid: string,
    ): Promise<void> {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            AccountModule.connectToQuickbook(accountId, code, realmid),
        );
        if (res.error) {
            throw new Error(res.payload.message);
        }
        this.setState({
            quickbookModalActive: false,
        });
    }

    private loadCurrentPlanInfo = async () => {
        const { accountId } = this.props;
        
        const planInfo = await getCustomerPlanInfo(accountId);
        
        if (planInfo) {
            this.setState({
                currentPlanInfo: {
                    planType: planInfo.object,
                    planName: planInfo.planName,
                    startDate: new Date(planInfo.current_period_start * 1000),
                    renewalDate: new Date(planInfo.current_period_end * 1000),
                    monthlyPrice: planInfo && planInfo.plan && planInfo.plan.amount,
                    planId: planInfo && planInfo.plan && planInfo.plan.id,
                    promoId:
                        planInfo.discount && planInfo.discount.promotion_code,
                    cancelAtPeriodEnd: planInfo.cancel_at_period_end ? true : false,
                    discountAmount:
                        planInfo.discount &&
                        (planInfo.plan.amount *
                            planInfo.discount.coupon.percent_off) /
                            100,
                },
            });
        }
    };

    private handleUpdateCardError = (error: string) => {
        this.setState({ updateCardError: error });
    };

    private openAvatarModal = () => {
        this.setState({
            imageModalOpen: true,
        });
    };

    private closeAvatarModal = () => {
        this.setState({
            imageModalOpen: false,
        });
    };

    private saveAvatarImage = async (file: File) => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(AccountModule.uploadImage(accountId, file));
        if (res.error) {
            throw new Error(res.payload.message);
        }

        const res1 = await dispatch(
            AccountModule.updateAccountSettings(
                accountId,
                undefined,
                undefined,
                undefined,
                res.payload.url,
            ),
        );
        if (res1.error) {
            if (res1.payload.name === "ValidationError") {
                const errors = (res1.payload as Error as ValidationError)
                    .errors;
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res1.payload.message);
            }
        } else {
            this.setState({
                formFields: {
                    ...this.state.formFields,
                    avatarId: res.payload.url,
                },
                imageModalOpen: false,
            });
        }
    };

    private deleteAvatarImage = async () => {
        const { dispatch, accountId } = this.props;
        const res = await dispatch(
            AccountModule.updateAccountSettings(
                accountId,
                undefined,
                undefined,
                undefined,
                null,
            ),
        );
        if (res.error) {
            if (res.payload.name === "ValidationError") {
                const errors = (res.payload as Error as ValidationError).errors;
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.payload.message);
            }
        } else {
            this.setState({
                formFields: {
                    ...this.state.formFields,
                    avatarId: null,
                },
            });
        }
    };

    @Bind()
    private async handleFieldChange(
        e: ChangeEvent<HTMLInputElement>,
    ): Promise<void> {
        const updatedForm = {
            ...this.state.formFields,
        };

        let key = e.currentTarget.name;
        const value = e.currentTarget.value;

        if (key.includes("billingInfo.")) {
            key = key.split(".").pop()!;
            updatedForm.billingInfo![key] = value;
            if (updatedForm.sameAsBilling) {
                updatedForm.clientFacingInfo![key] = value;
            }
        } else if (key.includes("clientFacingInfo.")) {
            key = key.split(".").pop()!;
            updatedForm.clientFacingInfo![key] = value;
        } else if (key === "sameAsBilling") {
            updatedForm[key] = e.currentTarget.checked;
            if (e.currentTarget.checked) {
                const targetBillingInfo = {
                    ...this.state.formFields.billingInfo,
                };
                updatedForm.clientFacingInfo = targetBillingInfo;
                // defaultBillingInfoValues();
            }
        } else {
            updatedForm[key] = value;
        }

        this.setState({ formFields: updatedForm });
    }

    // private handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    //     const { value } = e.currentTarget;
    //     this.setState({
    //         estimateNotes: value,
    //     });
    // };

    private handleFieldState = (value: string, name: string) => {
        if (name === "billingInfo.state" || name === "clientFacingInfo.state") {
            return;
        }

        const updatedForm = { ...this.state.formFields };

        let key = name;

        if (key.includes("billingInfo.")) {
            key = key.split(".").pop()!;
            updatedForm.billingInfo![key] = value;
            if (updatedForm.sameAsBilling) {
                updatedForm.clientFacingInfo![key] = value;
            }
        } else if (key.includes("clientFacingInfo.")) {
            key = key.split(".").pop()!;
            updatedForm.clientFacingInfo![key] = value;
        } else {
            updatedForm[key] = value;
        }

        this.setState({ formFields: updatedForm });
    };

    private handleKeyPressForm(e: KeyboardEvent<HTMLElement>) {
        if (
            e.which === 13 &&
            (e.target as HTMLTextAreaElement).name !== "notes"
        ) {
            e.preventDefault();
            return;
        }
    }

    private handleConfirmCancellation = () => {
        this.closeCancellationModal();
        this.cancelMembership();
    };

    @Bind()
    private async cancelMembership(): Promise<void> {
        const { accountId } = this.props;
        // const res = await dispatch(AccountModule.unsubscribeAccount(accountId));
        const res = await cancelSubscription(accountId);
        if (res.status === "canceled") {
            this.setState({ isCancellationMembership: true });
        }
        // if (res.error) {
        //     ///
        // } else {
        //     this.setState({ isCancellationMembership: true });
        // }
    }

    @Bind()
    private async handleSubmitForm(
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> {
        e.preventDefault();

        const { dispatch, accountId } = this.props;
        const { formFields } = this.state;
        const resUpdateProfile = await updateCrispProfile(accountId, formFields.billingInfo);
        
        const stripeProfile = await updateStripeProfile(accountId, formFields.billingInfo);
        
        const res = await dispatch(
            AccountModule.updateAccountSettings(
                accountId,
                formFields.billingInfo,
                formFields.clientFacingInfo,
                formFields.sameAsBilling,
                formFields.avatarId,
            ),
        );
        if (res.error) {
            if (res.payload.name === "ValidationError") {
                const errors = (res.payload as Error as ValidationError).errors;
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.payload.message);
            }
        } else {
            if (resUpdateProfile.status === 200 && stripeProfile.status === 200){
                const notification: NotificationsModule.Notification = {
                    id: 0,
                    message: "Success!",
                    subMessage: 'Billing info updated.',
                    type: "info",
                };
                dispatch(NotificationsModule.addNotification(notification));
            }
            // onClientSaved({
            //     ...this.state.formFields,
            //     subClients: client.subClients,
            //     subClientsIds: client.subClientsIds,
            // });
        }
    }

    // @Bind()
    // private async handleSubmitCustomizedForm(
    //     e: FormEvent<HTMLFormElement>
    // ): Promise<void> {
    //     e.preventDefault();

    //     // const { dispatch, accountId } = this.props;
    //     // // const { estimateNotes } = this.state;
    //     // // need add estimateNotes field for accountSettings model
    //     // const res = await dispatch(AccountModule.updateAccountSettings(accountId, undefined, undefined, undefined, null));
    //     // if (res.error) {
    //     //     if (res.payload.name === "ValidationError") {
    //     //         const errors = (res.payload as Error as ValidationError).errors;
    //     //         throw FormValidationError.fromErrorDictionary(errors);
    //     //     } else {
    //     //         throw new Error(res.payload.message);
    //     //     }
    //     // } else {
    //     //     ///
    //     // }
    // }
}

function mapStateToProps(state: RootState, ownProps: {}): AccountSettingsProps {
    return {
        accountId: state.account.id,
        // cardDigits: state.account.cardDigits,
        quickbookConnected: state.account.quickbookConnected,
        userRole: state.user.role,
        type: state.account.type,
    };
}

export const AccountSettingsContainer =
    connect(mapStateToProps)(AccountSettings);
