import { Category, CategoryView } from "../../../interfaces/category";
import React, { FC, useCallback, useEffect, useState } from "react";

import AddButton from "../../UI/buttons/add-button";
import CategoryItem from "./category-item";
import CategoryModal from "./category-modal";
import { Button, Checkbox } from "@ramble/ramble-ui";
import { getAll } from "../../../api/category";
import { FaArrowLeft } from "react-icons/fa";

interface CategoriesListProps {
    history: any;
}

const CategoriesList: FC<CategoriesListProps> = (props) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [categoriesView, setCategoriesView] = useState<CategoryView[]>([]);

    const [editCategoryId, setEditCategoryId] = useState<number | null>(null);
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const generateListView = useCallback((list: Category[]) => {
        const listView: CategoryView[] = [];
        const getItemById = (id: number): CategoryView | undefined => {
            return list.find((item) => item.id === id);
        };
        list.forEach((item) => {
            if (!item.parentCategoryId) {
                listView.push(item);
                return;
            }
            const parentItem = getItemById(item.parentCategoryId);
            if (parentItem) {
                parentItem.subCategories = parentItem.subCategories || [];
                parentItem.subCategories.push(item);
            }
        });
        setCategoriesView(listView);
    }, []);

    const fetchList = useCallback(async () => {
        try {
            const list = await getAll();
            setCategories(list);
            generateListView(list);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }, []);

    const fetchListWrapper = () => {
        fetchList()
            .then()
            .catch(() => console.log("error in fetchlistwrapper"));
    };

    useEffect(() => {
        fetchListWrapper();
    }, [fetchList]);

    const handleCheckboxClick = () => {
        setShowInactive(!showInactive);
    };

    const onAddClick = () => {
        setIsModalOpen(true);
    };

    const onEdit = (id: number | null) => {
        setEditCategoryId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditCategoryId(null);
    };

    return (
        <div className="container max-w-xl mx-auto mt-2 mb-14 lg:mt-10 grid grid-flow-row auto-rows-min grid-cols-1">
            <div>
                <Button
                    uiColor="primary"
                    className="rounded-lg"
                    onClick={() => props.history.push("/myshop")}
                >
                    <FaArrowLeft className="mr-2 h-3 w-3" />
                    Return to My Shop
                </Button>
            </div>
            <h1 className="text-2xl font-medium text-twPrimary">
                MANAGE CATEGORIES
            </h1>
            <div className="grid grid-cols-2 grid-flow-row w-full mx-auto mb-5">
                <Checkbox
                    checked={showInactive}
                    className="col-span-1"
                    onChange={() => handleCheckboxClick()}
                >
                    Include Inactive
                </Checkbox>
                <AddButton
                    className="col-span-1 max-w-[200px] ml-auto"
                    onClick={onAddClick}
                    title="Add Category"
                />
            </div>

            <div className="w-full border-b">
                <div className="border border-b-0 bg-twBackDropColor p-2">
                    <h2 className="text-xl font-medium">CATEGORIES</h2>
                </div>
                {categoriesView && // NOSONAR
                    categoriesView.map((item) => {
                        //current typescript doesnt support optional chaining hence NOSONAR above
                        return (
                            <>
                                <CategoryItem
                                    key={item.id}
                                    data={item}
                                    showInactive={showInactive}
                                    onEdit={onEdit}
                                />

                                {item.subCategories && // NOSONAR
                                    item.subCategories.map((subCategory) => {
                                        //current typescript doesnt support optional chaining hence NOSONAR above
                                        return (
                                            <CategoryItem
                                                key={subCategory.id}
                                                data={subCategory}
                                                showInactive={showInactive}
                                                isSubCategory={true}
                                                onEdit={onEdit}
                                            />
                                        );
                                    })}
                            </>
                        );
                    })}
            </div>
            <CategoryModal
                isModalOpen={isModalOpen}
                onModalClose={closeModal}
                reloadList={fetchListWrapper}
                editCategoryId={editCategoryId}
                categories={categories}
            />
        </div>
    );
};

export default CategoriesList;
