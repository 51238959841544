import React, { FC } from "react";

import { CategoryView } from "../../../interfaces/category";
import LockIcon from "../../../assets/icons/icons8-lock.svg";
import GenericTooltip from "../../content/GenericTooltip";

interface CategoryItemProps {
    data: CategoryView;
    showInactive: boolean;
    isSubCategory?: boolean;
    onEdit: (id: number | null) => void;
}

const CategoryItem: FC<CategoryItemProps> = ({
    data,
    showInactive,
    isSubCategory,
    onEdit,
}) => {
    const { id, isActive, name, isDefault } = data;
    if (!showInactive && !isActive) return null;
    const keyVal = `listview-${id}`;

    const containerClasses = `border border-b-0 p-2 ${
        !isDefault && "cursor-pointer"
    }`;

    const inactiveClass = !isActive ? "italic text-twGrey" : "";
    const subCategoryClass = isSubCategory ? "ml-4" : "";
    const catName = !isActive ? `${name} (Inactive)` : name;

    return (
        <div key={keyVal} className={containerClasses}>
            <div
                className="flex items-center justify-between"
                onClick={!isDefault ? () => onEdit(id) : undefined}
            >
                <div className={`${inactiveClass} ${subCategoryClass}`}>
                    {catName}
                </div>
                {isDefault && (
                    <GenericTooltip
                        text={
                            <div className="pt-4 px-2">
                                <p>
                                    Categories marked with a lock are system
                                    categories and cannot be edited. You may add
                                    or edit subcategories underneath them as
                                    needed.
                                </p>
                            </div>
                        }
                        position={"top right"}
                    >
                        <LockIcon className="h-4" />
                    </GenericTooltip>
                )}
            </div>
        </div>
    );
};

export default CategoryItem;
