import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { DraperyOrderDesignLining } from "./interfaces";

export const ADD_DRAPERY_ORDER_DESIGN_LINING = "draperyOrderDesignLining/ADD";
export type AddDraperyOrderDesignLiningAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_DESIGN_LINING,
    Omit<DraperyOrderDesignLining, "id"> & { accountId: number } & { draperyOrderDesignId: number },
    DraperyOrderDesignLining
>;

export const EDIT_DRAPERY_ORDER_DESIGN_LINING = "draperyOrderDesignLining/EDIT";
export type EditDraperyOrderDesignLiningAction = ActionDescriptor<typeof EDIT_DRAPERY_ORDER_DESIGN_LINING, DraperyOrderDesignLining, {}>;

export const GET_DRAPERY_ORDER_DESIGN_LINING = "draperyOrderDesignLining/GET";
export type GetDraperyOrderDesignLiningAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_DESIGN_LINING, { id: number }, DraperyOrderDesignLining>;

export const DELETE_DRAPERY_ORDER_DESIGN_LINING = "draperyOrderDesignLining/DELETE";
export type DeleteDraperyOrderDesignLiningAction = ActionDescriptor<typeof DELETE_DRAPERY_ORDER_DESIGN_LINING, { id: number }, {}>;

export const SEARCH_DRAPERY_ORDER_DESIGN_LININGS = "draperyOrderDesignLining/SEARCH";
export type SearchDraperyOrderDesignLiningsAction = ActionDescriptor<typeof SEARCH_DRAPERY_ORDER_DESIGN_LININGS, {
    draperyOrderDesignId: number,
    }, DraperyOrderDesignLining[]>;

export interface DraperyOrderDesignLiningActionsMap {
    [ADD_DRAPERY_ORDER_DESIGN_LINING]: AddDraperyOrderDesignLiningAction;
    [EDIT_DRAPERY_ORDER_DESIGN_LINING]: EditDraperyOrderDesignLiningAction;
    [GET_DRAPERY_ORDER_DESIGN_LINING]: GetDraperyOrderDesignLiningAction;
    [DELETE_DRAPERY_ORDER_DESIGN_LINING]: DeleteDraperyOrderDesignLiningAction;
    [SEARCH_DRAPERY_ORDER_DESIGN_LININGS]: SearchDraperyOrderDesignLiningsAction;
}
