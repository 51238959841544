import { Api, Req } from "@quipa/api";

export const getClientProjects = (
    accountId: number,
    clientId: number,
    includeArchived: boolean = false,
    sort: { field: string; direction: "ASC" | "DESC" }
) =>
    Api.createAction(
        Api.GET_CLIENT_PROJECTS,
        { accountId, clientId, includeArchived, sort },
        { authenticated: true }
    );

export const getAllProjects = (accountId: number, includeArchived: boolean = false, sort: { field: string, direction: 'ASC' | 'DESC' }, limit: number, pageNumber: number, searchTerm: any) =>
    Api.createAction(Api.GET_ALL_PROJECTS, { accountId, includeArchived, sort, limit, pageNumber, searchTerm }, { authenticated: true });

export const editClientProject = (project: Api.EditClientProjectAction[Req]) =>
    Api.createAction(Api.EDIT_CLIENT_PROJECT, project, { authenticated: true });

export const deleteClientProject = (
    project: Api.DeleteClientProjectAction[Req]
) =>
    Api.createAction(Api.DELETE_CLIENT_PROJECT, project, {
        authenticated: true,
    });

export const copyDraperyOrder = (accountId: number, draperyOrderId: number) =>
    Api.createAction(
        Api.COPY_DRAPERY_ORDER,
        { accountId, draperyOrderId },
        { authenticated: true }
    );

export const archiveClientProjectItem = (
    accountId: number,
    draperyOrderId: number,
    archived: boolean
) =>
    Api.createAction(
        Api.ARCHIVE_CLIENT_PROJECT_ITEM,
        { accountId, draperyOrderId, archived },
        { authenticated: true }
    );
