import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { DraperyOrderDesign } from "./interfaces";

export const ADD_DRAPERY_ORDER_DESIGN = "draperyOrderDesign/ADD";
export type AddDraperyOrderDesignAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_DESIGN,
    Omit<DraperyOrderDesign, "id"> & { accountId: number },
    DraperyOrderDesign
>;

export const EDIT_DRAPERY_ORDER_DESIGN = "draperyOrderDesign/EDIT";
export type EditDraperyOrderDesignAction = ActionDescriptor<typeof EDIT_DRAPERY_ORDER_DESIGN, DraperyOrderDesign, {}>;

export const GET_DRAPERY_ORDER_DESIGN = "draperyOrderDesign/GET";
export type GetDraperyOrderDesignAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_DESIGN, { id: number }, DraperyOrderDesign>;

export const DELETE_DRAPERY_ORDER_DESIGN = "draperyOrderDesign/DELETE";
export type DeleteDraperyOrderDesignAction = ActionDescriptor<typeof DELETE_DRAPERY_ORDER_DESIGN, { id: number }, {}>;

export interface DraperyOrderDesignActionsMap {
    [ADD_DRAPERY_ORDER_DESIGN]: AddDraperyOrderDesignAction;
    [EDIT_DRAPERY_ORDER_DESIGN]: EditDraperyOrderDesignAction;
    [GET_DRAPERY_ORDER_DESIGN]: GetDraperyOrderDesignAction;
    [DELETE_DRAPERY_ORDER_DESIGN]: DeleteDraperyOrderDesignAction;
}
