import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import {DraperyOrderItem, DraperyOrderItemAllInfo } from "./interfaces";
import { DraperyOrderDesign } from "../drapery_order_design";
// import { DraperyOrderDesignLining } from "../drapery_order_design_lining";

export const ADD_DRAPERY_ORDER_ITEM = "draperyOrderItem/ADD";
export type AddDraperyOrderItemAction = ActionDescriptor<typeof ADD_DRAPERY_ORDER_ITEM,
    Omit<DraperyOrderItem, "id" | "workOrderId"> & { accountId: number } & { clientId?: number } & { draperyOrderDesignId?: number } & { draperyOrderFabricIds?: number[] } & { draperyOrderSummaryId?: number } & { roomId?: number } & { itemIds?: number[]} & { workOrderId?: number } & DraperyOrderDesign,
    DraperyOrderItem
>;

export const EDIT_DRAPERY_ORDER_ITEM = "draperyOrderItem/EDIT";
export type EditDraperyOrderItemAction = ActionDescriptor<typeof EDIT_DRAPERY_ORDER_ITEM, DraperyOrderItem, {}>;

export const GET_DRAPERY_ORDER_ITEM = "draperyOrderItem/GET";
export type GetDraperyOrderItemAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_ITEM, { id: number }, DraperyOrderItem>;

export const GET_DRAPERY_ORDER_ITEM_ALL_INFO = "draperyOrderAllInfo/GET";
export type GetDraperyOrderItemAllInfoAction = ActionDescriptor<typeof GET_DRAPERY_ORDER_ITEM_ALL_INFO, { id: number }, DraperyOrderItemAllInfo>;

export const DELETE_DRAPERY_ORDER_ITEM = "draperyOrderItem/DELETE";
export type DeleteDraperyOrderItemAction = ActionDescriptor<typeof DELETE_DRAPERY_ORDER_ITEM, { id: number }, {}>;

export interface DraperyOrderItemActionsMap {
    [ADD_DRAPERY_ORDER_ITEM]: AddDraperyOrderItemAction;
    [EDIT_DRAPERY_ORDER_ITEM]: EditDraperyOrderItemAction;
    [GET_DRAPERY_ORDER_ITEM]: GetDraperyOrderItemAction;
    [GET_DRAPERY_ORDER_ITEM_ALL_INFO]: GetDraperyOrderItemAllInfoAction;
    [DELETE_DRAPERY_ORDER_ITEM]: DeleteDraperyOrderItemAction;
}