import { Bind } from "lodash-decorators/bind";
import React, { ChangeEvent, createRef, InputHTMLAttributes, KeyboardEvent, PureComponent, RefObject } from "react";
import CheckSquareIcon from "../icons/check_square_icon";
import SquareIcon from "../icons/square_icon";
import { ColorValue } from "../theme";
import styled, { css } from "../theme/styled";
import { forwardRef, WithForwardedRef } from "../utils/ref";
import { cleanUiProps } from "../utils/withStyledProps";

export interface CheckboxUIProps {
    /**
     * Color to use when checked, defaults to primaryText
     */
    uiColor?: ColorValue;
    /**
     * Disabled checkbox
     */
    uiDisabled?: boolean;
    /**
     * Icon for non checked checkbox
     */
    uiIcon?: React.ReactElement<any>;
    /**
     * Icon for checked checkbox
     */
    uiCheckIcon?: React.ReactElement<any>;
}

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & CheckboxUIProps;

export interface CheckboxState {
    /**
     * Checked state
     */
    checked?: boolean;
}

/**
 * Just an ordinary checkbox
 */
class CheckboxComponent extends PureComponent<CheckboxProps, CheckboxState> {
    private rootRef: RefObject<HTMLLabelElement> = createRef();
    public constructor(props: CheckboxProps) {
        super(props);

        // set to state only if non-controlled, i.e. checked is undefined
        this.state = {
            checked: props.checked != null ? undefined : props.defaultChecked || false,
        };
    }

    public render(): JSX.Element {
        const {
            uiColor, className, uiDisabled, children, uiIcon, uiCheckIcon, forwardedRef, defaultChecked, checked, tabIndex, ...other
        } = this.props as WithForwardedRef<CheckboxProps>;
        cleanUiProps(other);
        return (
            <label
                className={className}
                onKeyDown={this.onKeyDown}
                tabIndex={tabIndex}
                ref={this.rootRef}
            >
                {/* YES!!! You see this. Actually if you have a better idea - let me know (asvetl) */}
                <span className="baseline-helper" />
                <input
                    {...other}
                    tabIndex={tabIndex}
                    ref={forwardedRef}
                    type="checkbox"
                    checked={checked != null ? checked : this.state.checked}
                    className="input"
                    onChange={this.onChange}
                />
                {checked || this.state.checked ? uiCheckIcon || <CheckSquareIcon /> : uiIcon || <SquareIcon />}
                {<span className="label">{children}</span>}
            </label>
        );
    }

    /**
     * Handle checkbox change
     */
    @Bind()
    private onChange(e: ChangeEvent<HTMLInputElement>): void {
        this.handleChange(e);
    }

    @Bind()
    private onKeyDown(e: KeyboardEvent<HTMLElement>): void {
        // space
        if (e.keyCode !== 32 || !this.rootRef.current) {
            return;
        }
        const input = this.rootRef.current.children[1] as HTMLInputElement;
        if (!input || !(input instanceof HTMLInputElement)) {
            return;
        }
        this.handleChange({
            ...e,
            currentTarget: input,
            target: input,
        });
    }

    private handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const { onChange, checked } = this.props;
        if (onChange) {
            onChange(e);
        }
        if (checked == null) {
            this.setState({
                checked: !this.state.checked,
            });
        }
    }
}

const ForwardCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(CheckboxComponent);

const Checkbox = styled(ForwardCheckbox)`
    font-style: normal;
    font-size: 1.2em;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    // position: relative;
    backface-visibility: hidden;
    outline: none;
    cursor: pointer;

    .baseline-helper {
        opacity: 0;
        width: 0;
        visibility: hidden;

        &::after {
            content: ".";
        }
    }

    .makeInactiveLabel {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-top: 4px;
        font-size: 1rem! important;
        line-height: 1.5rem !important;
    }

    > .input {
        opacity: 0;
        cursor: pointer;
        width: 0;
        height: 0;
        visibility: hidden;
        outline: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    > .label {
        cursor: pointer;
        user-select: none;
        font-size: 1em;
        padding-left: 0.4em;
        flex: 0 1 auto;
        color: #222;
    }

    > svg {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #cdcdcd rect {
            fill: #fff;
        }
    }

    &:focus {
        > svg {
            stroke: currentColor;
        }
    }

    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.45;
        `};
`;
Checkbox.defaultProps = {
    uiColor: "primary",
};

export default Checkbox;
