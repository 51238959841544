import React, { FC } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import styled from "../../../theme";

const MoreDetails = styled.button`
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #3b97b1;
    height: 40px;
    color: #3b87a0;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 16px;
    cursor: pointer;
    outline: none;
    margin-left: auto;
`;

const Content = styled.span`
    display: inline-flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }
`;

export interface MoreDetailsButtonProps {
    isDetailsDisplayed: boolean;
    onToggleClientDetails(): void;
}

const MoreDetailsButton: FC<MoreDetailsButtonProps> = (props) => (
    <MoreDetails onClick={props.onToggleClientDetails}>
        <Content>
            {props.isDetailsDisplayed ? <FaArrowUp /> : <FaArrowDown />}
            {props.isDetailsDisplayed ? "Hide Profile" : "Show Profile"}
        </Content>
    </MoreDetails>
);

export default MoreDetailsButton;
