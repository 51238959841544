import { Checkbox, GridTable, GridTableBody } from "@ramble/ramble-ui";
import { ClientDashboardModule, ClientModule, RoomModule } from "../../redux";
import {
    ContainerCell,
    HeaderCell,
    HeaderRow,
    MiniCell,
    MiniRowOne,
    MiniRowTwo,
    ProjectOverviewTableRow,
    ProjectOverviewTableRowProps,
} from "./project_overview_table_row";
import React, { PureComponent } from "react";
import { getAll as getAllCategories } from "../../api/category";
import {
    getAll as getAllItems
} from "../../api/item";
import AddButtonE from "../UI/buttons/add-button";
import HoverableItem from "./project_overview_table_hoverable_item";
import styled from "../../theme";
// import NewItemModal
//     from "./new-item-modal/new-item-modal";
import { SortTableRowInfo } from "../UI/table/sort_table";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FadeLoader } from "react-spinners";

interface TableProps {
    nRows: number;
    border?: boolean;
}

const Table = styled(GridTable) <TableProps>`
    flex: 1;
    border: none;
    overflow: auto;
    min-height: ${(props) => (props.nRows < 10 ? props.nRows : 10) * 30 + 52}px;

    ${(props) => props.border && `
            border-bottom: none !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            `}
`;

interface BodyProps {
    paddingBottom?: boolean;
}

const ProjectOverviewTableBody = styled(GridTableBody) <BodyProps>`
    flex: 1;
    padding-bottom: ${(props) => props.paddingBottom ? '0px' : '170px'};
    position: relative;
`;

const MiniTable = styled(GridTable)`
    border: none !important;
    background: transparent;
    height: 52px;
    justify-content: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 5px;
`;

const Heading = styled.h3`
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: 500;
    color: #222222;
    font-size: 18px;
`;

const ShowArchivedCheckBox = styled(Checkbox)`
    font-size: 14px;
    color: #222;
    padding-left: 10px;
`;

const AddButton = styled(AddButtonE)`
    margin-left: auto;
    font-size: 14px;
    border-radius: 8px;
    z-index: 10;
`;

const SaveButton = styled.button`
    background-color: #3b87a0;
    border: 0;
    color: #fff;
    cursor: pointer;
    outline-color: #fff;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 8px;
    z-index: 10;
    padding: 8px 28px 8px 28px;
`;

const CancelEditButton = styled(SaveButton)`
    z-index: 10;
    font-size: 14px;
    border-radius: 8px;
    color: #3b87a0;
    border: 1px solid black;
    background: initial;
    margin-left: auto;
`;

const CheckboxHeader = styled(Checkbox)`
    .label {
        padding-left: 0;
    }
`;


export interface ProjectOverviewTableProps {
    projectList: ClientDashboardModule.ClientProject[];
    roomList: RoomModule.Room[];
    checkedProjects: number[];
    showArchived: boolean;
    isParent?: boolean;
    onCheckRow: ProjectOverviewTableRowProps["onCheckRow"];
    onItemColumnClick: ProjectOverviewTableRowProps["onItemClick"];
    onWorkOrderColumnClick: ProjectOverviewTableRowProps["onWorkOrderClick"];
    onEdited(projects: ClientDashboardModule.ClientProject[]): void;
    onNewClientProject(): void;
    onActionSelected(value: string): void;
    onCheckAllRows(checked: boolean): void;
    onShowArchiveChange(isShow: boolean): void;
    onOpenModal(value: string): void;
    handleNotCheckedBatchRow(
        id: number,
        project: ClientDashboardModule.ClientProject
    ): void;
    clients: SortTableRowInfo[];
    // accountId: number;
    loadProjects(): void;
    client?: ClientModule.Client;
    editProjectItem(
        project: ClientDashboardModule.ClientProject
    ): Promise<void>;
    mapSortTableToSortOptions(state: SortTable): void;
    overviewPage?: boolean;
    borderBottom?: boolean;   
    paddingBottom?: boolean;
    searchTerm?: any;
    pageLoading?: boolean;
}

export interface SortTable {
    field: string;
    position: number;
}

interface State {
    scrollWidth: number;
    isNewItemModalOpen: boolean;
    editMode: boolean;
    editedProjects: ClientDashboardModule.ClientProject[];
    isPopoverOpen: number;
    categories: any[];
    items: any[];
    sortTable: SortTable;
    rowIdEdit: number | undefined;
    rowKeyEdit: string;
}

class ProjectOverviewTable extends PureComponent<
    ProjectOverviewTableProps,
    State
> {
    public state: State = {
        scrollWidth: 0,
        editMode: false,
        editedProjects: [],
        items: [],
        isPopoverOpen: -1,
        sortTable: {
            field: "",
            position: 0,
        },
        categories: [],
        isNewItemModalOpen: false,
        rowKeyEdit: "",
        rowIdEdit: undefined,
    };

    public componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("click", this.handleOutsideClick);
        this.handleResize();
        this.getCategories(true);
        this.scrollToBottomIfNeeded();
        this.getItems(true);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.scrollToBottomIfNeeded();
        window.removeEventListener("click", this.handleOutsideClick);
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        this.handleResize();

        if (prevState.sortTable !== this.state.sortTable) {
            this.props.mapSortTableToSortOptions(this.state.sortTable);
        }
    }

    // private openNewItemModal = () => {
    //     this.setState({ isNewItemModalOpen: true });
    // };

    // private closeNewItemModal = () => {
    //     this.setState({ isNewItemModalOpen: false });
    // };

    private setCategories(categories: any[]): void {
        this.setState({ categories });
    }

    private async getCategories(activeOnly: boolean): Promise<any> {
        const catRes = await getAllCategories({
            isActiveOnly: activeOnly || undefined,
        });
        this.setCategories(catRes);

        return catRes;
    }

    private setItems(items: any[]): void {
        this.setState({ items });
    }

    private async getItems(activeOnly: boolean): Promise<any> {
        const itemRes = await getAllItems({
            isActiveOnly: activeOnly || undefined,
        });
        this.setItems(itemRes);

        return itemRes;
    }

    private ref: React.RefObject<HTMLDivElement> = React.createRef();

    private scrollToBottomIfNeeded = () => {
        const isScrolable = localStorage.getItem("isScrolable");
        if (isScrolable === "true") {
            this.ref &&
                this.ref.current &&
                this.ref.current.scrollIntoView({ behavior: "smooth" });
            localStorage.removeItem("isScrolable");
        }
    };

    private handleSetSorting = (field: string) => {
        if (this.state.sortTable.position === 2) {
            this.setState((prev) => {
                return {
                    ...prev,
                    sortTable: {
                        ...prev.sortTable,
                        field: "",
                        position: 0,
                    },
                };
            });
        } else {
            this.setState((prev) => {
                return {
                    ...prev,
                    sortTable: {
                        ...prev.sortTable,
                        field: field,
                        position: prev.sortTable.position + 1,
                    },
                };
            });
        }

        if (this.state.sortTable.field !== field) {
            this.setState((prev) => {
                return {
                    ...prev,
                    sortTable: {
                        ...prev.sortTable,
                        field: field,
                        position: 1,
                    },
                };
            });
        }
    };

    public loadProjects = async () => {
        await this.props.loadProjects();
        this.scrollToBottomIfNeeded();
    };

    public render() {
        const { scrollWidth, editedProjects, editMode, sortTable } = this.state;
        const {
            projectList,
            roomList,
            checkedProjects,
            onCheckAllRows,
            onNewClientProject,
            onCheckRow,
            clients,
            client,
            onItemColumnClick,
            onWorkOrderColumnClick,
            showArchived,
            isParent,
            // accountId,
        } = this.props;
        return (
            <>
                <HeaderContainer>
                    {this.props.overviewPage ? null : (
                        <Heading>Project Overview</Heading>
                    )}

                    {!this.props.overviewPage && (
                        <ShowArchivedCheckBox
                            checked={showArchived}
                            onChange={this.onShowArchiveChange}
                        >
                            Show archived
                        </ShowArchivedCheckBox>
                    )}
                    {editMode ? (
                        <>
                            <CancelEditButton
                                title="Cancel Edit"
                                onClick={this.cancelEditMode}
                            >
                                Cancel
                            </CancelEditButton>
                            <SaveButton title="Save" onClick={this.confirmEdit}>
                                Save
                            </SaveButton>
                        </>
                    ) : (
                        // <AddButton
                        //     title="Add New Item"
                        //     onClick={this.openNewItemModal}
                        // />
                        !this.props.overviewPage && (
                            <AddButton
                                title="Add Item"
                                onClick={onNewClientProject}
                            />
                        )
                    )}
                    {/* {this.state.isNewItemModalOpen && (
                        <NewItemModal
                            isNewItemModalOpen={this.state.isNewItemModalOpen}
                            closeNewItemModal={this.closeNewItemModal}
                            client={client}
                            clients={clients}
                            accountId={accountId}
                            categories={this.state.categories}
                            items={this.state.items}
                            loadProjects={this.loadProjects}
                        />
                    )} */}
                </HeaderContainer>
                <Table border={this.props.borderBottom} nRows={projectList.length}>
                    <HeaderRow scrollWidth={scrollWidth} showClient={isParent}>
                        <HeaderCell>
                            <CheckboxHeader
                                name="markAll"
                                checked={
                                    projectList.length ===
                                        checkedProjects.length &&
                                    !!projectList.length
                                }
                                onChange={(e) =>
                                    onCheckAllRows(e.currentTarget.checked)
                                }
                            />
                        </HeaderCell>
                        {isParent && (
                            <HeaderCell
                                className={`sort_header_cell ${
                                    sortTable.field === "clientId"
                                        ? "activeSort"
                                        : ""
                                }`}
                            >
                                <HoverableItem className="leftAlign lineHeight">
                                    <span
                                        onClick={() =>
                                            this.handleSetSorting("clientId")
                                        }
                                        className={`${
                                            sortTable.field === "clientId"
                                                ? "activeSort"
                                                : "hoverableSort"
                                        }`}
                                    >
                                        CLIENT
                                    </span>
                                    {sortTable.field === "clientId" && (
                                        <div
                                            className={`sort_arrow ${
                                                sortTable.position === 0 ||
                                                sortTable.position === 3
                                                    ? "hidden"
                                                    : ""
                                            }`}
                                        >
                                            <IoIosArrowRoundUp
                                                style={{ fontSize: "20px" }}
                                                className={`arrow ${
                                                    sortTable.position === 2
                                                        ? "rotate180"
                                                        : ""
                                                }`}
                                            />
                                        </div>
                                    )}
                                    <br />
                                    <span
                                        onClick={() =>
                                            this.handleSetSorting("parentId")
                                        }
                                        className={`${
                                            sortTable.field === "parentId"
                                                ? "activeSort"
                                                : "hoverableSort"
                                        } sub_row`}
                                    >
                                        (Parent)
                                    </span>
                                    {sortTable.field === "parentId" && (
                                        <div
                                            className={`sort_arrow ${
                                                sortTable.position === 0 ||
                                                sortTable.position === 3
                                                    ? "hidden"
                                                    : ""
                                            }`}
                                        >
                                            <IoIosArrowRoundUp
                                                style={{ fontSize: "20px" }}
                                                className={`arrow ${
                                                    sortTable.position === 2
                                                        ? "rotate180"
                                                        : ""
                                                }`}
                                            />
                                        </div>
                                    )}
                                </HoverableItem>
                            </HeaderCell>
                        )}
                        <HeaderCell
                            className={`sort_header_cell leftAlign ${
                                sortTable.field === "roomId" ? "activeSort" : ""
                            }`}
                        >
                            <HoverableItem className="leftAlign lineHeight">
                                <span
                                    onClick={() =>
                                        this.handleSetSorting("itemId")
                                    }
                                    className={
                                        sortTable.field === "itemId"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    }
                                >
                                    ITEM
                                </span>
                                {sortTable.field === "itemId" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                                <br />
                                <span
                                    onClick={() =>
                                        this.handleSetSorting("roomId")
                                    }
                                    className={`${
                                        sortTable.field === "roomId"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    } sub_row`}
                                >
                                    Room
                                </span>
                                {sortTable.field === "roomId" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <HeaderCell
                            style={{ minWidth: "130px" }}
                            onClick={() => this.handleSetSorting("status")}
                            className="sort_header_cell editInlineField"
                        >
                            <HoverableItem className="leftAlign">
                                <span
                                    className={
                                        sortTable.field === "status"
                                            ? "activeSort"
                                            : ""
                                    }
                                >
                                    STATUS
                                </span>
                                {sortTable.field === "status" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <HeaderCell>
                            <HoverableItem>NOTE</HoverableItem>
                        </HeaderCell>
                        <HeaderCell
                            onClick={() => this.handleSetSorting("workOrderId")}
                            className="sort_header_cell editInlineField"
                        >
                            <HoverableItem>
                                <span
                                    className={
                                        sortTable.field === "workOrderId"
                                            ? "activeSort"
                                            : ""
                                    }
                                >
                                    WO#
                                </span>
                                {sortTable.field === "workOrderId" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <HeaderCell
                            className={`sort_header_cell ${
                                sortTable.field === "dateSent"
                                    ? "activeSort"
                                    : ""
                            }`}
                        >
                            <HoverableItem className="leftAlign lineHeight">
                                <span className="invoice">ESTIMATE &nbsp;</span>
                                <br />
                                <span className={`sub_row`}># </span>
                                <span className={`sub_row`}>/</span>
                                <span
                                    className={`${
                                        sortTable.field === "dateSent"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    } sub_row`}
                                    onClick={() =>
                                        this.handleSetSorting("dateSent")
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    {" "}
                                    Sent
                                </span>
                                {sortTable.field === "dateSent" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <HeaderCell
                            style={{ minWidth: "82px" }}
                            className={`sort_header_cell ${
                                sortTable.field === "num" ||
                                sortTable.field === "paid"
                                    ? "activeSort"
                                    : ""
                            }`}
                        >
                            <HoverableItem className="leftAlign lineHeight">
                                <span className="invoice">INVOICE &nbsp;</span>{" "}
                                <br />
                                <span
                                    className={`${
                                        sortTable.field === "num"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    } sub_row`}
                                    onClick={() => this.handleSetSorting("num")}
                                    style={{ cursor: "pointer" }}
                                >
                                    #
                                </span>
                                {sortTable.field === "num" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                                <span className="sub_row"> / </span>
                                <span
                                    className={`${
                                        sortTable.field === "paid"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    } sub_row`}
                                    onClick={() =>
                                        this.handleSetSorting("paid")
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    Paid
                                </span>
                                {sortTable.field === "paid" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <ContainerCell>
                            <MiniTable>
                                <MiniRowOne>
                                    <MiniCell>
                                        <HoverableItem>
                                            COMPONENTS
                                        </HoverableItem>
                                    </MiniCell>
                                </MiniRowOne>
                                <MiniRowTwo>
                                    <MiniCell>
                                        <HoverableItem className="lineHeight">
                                            <span className={`sub_row`}>
                                                Ordered
                                            </span>
                                        </HoverableItem>{" "}
                                    </MiniCell>
                                    <MiniCell>
                                        <HoverableItem className="lineHeight">
                                            <span className={`sub_row`}>
                                                Received
                                            </span>
                                        </HoverableItem>{" "}
                                    </MiniCell>
                                </MiniRowTwo>
                            </MiniTable>
                        </ContainerCell>
                        <HeaderCell>
                            <HoverableItem className="leftAlign lineHeight">
                                <span>PRODUCTION</span> <br />
                                <span className={`sub_row`}>Start </span>
                                <span className={`sub_row`}>/</span>
                                <span className={`sub_row`}> Finish</span>
                            </HoverableItem>
                        </HeaderCell>
                        <HeaderCell
                            className={`sort_header_cell ${
                                sortTable.field === "installDate"
                                    ? "activeSort"
                                    : ""
                            }`}
                        >
                            <HoverableItem className="leftAlign lineHeight">
                                <span className="invoice">INSTALL</span> <br />
                                <span className={`sub_row`}># </span>
                                <span className={`sub_row`}>/</span>
                                <span
                                    className={`${
                                        sortTable.field === "installDate"
                                            ? "activeSort"
                                            : "hoverableSort"
                                    } sub_row`}
                                    onClick={() =>
                                        this.handleSetSorting("installDate")
                                    }
                                    style={{ cursor: "pointer" }}
                                >
                                    {" "}
                                    Date
                                </span>
                                {sortTable.field === "installDate" && (
                                    <div
                                        className={`sort_arrow ${
                                            sortTable.position === 0 ||
                                            sortTable.position === 3
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <IoIosArrowRoundUp
                                            style={{ fontSize: "20px" }}
                                            className={`arrow ${
                                                sortTable.position === 2
                                                    ? "rotate180"
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </HoverableItem>
                        </HeaderCell>
                        <ContainerCell></ContainerCell>
                    </HeaderRow>
                    {this.props.overviewPage && this.props.pageLoading ? (
                        <FadeLoader
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(50%,-50%)",
                                zIndex: 9999,
                                display: !this.props.pageLoading ? "none" : "block",
                            }}
                            color="#3B97B1"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        <ProjectOverviewTableBody paddingBottom={this.props.paddingBottom} id="grid-table-body">
                            {projectList.map(
                                (project: ClientDashboardModule.ClientProject) => (
                                    <>
                                        <ProjectOverviewTableRow
                                            id={project.itemId}
                                            isChecked={checkedProjects.includes(
                                                project.itemId
                                            )}
                                            // use edited project from the local state if available
                                            project={
                                                editedProjects.find(
                                                    (p) =>
                                                        p.itemId === project.itemId
                                                ) || project
                                            }
                                            checkedProjects={checkedProjects}
                                            showClient={isParent}
                                            onCheckRow={onCheckRow}
                                            onEdited={this.onEditedProject}
                                            isEditing={
                                                editMode &&
                                                checkedProjects.includes(
                                                    project.itemId
                                                )
                                            }
                                            onItemClick={onItemColumnClick}
                                            onWorkOrderClick={
                                                onWorkOrderColumnClick
                                            }
                                            rooms={roomList}
                                            handleActionMenu={this.handleActionMenu}
                                            isPopoverOpen={
                                                this.state.isPopoverOpen ===
                                                project.itemId
                                            }
                                            setIsPopoverOpen={this.setIsPopoverOpen}
                                            onOpenModal={this.props.onOpenModal}
                                            notes={project.notes}
                                            handleNotCheckedBatchRow={this.props.handleNotCheckedBatchRow}
                                            editProjectItem={this.props.editProjectItem}
                                            rowIdEdit={this.state.rowIdEdit}
                                            rowKeyEdit={this.state.rowKeyEdit}
                                            handleSetRowId={this.handleSetRowId}
                                            confirmEdit={this.confirmEdit}
                                            client={client ? client : project.client}
                                            clients={clients}
                                            searchTerm={this.props.searchTerm}
                                        />
                                    </>
                                )
                            )}
                            <div ref={this.ref}></div>
                        </ProjectOverviewTableBody>
                    )}
                </Table>
            </>
        );
    }

    private handleOutsideClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isPopoverClicked = target.closest(".cursor-pointer");
        if (!isPopoverClicked) {
            this.setIsPopoverOpen(null);
        }
    };

    private handleSetRowId = (p: number | undefined, k: string): void => {
        this.setState({
            rowIdEdit: p,
            rowKeyEdit: k,
        });
    };

    private setIsPopoverOpen = (id: number | null) => {
        this.setState({ isPopoverOpen: id || -1 });
    };

    private onEditedProject: ProjectOverviewTableRowProps["onEdited"] = (
        project
    ) => {
        const { editedProjects } = this.state;
        this.setState({
            editedProjects: editedProjects
                .filter((p) => p.itemId !== project.itemId)
                .concat(project),
        });
    };

    private handleActionMenu = (val: string) => {
        switch (val) {
            case "Edit": {
                this.setState({
                    editMode: true,
                });
                return;
            }
            default: {
                this.props.onActionSelected(val);
            }
        }
    };

    private cancelEditMode = () => {
        this.setState({
            editedProjects: [],
            editMode: false,
        });
    };

    private confirmEdit = () => {
        const { editedProjects } = this.state;
        if (editedProjects.length) {
            this.props.onEdited(editedProjects);
        }
        this.setState({
            editedProjects: [],
            editMode: false,
        });
    };

    private handleResize = () => {
        const e = document.getElementById("grid-table-body");
        const e1: HTMLElement | null = document.getElementsByClassName(
            "grid-table-row"
        )[0] as any;
        let scrollWidth = 0;
        if (e && e1) {
            scrollWidth = e.offsetWidth - e1.offsetWidth;
        }
        this.setState({
            scrollWidth,
        });
    };

    private onShowArchiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;
        this.props.onShowArchiveChange(checked);
    };
}

export default ProjectOverviewTable;
