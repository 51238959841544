import {
    Button,
    // Form,
    Input,
    // Modal,
    // ModalActions,
    // ModalContent,
    ModalHeader,
    // PageDimmer,
    Select as SelectE,
} from "@ramble/ramble-ui";
import { ClientDashboardModule, RoomModule } from "../redux";
import React, { ChangeEvent, PureComponent } from "react";
import { SortTableRowInfo } from "../components/UI/table/sort_table";
// import { FiEdit2 } from "react-icons/fi";
import { GoCalendar } from "react-icons/go";
import { TiWarningOutline } from "react-icons/ti";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../components/reactDatePicker/ReactDatePicker.css";
import SearchSelect, {
    SearchSelectProps,
} from "../components/UI/search_select/search_select";

import styled from "../theme";

export interface EditValues {
    roomName: string;
    roomId?: number;
    status: ClientDashboardModule.ClientProject["status"];
    invoicePaid: ClientDashboardModule.ClientProject["invoicePaid"];
    productionStartDate: Date | null;
    productionFinishedDate: Date | null;
    installDate: Date | null;
    clientId?: any;
    invoiceNumber?: string;
    estimateNumber?: string;
    estimateDateSent?: Date | null;
    productionDuoDate: Date | null;
    installNumber?: string;
    notes?: string;
    window?: string;
}

export interface EditItemsModalProps {
    roomList: RoomModule.Room[];
    active: boolean;
    onRequestClose(): void;
    onSave(newValues: EditValues): void;
    clients: SortTableRowInfo[];
    handleSearchClient: SearchSelectProps["onSearch"];
}

const CancelButton = styled(Button)`
    // margin-right: auto !important;
    background-color: transparent;
    // border-radius: 0.5em;
    // border: 1px solid #cdcdcd;
    box-shadow: none;
    // width: 200px;
    color: #222222;
    display: block;

    &:hover {
        background-color: transparent;
    }
`;

export const SaveButton = styled(Button)`
    // width: 210px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

const Header = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding: 15px 15px 0px 15px;

    div {
        width: 100%;
        // border-bottom: 10px solid #e6f1f4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        color: ${({ theme }) => theme.colors.primary};
        text-wrap: nowrap;
    }

    .modal-title-desc {
        font-size: 14px;
        color: black;
        margin: 10px 0px;
    }
`;

const Select = styled(SelectE)`
    .select {
        border-radius: 5px;
        padding: 10.05px 15px;
        white-space: pre-line;
        word-break: break-word;
        box-sizing: border-box;
        min-width: 150px;
        box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.1);
    }

    &.placeholder > select {
        color: gray;
        font-style: italic;

        > option {
            color: black;
            font-style: normal;
        }

        > :nth-child(1) {
            color: gray;
            font-style: italic;
        }
    }

    &:not(.placeholder) > select {
        color: black;

        > :nth-child(1) {
            color: gray;
            font-style: italic;
        }
    }
`;

const BatchOverlay = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128, 128, 128, 0.4);
    z-index: 1000;

    &.active {
        display: block;
    }
`;

const BatchModal = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    top: 50px;
    width: 0px;
    background: #ecf6f9;
    z-index: 1001;
    padding: 15px 0px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.5s ease;

    & label {
        font-size: 14px;
        color: black;
    }

    &.active {
        width: 650px;
        padding: 15px 25px;
    }
`;

const BatchFlexItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :nth-child(1) > :nth-child(1) {
        border-right: 2px solid #cde5eb;
        margin-bottom: 7.5px;
        padding-bottom: 7.5px;

        & > :nth-child(2) > select {
            min-width: 340px;
        }
        & > :nth-child(3) > select {
            min-width: 160px;
        }
    }

    & > :nth-child(1) > :nth-child(2) {
        margin-bottom: 7.5px;
        padding-bottom: 7.5px;
    }
`;
const BatchFlexItem = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 2px solid #cde5eb;

    & .batch_warning {
        display: flex;
        gap: 10px;
        padding: 15px;

        & > :nth-child(1) {
            background-color: #ffc043;
            border-radius: 5px;
            padding: 5px;
            height: max-content;
        }

        & .batch_warning_desc {
            > span {
                display: block;
                font-size: 13px;
                color: black;
                text-wrap: nowrap;
            }
        }
    }
`;
const BatchFlexRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;

    & .search_input {
        & .batch_edit_search_input {
            width: 100%;

            label {
                border-radius: 0.5em;
                box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.1);
                background: white;

                & > input {
                    font-style: normal;

                    &::placeholder {
                        font-style: italic;
                    }
                }
            }
        }
    }

    & .input_row {
        & > label > input {
            width: 100%;
            min-width: 240px;
            color: black;
        }

        & > :nth-child(2) {
            box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.1);
        }
    }

    & .date_picker_row {
        & .custom_date_picker {
            max-width: 100%;
            padding: 10.05px 15px;
            min-width: 270px;
            color: black;
        }

        & > :nth-child(2) {
            box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.1);
            border-radius: 0.5rem;
        }

        & .react-datepicker-wrapper {
            width: 100%;
        }
    }

    & .batch_status,
    & .batch_room {
        font-size: 14px;
        color: black;
        text-wrap: nowrap;
    }

    & .clientTitle,
    & .batch_notes {
        color: black;
        text-wrap: nowrap;
    }

    & .batch_notes_textarea {
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: 10px;
        resize: none;
        border: 1px solid lightgray;
        padding: 15px;
        margin-top: 5px;
        min-width: 190px;
        color: black;
        box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.1);
    }
`;

interface State {
    isMemberShipCancel: boolean;
    memberShipCancelReasons: string;
    values: EditValues;
}

export class EditItemsModal extends PureComponent<EditItemsModalProps, State> {
    public state: State = {
        isMemberShipCancel: false,
        memberShipCancelReasons: "",
        values: {
            installDate: null as Date | null,
            roomName: "",
            invoicePaid: "" as any,
            productionFinishedDate: null as Date | null,
            productionStartDate: null as Date | null,
            status: "" as any,
            clientId: "" as any,
            invoiceNumber: "" as string,
            estimateNumber: "" as string,
            estimateDateSent: null as Date | null,
            productionDuoDate: null as Date | null,
            installNumber: "" as string,
            notes: "" as string,
            window: "" as string,
        },
    };

    public render(): JSX.Element {
        const { active } = this.props;
        const { values } = this.state;

        return (
            <>
                <BatchOverlay className={active ? "active" : ""} />
                <BatchModal className={active ? "active" : ""}>
                    <Header>
                        <div>
                            <span className="modal-title">Batch Edit</span>
                        </div>
                    </Header>

                    <BatchFlexItems>
                        <BatchFlexItem>
                            <BatchFlexRow style={{ flex: "2" }}>
                                <span className="clientTitle">CLIENT</span>

                                <div className="search_input">
                                    <SearchSelect
                                        className="batch_edit_search_input"
                                        onItemClick={(clientId: number) =>
                                            this.onItemClick(clientId)
                                        }
                                        onSearch={this.props.handleSearchClient}
                                        placeholder="Please select"
                                        onKeywordCleared={
                                            this.handleClearClient
                                        }
                                        handleEmptyField={this.handleEmptyField}
                                    />
                                </div>

                                <div className="flex" style={{ gap: "10px" }}>
                                    <div className="flex flex-1 flex-col">
                                        <span className="batch_status">
                                            STATUS
                                        </span>
                                        <Select
                                            value={values.status || ""}
                                            name="status"
                                            onChange={this.handleSelectChange}
                                            className={
                                                !values.status
                                                    ? "placeholder"
                                                    : ""
                                            }
                                        >
                                            <option value="">
                                                Please select
                                            </option>
                                            <option value="New">New</option>
                                            <option value="Planning">
                                                Planning
                                            </option>
                                            <option value="Measured">
                                                Measured
                                            </option>
                                            <option value="Need Measure">
                                                Need Measure
                                            </option>
                                            <option value="Pending">
                                                Pending
                                            </option>
                                            <option value="Approved">
                                                Approved
                                            </option>
                                            <option value="Production">
                                                Production
                                            </option>
                                            <option value="Ready">Ready</option>
                                            <option value="Closed">
                                                Closed
                                            </option>
                                            <option value="Rejected">
                                                Rejected
                                            </option>
                                        </Select>
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <span className="batch_room">ROOM</span>
                                        <Select
                                            id="room_item_select"
                                            name="roomId"
                                            value={values.roomId || ""}
                                            onChange={this.handleRoomChange}
                                            className={
                                                !values.roomId
                                                    ? "placeholder"
                                                    : ""
                                            }
                                        >
                                            {this.props.roomList.map(
                                                (room, id) => (
                                                    <option
                                                        key={id}
                                                        value={room.id}
                                                    >
                                                        {room.name}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </div>
                                </div>
                            </BatchFlexRow>
                            <BatchFlexRow style={{ flex: "1" }}>
                                <span className="batch_notes">
                                    PROJECT OVERVIEW NOTES
                                </span>

                                <textarea
                                    className="batch_notes_textarea"
                                    id="notes_textarea_batch"
                                    name="notes"
                                    value={values.notes || ""}
                                    onChange={this.handleTextareaChange}
                                    // rows={3}
                                />
                            </BatchFlexRow>
                        </BatchFlexItem>

                        <BatchFlexItem>
                            <BatchFlexRow className="flex-1">
                                <span>ESTIMATE</span>

                                <div className="input_row">
                                    <label htmlFor="estimate_number_input">
                                        Estimate #
                                    </label>
                                    <Input
                                        className="w-full"
                                        id="estimate_number_input"
                                        name="estimateNumber"
                                        value={values.estimateNumber || ""}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="date_picker_row">
                                    <label htmlFor="estimate_date_input">
                                        Estimate Sent
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            className="custom_date_picker"
                                            selected={
                                                values.estimateDateSent || null
                                            }
                                            onChange={(newDate) =>
                                                this.handleChangeDate(
                                                    newDate,
                                                    "estimateDateSent"
                                                )
                                            }
                                            placeholderText="MM/DD/YYYY"
                                            isClearable
                                        />
                                        <GoCalendar
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "12px",
                                                transform:
                                                    "translate(-12px, -50%)",
                                                fontSize: "22px",
                                                color: "gray",
                                            }}
                                        />
                                    </div>
                                </div>
                            </BatchFlexRow>

                            <BatchFlexRow className="flex-1">
                                <span className="batch_invoice">INVOICE</span>

                                <div className="input_row">
                                    <label htmlFor="invoice_number_input">
                                        Invoice #
                                    </label>
                                    <Input
                                        className="w-full"
                                        id="invoice_number_input"
                                        name="invoiceNumber"
                                        value={values.invoiceNumber || ""}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="flex flex-1 flex-col">
                                    <label htmlFor="invoice_paid_select">
                                        Payment
                                    </label>
                                    <Select
                                        id="invoice_paid_select"
                                        name="invoicePaid"
                                        value={values.invoicePaid}
                                        onChange={this.handleSelectChange}
                                        className={
                                            !values.invoicePaid
                                                ? "placeholder"
                                                : ""
                                        }
                                    >
                                        <option value={""}>
                                            Please select
                                        </option>
                                        <option value={"Yes"}>Yes</option>
                                        <option value={"No"}>No</option>
                                        <option value={"Partial"}>
                                            Partial
                                        </option>
                                    </Select>
                                </div>
                            </BatchFlexRow>
                        </BatchFlexItem>

                        <BatchFlexItem>
                            <BatchFlexRow className="flex-1">
                                <span className="batch_production">
                                    PRODUCTION
                                </span>

                                <div className="date_picker_row">
                                    <label htmlFor="production_start_date_input">
                                        Start Date
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            className="custom_date_picker"
                                            selected={
                                                values.productionStartDate ||
                                                null
                                            }
                                            onChange={(newDate) =>
                                                this.handleChangeDate(
                                                    newDate,
                                                    "productionStartDate"
                                                )
                                            }
                                            placeholderText="MM/DD/YYYY"
                                            isClearable
                                        />
                                        <GoCalendar
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "12px",
                                                transform:
                                                    "translate(-12px, -50%)",
                                                fontSize: "22px",
                                                color: "gray",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="date_picker_row">
                                    <label htmlFor="production_due_date_input">
                                        Due Date
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            className="custom_date_picker"
                                            selected={
                                                values.productionDuoDate || null
                                            }
                                            onChange={(newDate) =>
                                                this.handleChangeDate(
                                                    newDate,
                                                    "productionDuoDate"
                                                )
                                            }
                                            placeholderText="MM/DD/YYYY"
                                            isClearable
                                        />
                                        <GoCalendar
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "12px",
                                                transform:
                                                    "translate(-12px, -50%)",
                                                fontSize: "22px",
                                                color: "gray",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="date_picker_row">
                                    <label htmlFor="production_finished_date_input">
                                        Finished Date
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            className="custom_date_picker"
                                            selected={
                                                values.productionFinishedDate ||
                                                null
                                            }
                                            onChange={(newDate) =>
                                                this.handleChangeDate(
                                                    newDate,
                                                    "productionFinishedDate"
                                                )
                                            }
                                            placeholderText="MM/DD/YYYY"
                                            isClearable
                                        />
                                        <GoCalendar
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "12px",
                                                transform:
                                                    "translate(-12px, -50%)",
                                                fontSize: "22px",
                                                color: "gray",
                                            }}
                                        />
                                    </div>
                                </div>
                            </BatchFlexRow>

                            <BatchFlexRow className="flex-1">
                                <span className="batch_install">INSTALL</span>

                                <div className="input_row">
                                    <label htmlFor="install_order_input">
                                        Order #
                                    </label>
                                    <Input
                                        className="w-full"
                                        id="install_order_input"
                                        name="installNumber"
                                        value={values.installNumber || ""}
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <div className="date_picker_row">
                                    <label htmlFor="install_date_input">
                                        Date
                                    </label>
                                    <div className="relative">
                                        <DatePicker
                                            className="custom_date_picker"
                                            selected={
                                                values.installDate || null
                                            }
                                            onChange={(newDate) =>
                                                this.handleChangeDate(
                                                    newDate,
                                                    "installDate"
                                                )
                                            }
                                            placeholderText="MM/DD/YYYY"
                                            isClearable
                                        />
                                        <GoCalendar
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "12px",
                                                transform:
                                                    "translate(-12px, -50%)",
                                                fontSize: "22px",
                                                color: "gray",
                                            }}
                                        />
                                    </div>
                                </div>
                            </BatchFlexRow>
                        </BatchFlexItem>

                        <BatchFlexItem style={{ border: "none" }}>
                            <div className="batch_warning">
                                <div>
                                    <TiWarningOutline
                                        style={{ fontSize: "30px" }}
                                    />
                                </div>
                                <div className="batch_warning_desc">
                                    <span>
                                        Changes indicated below will be applied
                                        to all the selected items on the table.
                                    </span>
                                    <span>
                                        Any existing data in the edited fields
                                        will be replaced.
                                    </span>
                                </div>
                            </div>
                        </BatchFlexItem>

                        <BatchFlexItem
                            style={{
                                gap: "10px",
                                minWidth: "600px",
                                justifyContent: "space-around",
                            }}
                            className="border-0 pr-4 pl-4"
                        >
                            <CancelButton onClick={this.props.onRequestClose}>
                                Cancel
                            </CancelButton>
                            <SaveButton uiColor="primary" onClick={this.save}>
                                Ok
                            </SaveButton>
                        </BatchFlexItem>
                    </BatchFlexItems>
                </BatchModal>
            </>
        );
    }

    private handleRoomChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const id = parseInt(e.currentTarget.value, 10);
        const room = this.props.roomList.find((r) => r.id === id);
        if (!room) {
            return;
        }
        this.setState({
            values: {
                ...this.state.values,
                roomId: id,
                roomName: room.name,
            },
        });
    };

    private onItemClick = (clientId: number) => {
        this.setState({
            values: {
                ...this.state.values,
                clientId,
            },
        });
    };

    private handleEmptyField = (value: string) => {
        if (!value) {
            this.setState({
                values: {
                    ...this.state.values,
                    clientId: "",
                },
            });
        }
    };

    private handleClearClient = (): void => {
        this.setState({
            values: {
                ...this.state.values,
                clientId: "",
            },
        });
    };

    private handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const { value, name } = e.currentTarget;
        this.setState({
            values: {
                ...this.state.values,
                [name]: value,
            },
        });
    };

    private handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            values: {
                ...this.state.values,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    private handleChangeDate = (value: Date | null, field: string): void => {
        if (value) {
            this.setState({
                values: {
                    ...this.state.values,
                    [field]: new Date(value),
                },
            });
        } else {
            this.setState({
                values: {
                    ...this.state.values,
                    [field]: null,
                },
            });
        }
    };

    private handleTextareaChange = (
        e: ChangeEvent<HTMLTextAreaElement>
    ): void => {
        let { value } = e.currentTarget;
        value = value.length > 150 ? value.slice(0, 150) : value;
        this.setState({
            values: {
                ...this.state.values,
                [e.currentTarget.name]: value,
            },
        });
    };

    private save = () => {
        this.props.onSave(this.state.values);

        this.setState({
            ...this.state,
            values: {
                installDate: null as Date | null,
                roomName: "",
                invoicePaid: "" as any,
                productionFinishedDate: null as Date | null,
                productionStartDate: null as Date | null,
                status: "" as any,
                clientId: "" as any,
                invoiceNumber: "" as string,
                estimateNumber: "" as string,
                estimateDateSent: null as Date | null,
                productionDuoDate: null as Date | null,
                installNumber: "" as string,
                notes: "" as string,
                window: "" as string,
            },
        });
    };
}
