import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import { BasicClientInfo, Client } from "./interfaces";

export const GET_CLIENTS = "client/GET_ALL";

export type GetClientsAction = ActionDescriptor<
    typeof GET_CLIENTS,
    {
        /**
         * Account id to get the clients
         */
        accountId: number;
        /**
         * Client sorting order. Default to asc if not set
         */
        sort?: "asc" | "desc";
        /**
         * Include inactive clients
         */
        includeInactive?: boolean;
    },
    BasicClientInfo[]
>;

export const GET_CLIENT = "client/GET";

export type GetClientAction = ActionDescriptor<typeof GET_CLIENT,
    {
        clientId: number;
    },
    Client
>;

export const ADD_CLIENT = "client/ADD";

export type AddClientAction = ActionDescriptor<typeof ADD_CLIENT,
    Omit<Client, "id" | "subClientsIds" | "subClients"> & { accountId: number },
    Client
>;

export const EDIT_CLIENT = "client/EDIT";

export type EditClientAction = ActionDescriptor<typeof EDIT_CLIENT,
    Omit<Client, "subClientsIds" | "subClients">,
    {}
>;

export const SEARCH_CLIENTS = "client/SEARCH_CLIENTS";

export type SearchClientsAction = ActionDescriptor<
    typeof SEARCH_CLIENTS,
    {
        /**
         * Search text
         */
        text: string;
        /**
         * Account id
         */
        accountId: number;
        /**
         * Exclude following client id
         */
        excludeId?: number;
        /**
         * Include inactive clients for the search
         */
        includeInactive?: boolean;
    },
    BasicClientInfo[]
>;

export interface ClientActionsMap {
    [GET_CLIENTS]: GetClientsAction;
    [GET_CLIENT]: GetClientAction;
    [ADD_CLIENT]: AddClientAction;
    [EDIT_CLIENT]: EditClientAction;
    [SEARCH_CLIENTS]: SearchClientsAction;
}
