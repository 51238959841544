import {
    Button,
    Checkbox,
    FormValidationError,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import {
    Category,
    CategoryNew,
    CategoryRecord,
    UpdateCategory,
} from "../../../interfaces/category";
import React, {
    FC,
    FormEvent,
    KeyboardEvent,
    useEffect,
    useState,
} from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../UI/search_select/search_select";
import { addRecord, getRecord, updateRecord } from "../../../api/category";
import styled, { theme } from "../../../theme";

const CancelButton = styled(Button)`
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    color: #222222;
    &:hover {
        background-color: #eeeeee;
    }
`;

const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 30px !important;

    > label:hover,
    &:hover {
        cursor: pointer;
    }
`;

const RadioInput = styled(ValidationPopupInput)`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 0;
    margin-right: 5px;
    border: 1px solid ${theme.colors.grayRGBA};
    border: ${(props) =>
        props.checked ? `5px solid ${theme.colors.blue}` : ""};

    input {
        visibility: hidden;
    }
`;

interface CategoryModalProps {
    isModalOpen: boolean;
    editCategoryId: number | null;
    categories: Category[];
    onModalClose(): void;
    reloadList(): void;
}

interface ScrollInfo {
    scrollX: number;
    scrollY: number;
}

interface ParentCategory {
    id: number;
    name: string;
}

const CategoryModal: FC<CategoryModalProps> = ({
    isModalOpen,
    editCategoryId,
    categories,
    onModalClose,
    reloadList,
}) => {
    const [isInactive, setIsInactive] = useState<boolean>(false);
    const [showSubCatMenuList, setShowSubCatMenuList] =
        useState<boolean>(false);
    const [isSaveComplete, setIsSaveComplete] = useState<boolean>(false);
    const [IsShowInactiveOptions, setIsShowInactiveOptions] =
        useState<boolean>(false);
    const [categoryName, setCategoryName] = useState<string>("");
    const [subCategories, setSubCategories] = useState<Category[]>([]);
    const [areaScrollInfo, setAreaScrollInfo] = useState<ScrollInfo>({
        scrollX: 0,
        scrollY: 0,
    });
    const [parentCategory, setParentCategory] = useState<ParentCategory>({
        id: 0,
        name: "",
    });

    const [parentCategoryIdToMove, setParentCategoryIdToMove] = useState<
        number | null
    >(null);

    const [inactiveRadioValue, setInactiveRadioValue] = useState<number>(0);
    const [makeAllSubCategoriesInactive, setMakeAllSubCategoriesInactive] =
        useState<boolean>(false);
    const [showInactiveOptionsDropdown, setShowInactiveOptionsDropdown] =
        useState<boolean>(false);

    useEffect(() => {
        const fetchCategoryById = async () => {
            try {
                if (editCategoryId) {
                    const data: CategoryRecord = await getRecord({
                        recordId: editCategoryId,
                    });

                    const { name, isActive, parentCategoryId } = data;
                    setCategoryName(name);
                    setIsInactive(!isActive);

                    if (parentCategoryId) {
                        const parentCat = categories.find(
                            (c) => c.id === parentCategoryId
                        );
                        if (parentCat) {
                            setShowSubCatMenuList(true);
                            setParentCategory({
                                id: parentCat.id,
                                name: parentCat.name,
                            });
                        }
                    } else {
                        const subCats = categories.filter(
                            (c) => c.parentCategoryId === editCategoryId
                        );
                        setSubCategories(subCats);
                    }
                }
            } catch (error) {
                console.error("Error fetching categoriesListView:", error);
            }
        };

        if (editCategoryId) {
            void fetchCategoryById();
        }
    }, [editCategoryId]);

    useEffect(() => {
        if (isSaveComplete) {
            const timeoutId = setTimeout(() => {
                setIsSaveComplete(false);
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isSaveComplete]);

    const handleSubmitForm = async (
        e: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        const isActive = !isInactive;

        let payload: UpdateCategory | CategoryNew = {
            name: categoryName,
            parentCategoryId: parentCategory.id,
            isActive,
        };

        let result: any;

        if (editCategoryId) {
            payload = {
                ...payload,
                id: editCategoryId,
                parentCategoryIdToMove,
                makeAllSubCategoriesInactive,
            };
            result = await updateRecord(payload as UpdateCategory);
        } else {
            result = await addRecord(payload);
        }

        const errors = result.data.errors;
        const status = result.status;

        if (errors) {
            setAreaScrollInfo({
                scrollX: 0,
                scrollY: 0,
            });
            throw FormValidationError.fromErrorDictionary(errors);
        } else if ([201, 200].includes(status)) {
            setIsSaveComplete(true);
            reloadList();
            onClose();
        }
    };

    const toggleSubCatMenuList = () => {
        if (showSubCatMenuList) {
            setParentCategory({ id: 0, name: "" });
        }

        setShowSubCatMenuList(!showSubCatMenuList);
    };

    const displayInactiveOptions = (toggledValue: boolean) => {
        if (toggledValue && editCategoryId && subCategories.length) {
            setIsShowInactiveOptions(true);
        } else {
            setIsShowInactiveOptions(false);
        }
    };

    const toggleInactive = () => {
        const toggledValue = !isInactive;
        setIsInactive(toggledValue);
        displayInactiveOptions(toggledValue);
    };

    const handleKeyPressForm = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const onClose = () => {
        setCategoryName("");
        setIsInactive(false);
        setShowSubCatMenuList(false);
        setIsShowInactiveOptions(false);
        setParentCategory({ id: 0, name: "" });
        setParentCategoryIdToMove(null);
        setMakeAllSubCategoriesInactive(false);
        setShowInactiveOptionsDropdown(false);
        setSubCategories([]);
        setInactiveRadioValue(0);
        setIsSaveComplete(false);
        onModalClose();
    };

    const handleSelect = async (id: number, name: string) => {
        setParentCategory({ id, name });
    };

    const handleSearch: SearchSelectProps["onSearch"] = async (
        term: string
    ) => {
        try {
            let results = [...categories];
            results = results
                .filter((r) => {
                    if (editCategoryId) {
                        return r.id !== editCategoryId;
                    }
                    return r;
                })
                .filter((r) => r.isActive && !r.parentCategoryId)
                .filter((r) => {
                    if (term && term.length) {
                        // NOSONAR
                        // removing NOSONAR shows optional chain sonar error,
                        // current typescript version doesnt support optional chaining
                        return r.name
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    }
                    return r;
                });

            return results.map((r) => ({
                id: r.id,
                displayName: r.name,
            }));
        } catch (error) {
            console.error("Error searching categories:", error);
            return [
                {
                    id: 0,
                    displayName: "",
                },
            ];
        }
    };

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryName(e.target.value);
    };

    const handleInactiveOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = +e.target.value;
        setInactiveRadioValue(targetValue);

        switch (targetValue) {
            case 1: {
                setParentCategoryIdToMove(null);
                setShowInactiveOptionsDropdown(false);
                setMakeAllSubCategoriesInactive(true);
                break;
            }
            case 2: {
                setMakeAllSubCategoriesInactive(false);
                setShowInactiveOptionsDropdown(true);
                break;
            }
            case 3: {
                setParentCategoryIdToMove(null);
                setMakeAllSubCategoriesInactive(false);
                setShowInactiveOptionsDropdown(false);
                setIsInactive(false);
                setIsShowInactiveOptions(false);
                setInactiveRadioValue(0);
                break;
            }
            default:
                break;
        }
    };

    const handleInactiveSelect = async (id: number, name: string) => {
        setParentCategoryIdToMove(id);
    };

    return (
        <>
            <PageDimmer uiActive={isModalOpen} />
            <Modal
                uiActive={isModalOpen}
                uiOnRequestClose={() => {
                    return;
                }}
                uiDefaultSizes={true}
            >
                <ValidationForm
                    uiOnSubmit={handleSubmitForm}
                    uiOnInvalidElem={() => {
                        return;
                    }}
                    uiReportValidityMode="firstInvalid"
                    onKeyPress={handleKeyPressForm}
                    uiPadding={false}
                >
                    <ModalHeader className="border-0">
                        <div className="w-full pb-2 border-b-8 border-twSecondary flex justify-between items-center">
                            <div>
                            {!editCategoryId && (
                                <>
                                   <span className="text-twPrimary font-medium">
                                            Add New Category
                                        </span>
                                        {(parentCategory.name ||
                                            categoryName) && (
                                            <span className="p-2">-</span>
                                        )}
                                    </>
                                )}
                                {parentCategory.name && (
                                    <>
                                        <span className="text-twBlue">
                                            {parentCategory.name}
                                        </span>
                                        <span className="p-1">|</span>
                                    </>
                                )}
                                <span className="text-twBlue">{`${
                                    categoryName || ""
                                }`}</span>
                            </div>
                            <Checkbox
                            checked={isInactive}
                            onClick={toggleInactive}
                            >
                            <span
                                    style={{
                                        fontSize: "15px",
                                        position: "relative",
                                        bottom: "6px",
                                    }}
                                >
                                    Make Inactive
                                </span>
                            </Checkbox>
                        </div>
                    </ModalHeader>
                    <ModalContent className="mb-14">
                        <PopoverScrollContext.Provider value={areaScrollInfo}>
                            <div className="flex flex-col w-full flex-wrap flex-1 h-full items-start max-w-[550px]">
                                <h4 className="text-twBlue1 tracking-[1.5px] font-bold mt-0 flex items-center place-content-between">
                                    {showSubCatMenuList
                                        ? `SUB-CATEGORY INFORMATION`
                                        : `CATEGORY INFORMATION`}
                                </h4>
                                <div className="mt-5 mb-8 w-full flex flex-col">
                                    <label
                                        className="mb-2 w-full"
                                        htmlFor="Category Name"
                                    >
                                        Name
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <ValidationPopupInput
                                    id="Category Name"
                                    name="Category Name"
                                    className="px-1.5 py-2"
                                    type="text"
                                    required
                                    value={categoryName}
                                    onChange={handleFieldChange}
                                    uiReportOnBlur={false}
                                    disabled={false}
                                    uiDisabled={false}
                                    uiValidityMessages={{
                                        valueMissing:
                                            "Category Name must be at least 1 character.",
                                    }}
                                    autoComplete="off"
                                    list="autocompleteOff"
                                    data-index={0}
                                    />
                                </div>
                                {!subCategories.length && (
                                    <div className="flex flex-col mb-4 w-full">
                                        <Checkbox
                                            onClick={toggleSubCatMenuList}
                                            checked={showSubCatMenuList}
                                        >
                                            is sub-category of
                                        </Checkbox>

                                        {showSubCatMenuList && (
                                            <SearchSelect
                                            searchType="modal"
                                                onItemClick={(id, name) =>
                                                    void handleSelect(id, name)
                                                }
                                                onSearch={handleSearch}
                                                initialValue={
                                                    parentCategory.name
                                                }
                                                placeholder="Please select"
                                                keepKeyword
                                                onKeywordCleared={() =>
                                                    void handleSelect(0, "")
                                                }
                                                className="mt-2 mb-4"
                                                required={true}
                                            >
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    style={{ display: "none" }}
                                                />
                                            </SearchSelect>
                                        )}
                                    </div>
                                )}
                               
                                {IsShowInactiveOptions && (
                                    <div className="mt-4">
                                        <p>
                                            This category has been marked
                                            inactive. What would you like to do
                                            with the existing sub-categories in
                                            this category?
                                        </p>
                                        <RadioLabel className="mb-2">
                                            <RadioInput
                                                id="inactiveSubs"
                                                name="inactiveParentWithSubs"
                                                type="radio"
                                                checked={
                                                    inactiveRadioValue === 1
                                                }
                                                value={1}
                                                onChange={handleInactiveOptions}
                                                uiReportOnBlur={false}
                                                disabled={false}
                                            />
                                            Make all sub-categories in this
                                            category inactive
                                        </RadioLabel>
                                        <RadioLabel className="mb-2">
                                            <RadioInput
                                                id="inactiveMoveSubs"
                                                name="inactiveParentWithSubs"
                                                type="radio"
                                                checked={
                                                    inactiveRadioValue === 2
                                                }
                                                value={2}
                                                onChange={handleInactiveOptions}
                                                uiReportOnBlur={false}
                                                disabled={false}
                                            />
                                            Move existing sub-categories in this
                                            category
                                        </RadioLabel>
                                        <RadioLabel>
                                            <RadioInput
                                                id="inactiveDoNothing"
                                                name="inactiveParentWithSubs"
                                                type="radio"
                                                checked={
                                                    inactiveRadioValue === 3
                                                }
                                                value={3}
                                                onChange={handleInactiveOptions}
                                                uiReportOnBlur={false}
                                                disabled={false}
                                            />
                                            Do not make this category or its
                                            sub-categories inactive
                                        </RadioLabel>
                                        {showInactiveOptionsDropdown && (
                                            <SearchSelect
                                                className="mt-5"
                                                searchType="modal"
                                                onItemClick={(id, name) =>
                                                    void handleInactiveSelect(
                                                        id,
                                                        name
                                                    )
                                                }
                                                onSearch={handleSearch}
                                                initialValue={""}
                                                placeholder="Please select"
                                                keepKeyword
                                                onKeywordCleared={() =>
                                                    void handleSelect(0, "")
                                                }
                                                required={true}
                                            />
                                        )}
                                    </div>
                                )}

                                {isSaveComplete && (
                                    <p className="bg-green-400 mt-5 text-center text-white w-full px-4 py-2 rounded">
                                        category saved successfully
                                    </p>
                                )}
                            </div>
                        </PopoverScrollContext.Provider>
                    </ModalContent>

                    <ModalActions>
                        <CancelButton
                            type="button"
                            uiColor="secondary"
                            onClick={onClose}
                            className="rounded-lg mr-auto block shadow-none"
                        >
                            Cancel
                        </CancelButton>

                        <Button
                            className={`rounded-lg border border-twBlue2 block shadow-none`}
                            uiColor={
                                IsShowInactiveOptions &&
                                inactiveRadioValue === 0
                                    ? "secondaryText"
                                    : "primary"
                            }
                            disabled={
                                IsShowInactiveOptions &&
                                inactiveRadioValue === 0
                            }
                        >
                            Save
                        </Button>
                    </ModalActions>
                </ValidationForm>
            </Modal>
        </>
    );
};

export default CategoryModal;